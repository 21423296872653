<!-- 视图 -->
<template>
  <div class="chooseView">
    <!-- 当前视图和视图列表 -->
    <el-popover
      placement="bottom-start"
      trigger="click"
      :visible-arrow="false"
      ref="viewListPopper"
      popper-class="view-list-popper"
      v-model="popdropBool"
      @show="this.openViewPopover"
    >
      <!-- 视图按钮 -->
      <el-button
        plain
        size="small"
        slot="reference"
        class="buttonClass"
        style="margin-right: 8px"
        @mouseover.native="mouseoverBtn"
        @mouseleave.native="mouseleaveBtn"
      >
        <span class="viewName">
          <span>{{ viewName }}</span>
          <svg class="icon dropdownIcon" aria-hidden="true">
            <use :href="dropIcon"></use>
          </svg>
        </span>
      </el-button>

      <div v-loading="loading">
        <!-- 搜索 -->
        <el-input
          size="small"
          class="searchKeyWord"
          :placeholder="$t('vue_label_commonobjects_view_searchview')"
          prefix-icon="el-icon-search"
          v-model="keyWord"
        >
        </el-input>
        <!-- 视图列表 -->
        <vuedraggable class="dataList" v-model="dataList" @end="changeDataList">
          <div
            v-for="(val, i) in dataList"
            :key="i"
            class="viewItem"
            :class="{
              active: val.isCurrent,
              hover: mousemoveViewId === val.id,
            }"
            @mousemove="mousemoveView(val)"
            @click="handleCommand(val)" 
          >
            <!-- 悬浮时拖拽标识 -->
            <span class="icon-adjustment">
              <svg
                class="icon"
                aria-hidden="true"
                v-show="mousemoveViewId === val.id"
              >
                <use href="#icon-a-Normal-Sort"></use>
              </svg>
            </span>
            <!-- 视图名称 -->
            <span class="viewListName">
              {{ val.name || val.label }}
            </span>
            <!-- 默认视图按钮 视图是默认视图或者悬浮时展示 -->
            <div class="thumbtackBox">
              <span
                v-show="val.isdefault === '1' || mousemoveViewId === val.id"
                class="thumbtack"
                @mouseover="mouseoverView(val)"
                @mouseleave="mouseoverView"
                @click="SetViewDefault(val)"
                :style="{
                  transform: val.isdefault === '1' ? 'rotate(45deg)' : '',
                }"
              >
                <svg class="icon thumbtackIcon" aria-hidden="true">
                  <use
                    :href="
                      val.isdefault === '1' || mouseoverViewId === val.id
                        ? '#icon-Hover-Fixation'
                        : '#icon-Normal-Fixed'
                    "
                  ></use>
                </svg>
              </span>
            </div>
            <!-- 视图操作按钮 悬浮时展示 -->
            <div class="viewEditBox" @click.stop="stopFun">
              <el-popover
                placement="bottom"
                trigger="hover"
                :visible-arrow="false"
                popper-class="view-list-popper"
                v-if="
                  mousemoveViewId === val.id &&
                  val.id !== '0' &&
                  val.id !== '1' &&
                  val.id !== '2' &&
                  val.id !== '3'
                "
              >
                <ul class="optionList">
                  <!-- 视图有编辑权限时，显示编辑、删除 -->
                  <li
                    class="item"
                    v-if="val.ismodify === 'true'"
                    @click="handleView('edit', val)"
                  >
                    {{ $t("pagecreator_page_button_edit") }}
                  </li>
                  <li
                    class="item"
                    v-if="val.ismodify === 'true'"
                    @click="handleView('delete', val)"
                  >
                    {{ $t("pagecreator_page_button_delete") }}
                  </li>
                  <!-- 当具有新建视图权限时，显示复制 -->
                  <li
                    class="item"
                    v-if="createView"
                    @click="handleView('copy', val)"
                  >
                    {{ $t("pagecreator_page_button_copy") }}
                  </li>
                  <!-- 无可用操作 -->
                  <li
                    class="item"
                    v-if="val.ismodify !== 'true' && !createView"
                  >
                    {{ $t("1") }}
                  </li>
                </ul>
                <svg
                  class="icon thumbtackIcon imoreStyle"
                  aria-hidden="true"
                  slot="reference"
                >
                  <use href="#icon-Normal-more1"></use>
                </svg>
              </el-popover>
            </div>
          </div>
        </vuedraggable>
        <!-- 创建新视图 -->
        <div class="createViewBox" v-show="isNewDisabled">
          <span @click="handleView('create')">
            <i class="el-icon-plus"></i>
            {{ $t("label.view.new") }}
          </span>
        </div>
      </div>
    </el-popover>
    <!-- 删除、复制视图 -->
    <Dialog
      :dialogSign.sync="dialogSign"
      :btnName="btnName"
      :titleType="titleType"
      @dialogEdit="dialogEdit"
      @dialoghandleClose="dialoghandleClose"
    >
      <!-- 删除视图 -->
      <span
        slot="dialogContent"
        v-if="operationType === 'delete'"
        style="font-size: 16px; font-weight: bold; word-break: break-word"
      >
        <!-- 确认删除该条数据吗？ -->
        {{ $t("label.weixin.confirm.delete") }}
      </span>
      <!-- 复制视图 -->
      <el-form
        slot="dialogContent"
        v-if="operationType === 'copy'"
        :model="copyFormObj"
        class="copyViewForm"
      >
        <el-form-item :label="$t('label.name')" label-width="60px">
          <el-input v-model="copyFormObj.name" auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
    </Dialog>
    <!-- 当有图表在引用视图时，该视图删除需二次确认，连同图表一起删除 -->
    <second-delete
      :secondDeleteTo="secondDeleteTo"
      :markedWords="markedWords"
      @secondDeleteClose="secondDeleteClose"
      @secondDeleteConfirm="secondDeleteConfirm"
    ></second-delete>
  </div>
</template>

<script>
/**
 * @description 视图列表
 * 功能：
 * 1、展示当前视图
 * 2、视图搜索
 * 3、切换视图
 * 4、设置默认视图
 * 逻辑：
 * 1、根据传入的数据展示展示当前视图
 * 2、根据输入的关键字,模糊搜索并展示符合条件的视图搜索结果
 * 3、点击视图列表中任一数据,可切换视图,并可拖拽改变视图展示顺序
 * 4、点击视图列表右侧按钮，将当前视图设置为默认视图
 */
import vuedraggable from "vuedraggable";
import Dialog from "@/components/Dialog/index.vue";
import secondDelete from "@/components/ButtonWrapper/secondDelete";
import {
  saveSortView,
  SetViewDefault,
  DeleteGroup,
  getUserSetup,
  GetViewList
} from "./api.js";

export default {
  components: {
    vuedraggable,
    Dialog,
    secondDelete,
  },
  props: {
    // 当前视图名称
    viewName: {
      type: String,
      default: "",
    },
    // 是否有编辑视图权限
    createView: {
      type: Boolean,
      default: false,
    },
    // 视图id
    viewId: {
      type: String,
      default: "",
    },
    // 视图列表
    viewList: {
      type: Array,
      default: () => [],
    },
    // 视图所在对象id
    objId: {
      type: String,
      default: "",
    },
    // 当前视图类型 table-列表 kanban-看板
    type: {
      type: String,
      default: "",
    },
    //复制视图时，当前视图的名字
    copyForm: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
       // 旧的对象id
      oldObjectId: "",
      loading: false,
      // viewList:[],
      // 未用到的变量
      // pathRoute: this.$route.path,

      // 复制视图时，当前视图的名字
      copyFormObj: this.copyForm,
      // 筛选器图标
      filterIcon: "#icon-Normal-Screen",
      // 非默认视图图标标识的名称
      thumbtack: "thumbtack",
      // 默认视图图标标识的名称
      checkedthumbtack: "thumbtack1",
      // 默认视图id
      defaultViewId: "",
      // 搜索视图关键字
      keyWord: "",
      // 视图列表数据
      dataList: [],
      // 默认视图图标标识的名称
      thumbtackImg: this.thumbtack,
      // 悬浮视图id
      mousemoveViewId: "",
      // 悬浮视图图标id
      mouseoverViewId: "",
      // 当前视图类型Icon图标
      currentViewIcon: "List",
      // 删除、复制视图弹窗是否显示
      dialogSign: false,
      // 按钮的操作名称 例如:确认
      btnName: "",
      // 删除、复制视图弹窗标题
      titleType: "",
      // 视图操作类型 delete和copy
      operationType: "",
      // 删除、复制视图的id
      operateViewId: "",
      //确认连同图表一起删除
      confirmdelete: false,
      // 视图二次删除弹框
      secondDeleteTo: false,
      // 视图二次删除的提示语
      markedWords: "",
      // 视图按钮图标Icon
      dropIcon: "#icon-drop-down1",
      // 控制视图列表的显示隐藏
      popdropBool: false,
      // 视图类型按钮图标Icon
      IconView: "#icon-Normal-List",
      IconSelect: "#icon-drop-down1",
      // 控制视图类型列表的显示隐藏
      changePopBool: false,
      // 当前视图类型下标
      isActive: 0,
      // 创建新视图的权限标识
      isNewDisabled: false,
    };
  },
  mounted() {
    this.initJudgeDefalutView();
    this.isNewFun();
  },
  methods: {
    // 判断对象id是否相同
    isTheSameObjectId() {
      return this.oldObjectId === this.objId;
    },
    // 打开视图切换器
    openViewPopover(){
      // 存在视图列表数据同时对象没有发生改变
      if (this.viewList.length && this.isTheSameObjectId()) {
        this.viewList.forEach((item) => {
          item.isCurrent = item.id === this.viewId;
        });
        return;
      }
      this.loading = true
      this.oldObjectId = this.objId;
      GetViewList({ objId: this.objId }).then((res) => {
        // this.viewList = res.data;
        const temp = res.data.map(item => ({
          ...item,
          isCurrent:item.id === this.viewId
        }))
        this.$emit('updateViewList',temp)
        this.dataList = temp;
        this.loading = false
      })
    },
    /**
     * stopFun: 防止该区域点击冒泡。该函数不可删
     */
    stopFun(){},
    /**
     * 视图列表拖动结束事件
     */
    changeDataList(event) {
      let { oldIndex, newIndex } = event;
      let params = {
        viewIds: this.dataList.map((item) => item.id).join(";"),
        objId: this.objId,
      };
      saveSortView(params).then((res) => {
        if (!res.result) {
          // 保存失败
          this.$message.error(res.returnInfo);
          // 回退排序
          let obj = this.dataList[newIndex];
          if (newIndex > oldIndex) {
            this.dataList.splice(newIndex, 1);
            this.dataList.splice(oldIndex, 0, obj);
          } else {
            this.dataList.splice(newIndex, 1);
            this.dataList.splice(oldIndex + 1, 0, obj);
          }
        }
      });
    },
    /**
     * 判断创建新视图权限
     */
    isNewFun() {
      getUserSetup().then((res) => {
        if (res.data.isCreateView === "true") {
          this.isNewDisabled = true;
        } else {
          this.isNewDisabled = false;
        }
      });
    },
    /**
     * 筛选器按钮/视图按钮的鼠标悬浮
     */
    mouseoverBtn(val) {
      // 筛选器
      if (val === "#icon-Normal-Screen") {
        this.filterIcon = val;
      }
      // 视图按钮
      this.dropIcon = "#icon-Click-drop-down";
    },
    /**
     * 筛选器按钮/视图按钮的鼠标离开
     */
    mouseleaveBtn(val) {
      // 筛选器
      if (val === "#icon-Click-Screen") {
        this.filterIcon = val;
      }

      if (!this.popdropBool) {
        this.dropIcon = "#icon-drop-down1";
      }
    },
    /**
     * 初始化判断是否为默认视图
     */
    initJudgeDefalutView() {
      this.$parent.business && this.$parent.business();
      this.dataList.forEach((val) => {
        //如果它有视图id，选中视图id
        if (this.viewId && val.id === this.viewId && val.isdefault === "1") {
          val.isCurrent = true;
          this.thumbtackImg = this.checkedthumbtack;
        } else if (
          this.viewId &&
          val.id === this.viewId &&
          val.isdefault !== "1"
        ) {
          val.isCurrent = true;
          this.thumbtackImg = this.thumbtack;
        }
        //如果它没有视图id，默认选中默认视图
        if (!this.viewId) {
          if (val.isdefault === "1") {
            this.defaultViewId = val.id;
            val.isCurrent = true;
            this.thumbtackImg = this.checkedthumbtack;
          } else {
            this.thumbtackImg = this.thumbtack;
          }
        }
      });
    },
    /**
     * 视图列表悬浮事件
     * @param {Object} view 悬浮视图数据
     */
    mousemoveView(view) {
      this.mousemoveViewId = view.id;
    },

    /**
     * 编辑、新建、复制、删除视图
     * @param {String} operation 操作类型  edit create copy delete
     * @param {Object} view 编辑视图时视图数据
     */
    handleView(operation, view) {
      // 删除视图
      if (operation === "delete") {
        this.operateViewId = view.id;
        this.operationType = operation;
        this.btnName = this.$i18n.t("label.delete");
        this.titleType = this.$i18n.t("label.tabpage.deleteview");
        this.dialogSign = true;
      } else if (operation === "copy") {
        this.operateViewId = view.id;
        this.operationType = operation;
        this.btnName = this.$i18n.t("label.confirm");
        this.titleType = this.$i18n.t("vue_label_commonobjects_view_copy_view");
        this.$emit("openCopyView", this.operateViewId);
        this.dialogSign = true;
      } else {
        this.$emit("handleView", operation, view);
      }
      // 关闭视图列表气泡
      this.$refs.viewListPopper.doClose();
    },

    /**
     * 删除、复制视图确认操作
     */
    dialogEdit() {
      if (this.operationType === "delete") {
        this.confirmDel();
      } else if (this.operationType === "copy") {
        this.$emit("handleView", this.operationType);
      }
    },
    /**
     * 删除视图
     */
    confirmDel() {
      const params = {
        viewId: this.operateViewId,
        objId: this.objId,
        confirmdelete: this.confirmdelete,
      };
      DeleteGroup(params)
        .then((res) => {
          if (res.result == true) {
            this.dialogSign = false;
            this.secondDeleteTo = false;
            this.confirmdelete = false;
            this.$message({
              showClose: true,
              type: "success",
              //删除成功
              message: this.$i18n.t("label.ems.delete.success"),
            });
            // 删除成功
            this.$emit("getViewId", this.$i18n.t("label.ems.delete.success"));
          }
        })
        .catch((error) => {
          this.markedWords = error.returnInfo;
          this.secondDeleteTo = true;
        });
    },
    /**
     * 视图二次删除确认弹框点击取消
     */
    secondDeleteClose() {
      this.secondDeleteTo = false;
      this.confirmdelete = false;
    },
    /**
     * 视图二次删除确认
     */
    secondDeleteConfirm() {
      this.confirmdelete = true; // 确认连同图表一起删除
      this.confirmDel();
    },
    /**
     * 关闭删除、复制视图弹窗
     */
    dialoghandleClose() {
      this.copyFormObj.name = "";
      this.dialogSign = false;
    },
    /**
     * 点击视图列表 切换视图
     * @param {Object} command 即将切换到的视图数据
     */
    handleCommand(command) {
      this.dataList.forEach((val) => {
        if (val.isdefault === "1") {
          this.defaultViewId = val.id;
        }
      });
      if (this.$router.apps[0]._route.params.type === "kanban") {
        let rs = this.$router.apps[0]._route.params;
        this.$router.push({
          path:
            `/commonObjects/views/kanban/${rs.objectApi}/${rs.objId}/${rs.prefix}` +
            "?viewId=" +
            command.id,
        });
      }
      this.keyWord = "";
      if (command.id === this.defaultViewId) {
        this.thumbtackImg = this.checkedthumbtack;
      } else {
        this.thumbtackImg = this.thumbtack;
      }
      this.$emit("changeView", command);

      // 关闭视图列表气泡
      this.$refs.viewListPopper.doClose();
    },
    /**
     * 鼠标悬浮/离开非默认视图按钮
     * @param {Object} view 视图数据
     */
    mouseoverView(view) {
      this.mouseoverViewId = view ? view.id : "";
    },
    /**
     * 设置默认视图
     * @param {Object} view 视图数据
     */
    SetViewDefault(view) {
      if (view.isdefault !== "1") {
        let data = {
          objId: this.objId,
          defaultViewId: view.id,
        };
        SetViewDefault(data).then((res) => {
          if (res.result === true) {
            this.$message({
              showClose: true,
              type: "success",
              //   设置默认视图成功
              message: this.$i18n.t(
                "vue_label_commonobjects_view_successfully"
              ),
            });
            // this.$emit("getViewList", false);
            this.$emit('updateDefaultView', view.id)
            this.mousemoveViewId = ''
          } else {
            this.$message({
              showClose: true,
              type: "error",
              //   设置默认视图失败
              message: this.$i18n.t("vue_label_commonobjects_view_fail"),
            });
          }
        });
      }
    },
  },
  watch: {
    /**
     * 监听搜索视图关键字变化,对视图列表进行过滤
     */
    keyWord(nval) {
      this.dataList = this.dataList.filter((val) => {
        return (
          val.label && val.label.toLowerCase().indexOf(nval.toLowerCase()) != -1
        );
      });
      if (nval == "") {
        this.dataList = this.viewList;
      }
    },
    /**
     * 监听父组件视图列表数据变化,对页面数据进行处理
     */
    viewList(nval) {
      this.dataList = nval;
      this.initJudgeDefalutView();
    },
    /**
     * 监听当前视图类型变化，修改视图类型列表中当前视图类型的图标
     */
    type(nval) {
      if (nval === "table") {
        this.isActive = 0;
        this.currentViewIcon = "List";
      } else if (nval === "kanban") {
        this.isActive = 2;
        this.currentViewIcon = "Kanban";
      } else if (nval === "multi-screen") {
        this.isActive = 1;
        this.currentViewIcon = "splitscreen";
      }
      this.IconView = `#icon-Normal-${this.currentViewIcon}`;
    },
    /**
     * 监听视图列表的显示隐藏变化，修改视图按钮的图标
     */
    popdropBool(val) {
      if (val) {
        this.dropIcon = "#icon-Click-drop-down";
      } else {
        this.dropIcon = "#icon-drop-down1";
      }
    },
    /**
     * 监听视图类型列表的显示隐藏变化，修改视图类型按钮的图标
     */
    changePopBool(val) {
      if (val) {
        this.IconView = `#icon-Click-${this.currentViewIcon}`;
        this.IconSelect = "#icon-Click-drop-down";
      } else {
        this.IconView = `#icon-Normal-${this.currentViewIcon}`;
        this.IconSelect = "#icon-drop-down1";
      }
    },
  },
};
</script>

<style lang="scss">
// 自定义气泡样式
.view-list-popper {
  margin-top: 4px !important;
  padding: 0 !important;
  border: 1px solid #e1e1e1 !important;
  // margin-left: 42px;

  .searchKeyWord {
    width: calc(100% - 16px);
    font-size: 12px;
    margin: 12px 8px 0 8px;
  }

  .dataList {
    margin: 4px 4px 0 4px;
    max-height: calc(97vh - 227px);
    overflow: auto;

    .viewItem {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #080707;
      margin-bottom: 4px;
      padding: 6px 9px;
      cursor: pointer;
      max-width: 337px;
      min-height: 38px;
      .icon-adjustment {
        width: 16px;
        font-size: 16px;
        margin-top: -3px;
        display: inline-block;
        cursor: move;
      }

      .viewListName {
        margin: 0 8px;
        // margin-right: 36px;
        max-width: 249px;
      }

      .icon-more {
        color: #c4c4c4;
      }
      // 增加box设置width，防止上浮出现位置错位
      .thumbtackBox {
        width: 18px;
        margin-right: auto;
        padding-top: 2px;
        .thumbtack {
          cursor: pointer;
          .thumbtackIcon {
            font-size: 16px;
          }
        }
      }
      .viewEditBox {
        width: 32px;
      }
    }

    .active {
      color: $--color-primary !important;
      background: #f3f7ff !important;
      border-radius: 4px;
    }

    .hover {
      color: #080707;
      background: #f5f5f5;
      border-radius: 4px;
    }
  }

  .optionList {
    margin: 4px 4px 0 4px;

    .item {
      font-size: 12px;
      color: #080707;
      margin-bottom: 4px;
      padding: 6px 12px;
      cursor: pointer;

      &:hover {
        background: #f5f5f5;
        border-radius: 4px;
      }
    }
    .active {
      color: $--color-primary;
      background: #f3f7ff;
      border-radius: 4px;
    }

    .view-type-item {
      font-size: 14px;
    }
  }
  .imoreStyle {
    float: right;
    margin-left: 8px;
    padding: 5px;
  }
  .imoreStyle:hover {
    background: #e1e1e1;
    border-radius: 4px;
  }

  .createViewBox {
    height: 42px;
    line-height: 34px;
    color: #080707;
    text-align: center;
    border-top: 1px solid #e1e1e1;

    > span {
      cursor: pointer;
      margin-top: 4px;
      height: calc(100% - 8px);
      width: calc(100% - 8px);
      display: inline-block;

      &:hover {
        background: #f5f5f5;
      }
    }
  }
}

.changeViewPopper {
  margin-top: 4px !important;
}

.change-view-button {
  padding: 0 !important;

  > span {
    display: inline-block;
    padding: 9px 10px;
  }

  .current-view-icon {
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid #dedcda;
  }
}
</style>
<style lang="scss" scoped>
.chooseView {
  position: relative;
  display: flex;
  .buttonClass {
    &:hover {
      background-color: #f3f7ff;
      border-color: $--color-primary;
    }
    &:active {
      background-color: #f3f7ff;
      border-color: $--color-primary;
    }
    &:focus {
      background-color: #f3f7ff;
      border-color: $--color-primary;
    }
  }
  .filterBtn {
    padding: 8px 9px !important;
    margin-right: 8px;

    > span {
      height: 12px;
      display: inline-block;
    }
  }

  .viewName {
    display: flex;
    span {
      font-size: 12px;
      display: inline-block;
      max-width: 177px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    i {
      margin-left: 6px;
    }
  }

  .dropdownIcon {
    margin-left: 5px;
  }

  .el-icon-caret-bottom {
    color: #c4c4c4;
  }

  // .thumbtack {
  //   position: absolute;
  //   top: 22px;
  //   cursor: pointer;
  //   border-radius: 2px;
  //   text-align: center;
  //   display: inline-block;
  //   .View-seas {
  //     margin-top: -3px;
  //     text-align: left;
  //   }
  //   .tubiao {
  //     position: absolute;
  //     bottom: 0;
  //     left: 0px;
  //     background: #fff;
  //     border: 1px solid #dedcda;
  //     border-radius: 3px;
  //     width: 18px;
  //     height: 18px;
  //     top: 0;
  //     svg {
  //       vertical-align: 0 !important;
  //       margin-left: 1px;
  //       margin-top: 1px;
  //       width: 13px;
  //     }
  //   }
  // }

  .viewImg {
    margin-right: 10px;
    margin-top: 2px;
    width: 37px;
    height: 40px;
  }
}

::v-deep .copyViewForm .el-form-item {
  margin-bottom: 0;
}

.el-dropdown {
  display: inline;
}
.el-popper {
  border: 0;
}
.el-dropdown-menu {
  max-height: 500px;
  min-height: 160px;
  margin: 0;
  display: block;
  overflow: hidden;
  overflow-y: auto;
  left: 65px !important;
  padding: 0;

  ::v-deep input::-webkit-input-placeholder {
    color: #706e6b;
  }
  .left_inp {
    width: 85%;
    ::v-deep .el-input__inner {
      padding-left: 0;
      border: 0;
      background: none;
      outline: none;
      border: none;
      font-size: 14px;
    }
  }

  .drop_box {
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    height: 40px;
    margin-bottom: 4px;
    svg {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin: -3px 10px 0 21px;
    }
  }
}
</style>
