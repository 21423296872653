// 新版视图列表页组件api
import axios from '@/config/httpConfig'


// 获取批量删除的数据
export function batchDelete(data) {
  return axios.post('/batch/batchDelete', data)
}
// 获取市场活动成员状态选项列表值(公海池中 ButtonWrapper组件中 退回，废弃操作也用到此API)
export function getSelectValue(data) {
  return axios.post('/view/getSelectValue', data)
}
// 查询项目模板
export function queryProjectTemplateList(data) {
  return axios.post('/projectTemplate/queryProjectTemplateList', data)
}
// 项目模板保存接口
export function templateSave(data) {
  return axios.post('/projectTemplateOperation/templateSave', data)
}
// 获取视图列表 (左侧下拉菜单列表,) // 获取对象下所有可见的公海池
export function queryMarketSeaList(data) {
  return axios.post('/marketsea/queryMarketSeaList', data)
}
// 获取公海池成员  分配时使用(下拉选项)
export function getMarketseaSpecMem(data) {
  return axios.post('marketseaFunction/getMarketseaSpecMem', data)
}
// 转移公海池时查询可转入的公海池id(下拉选项)
export function selMarketsea(data) {
  return axios.post('marketseaFunction/selMarketsea', data)
}
// 视图列表退回（公海池）
export function getReturnSeasPool(data) {
  return axios.post('/marketseaFunction/getReturnSeasPool', data)

}
// 查询语言接口
export function queryLanguage(data) {
  return axios.post("/knowledgeTranslate/queryLanguage", data);
}
// 获取快速操作错误信息
export function getErrorInfo(data) {
  return axios.post('/KnowledgeArticle/getErrorInfo', data)
}
// 获取MailChimp权限
export function GetMailchimpGetMailChimpPermission(data) {
  return axios.post('/mailchimp/getMailChimpPermission', data)
}
// 批量打分
export function BatchLeadScore(data) {
  return axios.post('/leadscore/batchLeadScore', data)
}
//邮件发送页
export function getEmailPageInfo(data) {
  return axios.post('emailObject/getEmailPageInfo', data)
}
// 获取对象信息
export function getObjectGetObjectInfo(data) {
  return axios.post('/object/getObjectInfo', data)
}
// 判断全部订单是否为同一个客户
export function isSameCustomer(data) {
  return axios.post('/generateInvoiceNew/isSameCustomer', data)
}
//判断全部订单是否为激活状态
export function isActiveOrder(data) {
  return axios.post('/generateInvoiceNew/isActiveOrder', data)
}
//判断订单是否为已开具发票状态
export function isAlreadyGenerated(data) {
  return axios.post('/generateInvoiceNew/isAlreadyGenerated', data)
}
//判断所选订单是否属于同一个币种以及价格手册
export function isSameCurencyAndPriceBook(data) {
  return axios.post('/generateInvoiceNew/isSameCurencyAndPriceBook', data)
}
// 自定义按钮
export function openCall(data) {
  return axios.post('/openCall/common', data)
}
// 从quickbooks上更新状态
export function updateStatusFromQB(data) {
  return axios.post('/quickbooks/updateStatusFromQB', data)
}
// 发票
// 从quickbooks上同步发票信息
export function sendInvoiceFromQB(data) {
  return axios.post('/quickbooks/sendInvoiceFromQB', data)
}

// 订单同步到QuickBooks
export function cloudccOrderToQBEstimate(data) {
  return axios.post('/quickbooks/cloudccOrderToQBEstimate', data)
}
// 回款同步到QuickBooks
export function cloudccPaymentToQBPayment(data) {
  return axios.post('/quickbooks/cloudccPaymentToQBPayment', data)
}
// 从QuickBooks同步发票
export function syncInvoicesFromQB(data) {
  return axios.post('/quickbooks/syncInvoicesFromQB', data)
}
// 从QuickBooks同步回款
export function syncPaymentFromQB(data) {
  return axios.post('/quickbooks/syncPaymentFromQB', data)
}
// 同步至quickbooks
export function cloudccInvoicesToQBInvoices(data) {
  return axios.post('/quickbooks/cloudccInvoicesToQBInvoices', data)
}
// 当前是否启用了quickbooks功能  查询QB权限
export function quickbookStart(data) {
  return axios.get('/quickbooks/getQBPermission', data)
}
// 判断在线二维码按钮是否显示
export function showQRCodeButton(data) {
  return axios.post('/caseQRCode/showButtonOrNot', data)
}
// 获取在线二维码
export function getCaseQRCode(data) {
  return axios.post('/caseQRCode/getCaseQRCode', data)
}
