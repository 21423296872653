<template>
  <div class="buttonWrapper">
    <!-- 批量快捷操作按钮 -->
    <div v-if="!pdfviewdetail">
      <el-button
        v-for="(item, idx) in btnList"
        :key="idx"
        :devid="item.id"
        size="small"
        class="batch-operation-button"
        @click="handleClick(item, item.action)"
      >
        {{ item.label }}
      </el-button>
    </div>
    <div v-if="pdfviewdetail && pdfviewdetail == 'pdfview'" class="pdf-btn">
      <el-select
        v-model="pdfViewModel"
        @change="handleClick('', pdfViewModel)"
        placeholder="请选择"
      >
        <el-option
          v-for="item in btnList"
          :key="item.action"
          :label="item.label"
          :value="item.action"
        >
        </el-option>
      </el-select>
    </div>

    <!-- 更多 -->
    <el-popover
      placement="bottom"
      trigger="click"
      :visible-arrow="false"
      popper-class="more-button-list-popper"
      v-if="moreBtnList.length > 0"
    >
      <span class="more-btn" slot="reference">
        <i class="el-icon-more"></i>
      </span>

      <ul class="buttonList">
        <li
          v-for="item in moreBtnList"
          :key="item.icon"
          :devid="item.id"
          class="item button-item"
          @click="handleClick(item, item.action)"
        >
          {{ item.label }}
        </li>
      </ul>
    </el-popover>

    <!-- 批量删除确认 -->
    <el-dialog
      :visible.sync="delDialogVisible"
      :title="$t('label_chatter_delete')"
      top="15%"
      width="25%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <span style="font-size: 14px; color: #080707">
        <!-- 确认删除选中的{{ selectNum }}条数据吗？ -->
        {{ delContent }}
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="delDialogVisible = false"
          size="mini"
          :loading="deleteLoadingStatus"
        >
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          @click="confirmDel"
          type="primary"
          size="mini"
          :loading="deleteLoadingStatus"
        >
          <!-- 删除 -->
          {{ $t("label.delete") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 转移所有人-->
    <transfer-owner-dialog
      :id="ids"
      :objId="objId"
      :show-select-num="true"
      :select-num="selectNum"
      :isKnowledge="isKnowledge"
      :transfer-deault="transferDeault"
      :owner-type-list="ownerTypeList"
      :owner-raletion-list="ownerRaletionList"
      ref="transferClientOwner"
      :transfer-client-owner-dialog="transferClientOwnerDialog"
      @closeTransferOwnerDialog="closeTransferOwnerDialog"
      @saveTransferOwner="saveTransferOwner"
    ></transfer-owner-dialog>

    <!-- 查找/查找多选 -->
    <el-dialog
      :title="$t('label.searchs')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
    >
      <search-table
        ref="searchTable"
        :fieldId="fieldId"
        :relevant-objid="relevantObjId"
        :relevant-prefix="relevantPrefix"
        @changeSelect="changeSelect"
      />
    </el-dialog>
    <!-- 注册事件总线的组件不能同时注册两次，否则触发一次回调用两次 -->
    <!-- 批量发送邮件 -->
    <!-- <batch-send-email></batch-send-email> -->
    <!--    资产条形码，二维码弹窗-->
    <code-dialog ref="codeStyleDialog"> </code-dialog>
    <!-- 批量发送短信 -->
    <!-- <shortMessageDialogMassHair></shortMessageDialogMassHair> -->
    <!-- 公海池右边操作按钮对话框【恢复，回收，领取】 -->
    <!-- :title="clientPoolOprationObject.oprationDirctiveName + tabName" -->
    <el-dialog
      :visible.sync="clientPoolDialogIsShow_Recover_Recycle_Get"
      :title="
        $t('vue_label_pool_assign1', [
          clientPoolOprationObject.oprationDirctiveName,
          clientPoolCheckDialogTitle,
        ])
      "
      top="15%"
      width="25%"
      :close-on-click-modal="true"
      :before-close="handleClose"
      @closed="clientPoolClosed"
    >
      <!-- 对话框场景适配   【回收、领取、恢复】-->
      <span v-if="tableCheckedObjectArr.length != 0">
        <span
          style="font-size: 16px; font-weight: bold"
          v-if="
            clientPoolOprationObject.oprationDirctiveName ===
            this.$i18n.t('vue_label_pool_recycle')
          "
        >
          {{
            tableCheckedObjectArr.length > 1
              ? this.$i18n.t("lable.Confirm.recallpotential.customers", {
                  num: tableCheckedObjectArr.length,
                })
              : this.$i18n.t("lable.Confirm .potential.customers", {
                  name: tableCheckedObjectArr[0].name,
                })
          }}
        </span>
        <span
          style="font-size: 16px; font-weight: bold"
          v-if="
            clientPoolOprationObject.oprationDirctiveName ===
            this.$i18n.t('vue_label_pool_restore')
          "
        >
          {{
            tableCheckedObjectArr.length > 1
              ? this.$i18n.t("lable.Confirm.customers", {
                  num: tableCheckedObjectArr.length,
                })
              : this.$i18n.t("lable.Confirm.recovery.potential.customers", {
                  name: tableCheckedObjectArr[0].name,
                })
          }}
        </span>
        <span
          style="font-size: 16px; font-weight: bold"
          v-if="
            clientPoolOprationObject.oprationDirctiveName ===
            this.$i18n.t('vue_label_pool_adopt')
          "
        >
          {{
            tableCheckedObjectArr.length > 1
              ? this.$i18n.t("lable.Confirm.potential.customers", {
                  num: tableCheckedObjectArr.length,
                })
              : this.$i18n.t("lable.Confirm.collect.potential.customers", {
                  name: tableCheckedObjectArr[0].name,
                })
          }}
        </span>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clientPoolCancle" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          @click="RecycleGetRecoverClientPoolConfirmEvent"
          type="primary"
          size="mini"
        >
          <!-- 确定 -->
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>
    <!-- 退回 -->
    <el-dialog
      :visible.sync="clientPoolDialogIsShow_Back_Discard"
      :title="
        $t('vue_label_pool_assign1', [
          clientPoolOprationObject.oprationDirctiveName,
          clientPoolCheckDialogTitle,
        ])
      "
      top="15%"
      width="25%"
      :close-on-click-modal="true"
      :before-close="handleClose"
      @closed="clientPoolClosed"
    >
      <div>
        <div class="clientPoolDialogIsShow_Back_Discard-margin">
          <span style="color: red">*</span>
          <!-- 原因 -->
          {{
            $t("vue_label_pool_reason", [
              clientPoolOprationObject.oprationDirctiveName,
            ])
          }}
        </div>
        <el-select
          v-model="Back_Discard_Form.firstSelect"
          prop="firstSelect"
          :rules="{
            required: true,
            message: 'xxxxxxxxxxxx',
            trigger: ['change', 'blur'],
          }"
          style="width: 100%"
        >
          <el-option
            :label="item.codevalue"
            :value="item.codekey"
            v-for="(item, index) in Back_Discard_FormOptionList"
            :key="index"
          />
        </el-select>
        <div class="clientPoolDialogIsShow_Back_Discard-margin">
          <!-- 原因描述 -->
          {{
            $t("vue_label_pool_reasondescription", [
              clientPoolOprationObject.oprationDirctiveName,
            ])
          }}
        </div>
        <el-input
          type="textarea"
          v-model="Back_Discard_Form.secondDesc"
        ></el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="clientPoolCancle" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          @click="Back_DiscardClientPoolConfirmEvent()"
          type="primary"
          size="mini"
          :loading="Back_Discard_FormOptionListLoading"
        >
          <!-- 确定 -->
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 公海池右边操作按钮对话框【退回，废弃】 -->
    <el-dialog
      :visible.sync="seaShow"
      :title="
        $t('vue_label_pool_assign1', [
          clientPoolOprationObject.oprationDirctiveName,
          clientPoolCheckDialogTitle,
        ])
      "
      top="15%"
      width="25%"
      :close-on-click-modal="true"
      :before-close="handleClose"
      @closed="clientPoolClosed"
    >
      <div v-loading="Back_Discard_FormOptionListLoading">
        <div class="clientPoolDialogIsShow_Back_Discard-margin">
          <el-form :model="Back_Discard_Form" ref="ruleForm">
            <span style="color: red">*</span>
            <!-- 选择退回的公海池 -->
            {{ $t("lable.Select.the.returned.high.seas.pool") }}
            <br />
            <el-select
              v-model="Back_Discard_Form.backtopoolId"
              prop="backtopoolId"
              :rules="{
                required: true,
                message: '请至少选择一条',
                trigger: ['change', 'blur'],
              }"
              :placeholder="$t('label_tabpage_pleaseSelectz')"
              style="width: 100%"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="(item, index) in seaoption"
                :key="index"
              />
            </el-select>
          </el-form>
        </div>

        <div class="clientPoolDialogIsShow_Back_Discard-margin">
          <span style="color: red">*</span>
          <!-- 原因 -->
          {{
            $t("vue_label_pool_reason", [
              clientPoolOprationObject.oprationDirctiveName,
            ])
          }}
        </div>
        <el-select
          v-model="Back_Discard_Form.firstSelect"
          prop="firstSelect"
          :rules="{
            required: true,
            message: 'xxxxxxxxxxxx',
            trigger: ['change', 'blur'],
          }"
          style="width: 100%"
        >
          <el-option
            :label="item.codevalue"
            :value="item.codekey"
            v-for="(item, index) in Back_Discard_FormOptionList"
            :key="index"
          />
        </el-select>
        <div class="clientPoolDialogIsShow_Back_Discard-margin">
          <!-- 原因描述 -->
          {{
            $t("vue_label_pool_reasondescription", [
              clientPoolOprationObject.oprationDirctiveName,
            ])
          }}
        </div>
        <el-input
          type="textarea"
          v-model="Back_Discard_Form.secondDesc"
        ></el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="clientPoolCancle" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          @click="marketseaReturnAndRecovery()"
          type="primary"
          size="mini"
          :loading="Back_Discard_FormOptionListLoading"
        >
          <!-- 确定 -->
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 公海池右边操作按钮对话框【转移】 -->
    <el-dialog
      :visible.sync="clientPoolDialogIsShow_Transfer"
      :title="
        $t('vue_label_pool_assign1', [
          clientPoolOprationObject.oprationDirctiveName,
          clientPoolCheckDialogTitle,
        ])
      "
      top="15%"
      width="25%"
      :close-on-click-modal="true"
      :before-close="handleClose"
      @closed="clientPoolClosed"
    >
      <div class="clientPoolDialogIsShow_Transfer-box-style">
        <div class="clientPoolDialogIsShow_Back_Discard-margin">
          <!-- 选择转入的公海池 -->
          {{ $t("vue_label_pool_selecttransferinto") }}
        </div>
        <el-select
          v-model="Transfer_Form.firstSelect"
          filterable
          style="width: 100%"
        >
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="(item, index) in Transfer_FormOptionList"
            :key="index"
          />
        </el-select>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="clientPoolCancle" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          @click="TransferClientPoolConfirmEvent"
          type="primary"
          size="mini"
        >
          <!-- 确定 -->
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 公海池右边操作按钮对话框【分配】 -->
    <el-dialog
      :visible.sync="clientPoolDialogIsShow_Assign"
      :title="
        $t('vue_label_pool_assign1', [
          clientPoolOprationObject.oprationDirctiveName,
          clientPoolCheckDialogTitle,
        ])
      "
      top="15%"
      width="25%"
      :close-on-click-modal="true"
      :before-close="handleClose"
      @closed="clientPoolClosed"
    >
      <div class="clientPoolDialogIsShow_Transfer-box-style">
        <div class="clientPoolDialogIsShow_Back_Discard-margin">
          <!-- 选择分配成员 -->
          {{ $t("vue_label_pool_assignselect") }}
        </div>
        <el-select
          v-model="Assign_Form.firstSelect"
          filterable
          style="width: 100%"
        >
          <el-option
            :label="item.username"
            :value="item.userid"
            v-for="(item, index) in Assign_FormOptionList"
            :key="index"
          />
        </el-select>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="clientPoolCancle" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          @click="AssignClientPoolConfirmEvent"
          type="primary"
          size="mini"
        >
          <!-- 确定 -->
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 公海池批量删除 -->
    <el-dialog
      :visible.sync="clientPoolDialogIsShow_Delete"
      :title="$t('label_chatter_delete')"
      top="15%"
      width="25%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <span style="font-size: 16px; font-weight: bold">
        <!-- 确认删除选中的{{ tableCheckedObjectArr.length }}条数据吗？ -->
        {{
          $t("vue_label_pool_deleteconfirm", {
            tableCheckedObjectArrlength: tableCheckedObjectArr.length,
          })
        }}
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clientPoolCancle" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          @click="DeleteClientPoolConfirmEvent"
          type="primary"
          size="mini"
        >
          <!-- 删除 -->
          {{ $t("label.delete") }}
        </el-button>
      </span>
    </el-dialog>
    <Dialog
      :dialogSign="dialogSign"
      :btnName="btnName"
      :titleType="titleType"
      @dialogEdit="dialogEdit"
      @dialoghandleClose="dialoghandleClose"
    >
      <template v-slot:Dialog>
        <div>
          <div v-if="btnSign == 'draftdel'">
            <!-- "您无法恢复已删除的草稿。"
           "现有草稿翻译将链接到主语言文章的上一个发布版本。如果没有发布版本，将删除任何译稿。" -->
            <div class="dialog-type">
              {{ $t("label.knowledgebase.unable.restore.draft") }}
            </div>
            <div>
              {{
                $t(
                  "label.knowledgebase.delete.translation.associated.with.draft"
                )
              }}
            </div>
          </div>
          <div v-else-if="btnSign == 'articledel'" class="dialog-type">
            <!-- "删除已归档文章会将其和所有关联版本从知识库中永久移除。" -->
            <div>
              {{ $t("label.knowledgebase.permanently.remove.articles") }}
            </div>
          </div>
          <div v-else-if="btnSign == 'file'" class="dialog-type">
            <!-- "已发布翻译也将归档，将删除处于草稿状态的翻译。" -->
            <div>{{ $t("label.knowledgebase.published.translations") }}</div>
          </div>
          <div v-else-if="btnSign == 'recovery'" class="dialog-type">
            <!-- "还原会从此版本创建草稿。您将需要发布草稿，以使其可用。" -->
            <div>{{ $t("label.knowledgebase.restore.creates.draft") }}</div>
          </div>
          <div v-else-if="btnSign == 'draftedit'" class="dialog-type">
            <!-- "已发布版本仍将在线，直到您发布此草稿。" -->
            <div>{{ $t("label.knowledgebase.published.version.online") }}</div>
          </div>
        </div>
      </template>
    </Dialog>
    <!-- 知识库发布 -->
    <Release
      :ids="ids"
      :transfer-owner-dialog="releasedialogVisible"
      @releasehandleClose="releasehandleClose"
      :markNewVersion="markNewVersion"
      :affectTranslation="affectTranslation"
      @refreshright="refreshright"
    ></Release>
    <!-- 指派 -->
    <Distribution
      :ids="ids"
      :transfer-owner-dialog="assigndialogVisible"
      @assignhandleClose="assignhandleClose"
      @refreshright="refreshright"
    >
    </Distribution>
    <!-- 提交进行翻译 -->
    <Submittranslation
      :ids="ids"
      :tableData="tableData"
      :transfer-owner-dialog="submittranslationdialogVisible"
      @submittranslationhandleClose="submittranslationhandleClose"
      @refreshright="refreshright"
    >
    </Submittranslation>

    <!-- 项目模板新建 -->
    <el-dialog
      :title="$t('front-project-module-v1-template-newcreate')"
      :visible.sync="createplant"
      width="30%"
      :before-close="createplanthandleClose"
    >
      <el-form
        ref="createplattform"
        :model="createpform"
        :rules="createmplatrules"
        label-width="auto"
        size="small"
      >
        <el-form-item
          :label="$t('front-project-module-v1-select-projecttemplate')"
          prop="region"
        >
          <el-select
            v-model="createpform.region"
            :placeholder="$t('front-project-module-v1-select-projecttemplate')"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in createpformoption"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('label.projectManagement.entryname')"
          prop="name"
        >
          <el-input
            v-model="createpform.name"
            :placeholder="$t('label.projectManagement.Pleaseenterprojectname')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('label.chatter.startDate')" prop="date">
          <el-date-picker
            type="date"
            :placeholder="$t('label.chatter.startDate')"
            v-model="createpform.date"
            value-format="yyyy-MM-dd"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createplant = false">{{
          $t("label.emailtocloudcc.button.back")
        }}</el-button>
        <el-button type="primary" @click="saveteplant">{{
          $t("component.button.ok")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/**
 * 新版视图页使用的批量操作快捷键
 */
import TransferOwnerMixin from "@/mixin/transferOwner.js";
import * as CommonObjApi from "./api.js";
import DialogMixin from "@/mixin/Dialog.js";

export default {
  mixins: [TransferOwnerMixin, DialogMixin],
  components: {
    TransferOwnerDialog: () =>
      import("@/components/TransferOwnerDialog/index.vue"),
    // batchSendEmail: () => import("@/views/Email/batchSendEmail"),
    // shortMessageDialogMassHair: () =>
    // import("@/views/noteSms/components/shortMessageDialogMassHair"),
    CodeDialog: () => import("@/views/commonObjects/components/CodeDialog"),
    Distribution: () => import("@/components/Distribution/index.vue"),
    Submittranslation: () => import("@/components/Submittranslation/index.vue"),
    Dialog: () => import("@/components/Dialog/index.vue"),
    Release: () => import("@/components/Release/index.vue"),
  },
  props: {
    // 被选中的列表数据
    selectedList: {
      type: Array,
      default: () => [],
    },
    // 显示的按钮：目前拆分为两大类
    showButtons: {
      type: Array,
      default: () => [],
    },
    // 区分PDF视图详情页
    pdfviewdetail: {
      type: String,
      default: "",
    },
    // 视图id，用于区分同一个类型数据的不同视图
    viewId: {
      type: String,
      default: "",
    },
    // 当前对象名称，目前不是多语言，后端翻译好了
    tabName: {
      type: String,
      default: "",
    },
    // 对象前缀
    prefix: {
      type: String,
      default: "",
    },
    // 公海池表格所选中的所有的对象
    tableCheckedObjectArr: {
      type: Array,
      default: () => [],
    },
    // 公海池页面标记，目前看是公海池使用的，用于标记对象的
    pageFlag: {
      type: String,
      default: "",
    },
    // 公海池ID，获取公海池成员时使用
    clientPoolId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      val: "",
      index: "",
      recordTypeList: [],
      pdfViewModel: "", //PDF视图详情页时，批量操作按钮显示下拉框
      // 有权限可见的批量操作按钮
      btnList: [],
      // 换行需要在更多中看的批量操作按钮
      moreBtnList: [],
      // 销售云：公海池视图选项
      seaoption: [],
      //群发邮件
      batchEmail: "",
      // 拥有者姓名
      ownerName: "",
      // 拥有者id
      ownerId: "",
      // 所有人 信息写死
      fieldId: "ffe100000001265aWyi8",
      // 查找dialog中搜索组件传入对象id
      relevantObjId: "user",
      // 查找dialog中搜索组件传入前缀
      relevantPrefix: "005",
      // 是否显示查找Dialog
      showSearchTable: false,
      // 批量删除dialog是否显示
      delDialogVisible: false,
      // 控制转移所有人dialog是否显示
      transferClientOwnerDialog: false,
      // 控制知识库发布的dialog显隐
      releasedialogVisible: false,
      // 控制指派dialog的显隐
      assigndialogVisible: false,
      // 提交进行翻译dialog的显隐
      submittranslationdialogVisible: false,
      // 错误数据详细信息
      tableData: [],
      // 是否标记为新版本：true是，那么词条的版本号会自动加1；false不是，那么词条的版本号不会变化
      markNewVersion: "true",
      // 是否翻译关联的文章
      affectTranslation: "true",
      // 标记是否为知识库
      isKnowledge: "",
      // 按钮集合
      buttonList: [
        {
          // 添加标签
          label: this.$i18n.t("label.tag.addtags"),
          action: "addTag",
          name: "addTag",
        },
        {
          // 批量转移
          label: this.$i18n.t("label.batchtransfer"),
          action: "batchRemove",
          name: "Batch Transfer",
        },
        {
          // 批量删除
          label: this.$i18n.t("label.batchDelete"),
          action: "batchDelete",
          name: "Batch Delete",
        },
        {
          //群发邮件
          label: this.$i18n.t("label.emailobject.groupemail"),
          action: "GroupEmail",
          name: "Group Email",
        },
        {
          // 批量发送短信
          label: this.$i18n.t("label.sendsms.batch"),
          action: "groupSMS",
          name: "Group SMS",
        },
        {
          label: "批量打印标签",
          action: "batchPrintAsset",
          name: "Batch Print Asset Code",
        },
        // {
        //   //查重合并
        //   label: this.$i18n.t("label.mergeRechecking"),
        //   action: "checkDuplicationAndMerge",
        //   name: "mergeRechecking",
        // },
        // {
        //   // 撞单分析
        //   label: "撞单分析",
        //   action: "collisionAnalysis",
        //   name: "myzdfx",
        // },
        {
          // 合并
          label: this.$i18n.t("label.checkfield.merge"),
          action: "merge",
          name: "Merge",
        },
        // 归档
        {
          label: this.$i18n.t("label.group.archived"),
          action: "file",
          name: "Archive",
        },
        // 发布
        {
          label: this.$i18n.t("pagecreator.button.publish"),
          action: "release",
          name: "Publish",
        },
        // 删除文章
        {
          label: this.$i18n.t("label.knowledgebase.delete.article"),
          action: "articledel",
          name: "Delete Article",
        },
        // 分配
        {
          label: this.$i18n.t("vue_label_pool_assign"),
          action: "assign",
          name: "Allot",
        },
        {
          // 删除草稿
          label: this.$i18n.t("label.knowledgebase.delete.draft.t"),
          action: "draftdel",
          name: "Delete Draft",
        },
        {
          // 还原
          label: this.$i18n.t("label.knowledgebase.reduction"),
          action: "recovery",
          name: "Restore",
        },
        {
          // 提交进行翻译
          label: this.$i18n.t("label.knowledgebase.submit.for.translation"),
          action: "submittranslation",
          name: "Submit For Translation",
        },
        {
          // 更改所有人
          label: this.$i18n.t("label.tabpage.cownerz"),
          action: "transferOwner",
          name: "Change Owner",
        },
        {
          // 从模板新建
          label: this.$i18n.t("front-project-module-v1-from-template-create"),
          action: "NewfromTemplate",
          name: "New from Template",
        },
      ],
      // 标记当前选择数据的数量
      selectNum: 0,
      // 当前对象Id
      objId: this.$route.params.objId,
      // 当前对象api标识
      objectApi: this.$route.params.objectApi,
      // 选择数据的id集合
      ids: "",
      //wj
      checkedList: [],
      //删除看板数据的所有id
      str: "",
      //公海池右边操作按钮对话框【恢复，回收，领取】
      clientPoolDialogIsShow_Recover_Recycle_Get: false,
      //公海池右边操作按钮对话框【退回，废弃】
      clientPoolDialogIsShow_Back_Discard: false,
      //公海池右边操作按钮对话框【转移】
      clientPoolDialogIsShow_Transfer: false,
      //公海池右边操作按钮对话框【分配】
      clientPoolDialogIsShow_Assign: false,
      //公海池右边操作按钮对话框【批量删除】
      clientPoolDialogIsShow_Delete: false,
      // 操作指令对象
      clientPoolOprationObject: {
        oprationDirctive: "", //操作指令,当前对话框所附带的事件类型（每一个 action 按钮的事件名称 ）
        oprationDirctiveName: "", //操作的指令名称
      },
      // 退回、废弃表单(选中的参数)
      Back_Discard_Form: {
        firstSelect: "",
        secondDesc: "",
        //退回公海池id
        backtopoolId: "",
      },
      // 退回、废弃的下拉选项列表
      Back_Discard_FormOptionList: [],
      // 退回、废弃的下拉选项列表请求接口时loading
      Back_Discard_FormOptionListLoading: false,
      // 转移公海池时查询可转入的公海池列表
      Transfer_FormOptionList: [],
      // 转移的表单
      Transfer_Form: { firstSelect: "" },
      // 分配的选项列表
      Assign_FormOptionList: [],
      // 分配的表单
      Assign_Form: { firstSelect: "" },
      // 删除提示语
      delContent: "",
      // 退回公海池，
      seaShow: false,
      // 删除、批量删除按钮 loading 状态
      deleteLoadingStatus: false,
      // 创建项目模板
      createplant: false,
      //创建项目模板弹框数据
      createpform: {
        date: "",
        name: "",
        region: "",
      },
      //从模板新建 弹框的验证规则
      createmplatrules: {
        date: [
          {
            required: true,
            message: this.$i18n.t(
              "front-project-module-v1-plase-input-start-time"
            ),
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: this.$i18n.t(
              "front-project-module-v1-plase-input-project-name"
            ),
            trigger: "blur",
          },
        ],
        region: [
          {
            required: true,
            message: this.$i18n.t("front-project-module-v1-plase-selectemplat"),
            trigger: "blur",
          },
        ],
      },
      //从模板新建 弹框的模板列表
      createpformoption: [],
    };
  },
  methods: {
    /**
     * 更改所有人，混入中调用了
     */

    saveTransferOwnerSuccess() {
      this.$emit("refresh");
      this.closeTransferOwnerDialog();
    },
    /**
     * 判断是否展示更多按钮,父组件中调用，该方式不能根据改变窗口宽度实时改变，待优化
     */
    showMoreBtns() {
      // 更新this.btnList
      this.init();
      // 无数据需要全部展示，保证每次打开前tag都是全部展示，每次都重新计算，但是不能根据窗口宽度自动适配，需要优化
      if (this.selectedList.length > 0) {
        this.$nextTick(() => {
          let buttons = document.getElementsByClassName(
            "batch-operation-button"
          );
          if (buttons.length == this.btnList.length) {
            //  无需重新判断
            let moreBtnBeginIndex = 0;
            if (buttons.length > 0) {
              // 判断如果按钮已经换行,显示更多按钮,点击展开剩余按钮
              for (let index = 0; index < buttons.length; index++) {
                // 修改为根据offsetTop判断是否换行
                if (buttons[index].offsetTop > 20) {
                  moreBtnBeginIndex = index;
                  break;
                }
              }
              if (moreBtnBeginIndex > 0) {
                this.moreBtnList = this.btnList.slice(
                  moreBtnBeginIndex - 1,
                  this.btnList.length
                );
                this.btnList = this.btnList.slice(0, moreBtnBeginIndex - 1);
              } else {
                this.moreBtnList = [];
              }
            }
          }
        });
      }
    },
    /**
     * 知识文章操作刷新
     */
    refreshright() {
      this.$emit("refresh");
    },
    /**
     * 获取对象下公海池
     * @param {String} val 对下ID
     */
    async selMarketsea(val) {
      let params = {
        id: val,
      };
      let res = await CommonObjApi.queryMarketSeaList(params);
      this.seaoption = res.data;
    },
    /**
     * 初始化
     */
    init() {
      this.btnList = [];
      this.transferClientOwnerDialog = false;

      this.showButtons.forEach((btn) => {
        this.buttonList.forEach((item) => {
          if (btn.name === item.name) {
            this.$set(item, "label", btn.label);
            this.btnList.push(item);
          }
        });
      });
      // 清空更多中的数据
      this.moreBtnList = [];
    },
    /**
     * 显示提示消息
     * @param {String} ssr 提示消息
     */
    open(ssr) {
      this.$message(ssr);
    },
    /**
     * 批量删除
     */
    batchdelete() {
      if (this.selectNum == 0) {
        this.$confirm(
          this.$i18n.t("lable_opppdt_selPdt_leastone"),
          this.$i18n.t("label.prompt"),
          {
            type: "warning",
          }
        );
      } else {
        if (
          this.objectApi === "CloudccProject" ||
          this.objectApi === "cloudccMilestone" ||
          this.objectApi === "CloudccProblem" ||
          this.objectApi === "cloudccTask" ||
          this.objectApi === "cloudccSubtask"
        ) {
          this.delContent = this.$i18n.t(
            "front-project-v1-batch-deletion-confirmation-information"
          );
        } else {
          this.delContent = this.$i18n.t(
            "vue_label_commonobjects_view_notice_deletecertification",
            { selectNum: this.selectNum }
          );
        }
        this.delDialogVisible = true;
      }
    },
    /**
     * 批量删除确认
     */
    confirmDel() {
      let param = {
        ids: this.ids,
        prefix: this.prefix,
      };
      this.deleteLoadingStatus = true;
      CommonObjApi.batchDelete(param)
        .then((res) => {
          this.deleteLoadingStatus = false;
          if (
            res.data.deleteerrorlist === undefined ||
            res.data.deleteerrorlist === null
          ) {
            this.$message.success(res.returnInfo);
            this.$emit("refreshAfterBatchDelete");
          } else {
            let returnInfo = "";
            res.data.deleteerrorlist.forEach((deleteerror) => {
              returnInfo += this.$i18n.t(
                "vue_label_commonobjects_view_notice_deletefailreason",
                [deleteerror.id, deleteerror.message]
              );
            });
            this.$message.error(returnInfo);
            if (
              res.data.deleteerrorlist.length !== param.ids.split(",").length
            ) {
              this.$emit("refreshAfterBatchDelete");
            }
          }
          this.val = "";
          this.delDialogVisible = false;
        })
        .catch(() => {
          this.deleteLoadingStatus = false;
        });
    },
    /**
     * 点击空白部分关闭弹窗（公海池使用）
     * @param {Function} done 事件
     */
    handleClose(done) {
      done();
      this.delDialogVisible = false;
    },
    /**
     * 公海池弹窗主动关闭
     */
    clientPoolCancle() {
      // 弹窗隐藏
      this.clientPoolDialogIsShow_Recover_Recycle_Get = false;
      this.clientPoolDialogIsShow_Back_Discard = false;
      this.clientPoolDialogIsShow_Transfer = false;
      this.clientPoolDialogIsShow_Assign = false;
      this.clientPoolDialogIsShow_Delete = false;
      this.seaShow = false;
    },
    /**
     * 公海池的对话框动画执行完毕，自动清除事件指令【交互优化】
     */
    clientPoolClosed() {
      // 清除公海池的弹窗保留的事件名称
      this.clientPoolOprationObject = {};
    },
    /**
     * 处理按钮点击事件
     * @param {Object} item 对象
     * @param {Object} action 动作
     */
    handleClick(item, action) {
      // PDF详情页视图传参没有item，去操作按钮中查找
      if (item == "") {
        this.btnList.map((btn) => {
          if (btn.action == action) {
            item = btn;
          }
        });
      }
      this.checkedList = JSON.parse(JSON.stringify(this.selectedList));
      let keys = [];
      for (let index in this.recordTypeList) {
        if (Object.prototype.hasOwnProperty.call(this.recordTypeList, index))
          keys.push(index);
        // if (this.recordTypeList.hasOwnProperty(index)) keys.push(index);
      }
      for (let i = 0; i < keys.length; i++) {
        this.index = i + 1;
      }
      if (action == "batchRemove") {
        // 批量转移
        this.selectNum = 0;
        this.ownerName = "";
        this.ownerId = "";
        this.checkList = [];
        if (this.checkedList) {
          this.selectNum = this.checkedList.length;
          if (this.selectNum == 0) {
            let srr1 = this.$i18n.t("lable.opppdt.selPdt.leastone");
            this.open(srr1);
          } else if (this.selectNum > 0 && this.selectNum <= 200) {
            let checkedList = [];
            for (let i = 0; i < this.checkedList.length; i++) {
              checkedList.push(this.checkedList[i].id);
            }
            this.ids = checkedList.join(",");
            this.initTransferOwner(this.objId, this.ids);
            this.transferClientOwnerDialog = true;
          } else if (this.selectNum > 200) {
            let srr1 = this.$i18n.t("vue_label_commonobjects_view_up_to_data");
            this.open(srr1);
          }
        } else {
          let str = this.$parent.str;
          this.str = str.split(",");
          for (let i = 0; i < this.str.length; i++) {
            this.selectNum++;
          }
          if (this.selectNum == 0) {
            let srr1 = this.$i18n.t("lable.opppdt.selPdt.leastone");
            this.open(srr1);
          } else if (this.selectNum > 0 && this.selectNum <= 200) {
            this.ids = str;
            this.initTransferOwner(this.objId, this.ids);
            this.transferClientOwnerDialog = true;
          } else if (this.selectNum > 200) {
            let srr1 = this.$i18n.t("vue_label_commonobjects_view_up_to_data");
            this.open(srr1);
          }
        }
      } else if (action == "batchDelete") {
        // 公海池页面选中数量和提示语检测
        if (this.pageFlag === "clientPool") {
          if (!this.checkCheckedCount()) {
            return;
          }
          this.clientPoolDialogIsShow_Delete = true;
          this.clientPoolOprationObject.oprationDirctive = action;
          this.clientPoolOprationObject.oprationDirctiveName = "删除";
          return;
        }

        // 批量删除
        if (this.checkedList) {
          this.selectNum = 0;
          for (let i = 0; i < this.checkedList.length; i++) {
            this.selectNum++;
          }
          if (this.selectNum > 0 && this.selectNum <= 200) {
            let checkedList = [];
            for (let i = 0; i < this.checkedList.length; i++) {
              checkedList.push(this.checkedList[i].id);
            }
            this.ids = checkedList.join(",");
            this.batchdelete();
          } else if (this.selectNum == 0) {
            let srr1 = this.$i18n.t("lable.opppdt.selPdt.leastone");
            this.open(srr1);
          } else if (this.selectNum > 200) {
            let srr1 = this.$i18n.t("vue_label_commonobjects_view_up_to_data");
            this.open(srr1);
          }
        } else {
          let str = this.$parent.str;
          this.str = str.split(",");
          this.selectNum = 0;
          for (let i = 0; i < this.str.length; i++) {
            this.selectNum++;
          }
          if (this.selectNum > 0 && this.selectNum <= 200) {
            this.ids = str;
            this.batchdelete();
          } else if (this.selectNum == 0) {
            let srr1 = this.$i18n.t("lable.opppdt.selPdt.leastone");
            this.open(srr1);
          } else if (this.selectNum > 200) {
            let srr1 = this.$i18n.t("vue_label_commonobjects_view_up_to_data");
            this.open(srr1);
          }
        }
      } else if (action === "merge") {
        // 合并
        this.selectNum = 0;
        for (let i = 0; i < this.checkedList.length; i++) {
          this.selectNum++;
        }
        if (this.selectNum < 2) {
          // 请选择至少两条数据进行合并
          let srr2 = this.$i18n.t("label.mr.selectTwoData");
          this.open(srr2);
        }
        if (this.selectNum >= 2 && this.selectNum <= 10) {
          let checkedList = [];
          for (let i = 0; i < this.checkedList.length; i++) {
            checkedList.push(this.checkedList[i].id);
          }
          let str = checkedList.join(",");
          this.$router.push({
            path: "/commonObjects/combination",
            query: {
              ids: str,
              prefix: this.prefix,
              labelName: this.tabName,
            },
          });
        }
        if (this.selectNum > 10) {
          let srr1 = this.$i18n.t(
            "vue_label_commonobjects_view_support_to_datas"
          );
          this.open(srr1);
        }
      } else if (
        action === "checkDuplicationAndMerge" ||
        action === "collisionAnalysis"
      ) {
        // 查重合并
        this.$router.push({
          path: "/commonObjects/cnki",
          query: {
            prefix: this.prefix,
            viewId: this.viewId,
            objId: this.$route.params.objId,
            objApi: this.objectApi,
          },
        });
      } else if (action == "NewfromTemplate") {
        this.createplantevevt();
      } else if (action === "GroupEmail") {
        CommonObjApi.getEmailPageInfo({}).then((result) => {
          if (result.data && result.data.relatedActiveMailSetting == true) {
            if (this.checkedList.length > 0) {
              let ids = this.checkedList.map((res) => res.id).join();
              let tabName = "";
              if (this.prefix === "004") {
                tabName = "lead";
              } else if (this.prefix == "003") {
                tabName = "contact";
              }
              let option = {
                ids: ids,
                type: tabName,
              };
              this.batchEmail = "true";
              this.$Bus.$emit("deliver-list-array", option);
            } else {
              this.batchEmail = "false";
              this.$Bus.$emit("deliver-zero-array", this.checkedList);
            }
          } else {
            this.$router.push({ path: "/relation" });
          }
        });
      } else if (action === "groupSMS") {
        if (this.checkedList.length > 0) {
          let tabName = "";
          if (this.prefix === "004") {
            tabName = "lead";
          } else if (this.prefix === "003") {
            tabName = "contact";
          }
          this.$Bus.$emit("deliver-sms-data", this.checkedList, tabName);
        } else {
          this.$alert(this.$i18n.t("label.attendance.onrecord"), {
            //请至少选择一条联系人
            confirmButtonText: this.$i18n.t("label.confirm"), //确定
          });
          // this.$Bus.$emit("deliver-select-array", this.checkedList);
        }
      } else if (action === "batchPrintAsset") {
        // 批量打印资产标签
        if (this.checkedList.length > 0) {
          let assetIdArray = [];
          this.checkedList.forEach((item) => {
            assetIdArray.push(item.id);
          });
          this.$refs.codeStyleDialog.openDialog(assetIdArray, "table");
        } else {
          this.$message.warning("请至少勾选一条记录");
        }
      } else if (action === "Get") {
        // 领取公海池
        // 公海池勾选的客户数量检测
        if (!this.checkCheckedCount()) {
          return;
        }
        this.clientPoolDialogIsShow_Recover_Recycle_Get = true;
        this.clientPoolOprationObject.oprationDirctive = action;
        this.clientPoolOprationObject.oprationDirctiveName = this.$i18n.t(
          "vue_label_pool_adopt"
        ); //领取
      } else if (action === "Assign") {
        // 清除之前表单
        this.clearClientPoolFormDataFun();
        // 分配公海池
        // 公海池勾选的客户数量检测
        if (!this.checkCheckedCount()) {
          return;
        }
        /**
         * 参数名	必选	类型	说明
          marketseaId	是	string	公海池id
         */
        CommonObjApi.getMarketseaSpecMem({
          marketseaId: this.clientPoolId,
        }).then((res) => {
          if (!res.result) {
            this.$message.error(res.stackTrace);
            return;
          }
          this.Assign_FormOptionList = res.data;
          this.clientPoolDialogIsShow_Assign = true;
          this.clientPoolOprationObject.oprationDirctive = action;
          this.clientPoolOprationObject.oprationDirctiveName = this.$i18n.t(
            "vue_label_pool_assign"
          ); //分配
        });
      } else if (action === "Back") {
        if (this.$route.params.objId === "account") {
          this.selMarketsea("account");
        } else if (this.$route.params.objId === "lead") {
          this.selMarketsea("lead");
        }
        // 退回公海池
        // 公海池勾选的客户数量检测
        if (!this.checkCheckedCount()) {
          return;
        }
        // 清除之前【 退回、废弃 】 操作的选项绑定的参数
        this.clearClientPoolFormDataFun();
        //  废弃、退回 下拉操作列表获取
        this.Back_Discard_FormOptionListFun("Back");
        // 弹窗显示
        this.clientPoolDialogIsShow_Back_Discard = true;
        this.clientPoolOprationObject.oprationDirctive = action;
        this.clientPoolOprationObject.oprationDirctiveName = this.$i18n.t(
          "vue_label_pool_return"
        ); //退回
      } else if (action === "Recycle") {
        // 回收公海池
        // 公海池勾选的客户数量检测
        if (!this.checkCheckedCount()) {
          return;
        }
        this.clientPoolDialogIsShow_Recover_Recycle_Get = true;
        this.clientPoolOprationObject.oprationDirctive = action;
        this.clientPoolOprationObject.oprationDirctiveName = this.$i18n.t(
          "vue_label_pool_recycle"
        ); //回收
      } else if (action === "Discard") {
        // 废弃公海池
        // 公海池勾选的客户数量检测
        if (!this.checkCheckedCount()) {
          return;
        }
        // 清除之前【 退回、废弃 】 操作的选项绑定的参数
        this.clearClientPoolFormDataFun();
        //  废弃、退回 下拉操作列表获取
        this.Back_Discard_FormOptionListFun("Discard");
        // 弹窗显示
        this.clientPoolDialogIsShow_Back_Discard = true;
        this.clientPoolOprationObject.oprationDirctive = action;
        this.clientPoolOprationObject.oprationDirctiveName = this.$i18n.t(
          "vue_label_pool_discard"
        ); //废弃
      } else if (action === "Recover") {
        // 恢复公海池
        // 公海池勾选的客户数量检测
        if (!this.checkCheckedCount()) {
          return;
        }
        this.clientPoolDialogIsShow_Recover_Recycle_Get = true;
        this.clientPoolOprationObject.oprationDirctive = action;
        this.clientPoolOprationObject.oprationDirctiveName = this.$i18n.t(
          "vue_label_pool_restore"
        ); //恢复
      } else if (action === "Transfer") {
        // 转移公海池
        // 公海池勾选的客户数量检测
        if (!this.checkCheckedCount()) {
          return;
        }
        // 清除之前转移的表单
        this.clearClientPoolFormDataFun();
        // 获取可转移的公海池列表选项
        CommonObjApi.selMarketsea({
          objid: this.objId,
        }).then((res) => {
          res.result && (this.Transfer_FormOptionList = res.data);
          if (!res.result) {
            this.$message.error(res.info);
            return;
          }
          this.clientPoolDialogIsShow_Transfer = true;
          this.clientPoolOprationObject.oprationDirctive = action;
          this.clientPoolOprationObject.oprationDirctiveName = this.$i18n.t(
            "vue_label_pool_move"
          ); //转移
        });
      } else if (action == "returnPool") {
        if (this.selectedList.length < 1) {
          this.$message({
            type: "warning",
            message: this.$i18n.t("lable.opppdt.selPdt.leastone"),
          });
        } else if (this.selectedList.length > 50) {
          this.$message({
            type: "warning",
            message: this.$i18n.t("label.report.selected.field.cannot"),
          });
        } else {
          this.seaShow = true;
          this.clientPoolOprationObject.oprationDirctive = action;
          this.clientPoolOprationObject.oprationDirctiveName = this.$i18n.t(
            "vue_label_pool_return"
          ); //退回
        }

        if (this.$route.params.objId === "account") {
          this.selMarketsea("account");
        } else if (this.$route.params.objId === "lead") {
          this.selMarketsea("lead");
        }
        this.Back_Discard_FormOptionListFun("Back");
      } else if (item.action == "draftdel") {
        //删除草稿
        if (this.selectedList.length > 0) {
          let thierid = [];
          for (let i in this.selectedList) {
            thierid.push(this.selectedList[i].id);
          }
          this.ids = thierid.join(",");
          this.dialogAssembly("", "draftdel");
        } else {
          // 请至少勾选一条记录
          this.$message.warning(
            this.$i18n.t("label.knowledgebase.please.check.record")
          );
        }
      } else if (item.action == "recovery") {
        //还原
        if (this.selectedList.length > 0) {
          let thierid = [];
          for (let i in this.selectedList) {
            thierid.push(this.selectedList[i].id);
          }
          this.ids = thierid.join(",");
          this.dialogAssembly("", "recovery");
        } else {
          // 请至少勾选一条记录
          this.$message.warning(
            this.$i18n.t("label.knowledgebase.please.check.record")
          );
        }
      } else if (item.action == "submittranslation") {
        //提交进行翻译
        //查询语言接口
        let params = {
          id: "",
        };
        CommonObjApi.queryLanguage(params).then((res) => {
          for (let i in res.data.languageList) {
            res.data.languageList[i].data = [
              {
                id: res.data.languageList[i].default_assignee,
                name: res.data.languageList[i].assignee_name,
              },
            ];
            res.data.languageList[i].id = Number(i);
          }
          res.data.languageList.map((o) => {
            return Object.assign(o, { value: o.default_assignee });
          });
          res.data.languageList.map((item) => {
            return Object.assign(item, { ownerType: item.assignee_ownertype });
          });
          this.tableData = res.data.languageList;
        });
        if (this.selectedList.length > 0) {
          let thierid = [];
          for (let i in this.selectedList) {
            thierid.push(this.selectedList[i].id);
          }
          this.ids = thierid.join(",");
          this.submittranslationdialogVisible = true;
        } else {
          // 请至少勾选一条记录
          this.$message.warning(
            this.$i18n.t("label.knowledgebase.please.check.record")
          );
        }
      } else if (item.action == "articledel") {
        //删除文章
        if (this.selectedList.length > 0) {
          let thierid = [];
          for (let i in this.selectedList) {
            thierid.push(this.selectedList[i].id);
          }
          this.ids = thierid.join(",");
          this.dialogAssembly("", "articledel");
        } else {
          // 请至少勾选一条记录
          this.$message.warning(
            this.$i18n.t("label.knowledgebase.please.check.record")
          );
        }
      } else if (item.action == "assign") {
        //分配
        if (this.selectedList.length > 0) {
          let thierid = [];
          for (let i in this.selectedList) {
            thierid.push(this.selectedList[i].id);
          }
          this.ids = thierid.join(",");
          this.assigndialogVisible = true;
        } else {
          // 请至少勾选一条记录
          this.$message.warning(
            this.$i18n.t("label.knowledgebase.please.check.record")
          );
        }
      } else if (item.action == "file") {
        //归档
        if (this.selectedList.length > 0) {
          let thierid = [];
          for (let i in this.selectedList) {
            thierid.push(this.selectedList[i].id);
          }
          this.ids = thierid.join(",");
          this.dialogAssembly("", "file");
        } else {
          // 请至少勾选一条记录
          this.$message.warning(
            this.$i18n.t("label.knowledgebase.please.check.record")
          );
        }
      } else if (item.action == "release") {
        //发布
        if (this.selectedList.length > 0) {
          let thierid = [];
          for (let i in this.selectedList) {
            thierid.push(this.selectedList[i].id);
          }
          this.ids = thierid.join(",");
          this.releasedialogVisible = true;
        } else {
          // 请至少勾选一条记录
          this.$message.warning(
            this.$i18n.t("label.knowledgebase.please.check.record")
          );
        }
      } else if (action == "transferOwner") {
        //知识文章批量更改所有人
        this.isKnowledge = "true";
        this.selectNum = 0;
        this.ownerName = "";
        this.ownerId = "";
        this.checkList = [];
        if (this.selectedList) {
          this.selectNum = this.selectedList.length;
          if (this.prefix === "001" && this.selectNum == 0) {
            let srr1 = this.$i18n.t("lable.opppdt.selPdt.leastone");
            this.open(srr1);
          } else if (
            this.prefix === "001" &&
            this.selectNum > 0 &&
            this.selectNum <= 200
          ) {
            let checkedList = [];
            for (let i = 0; i < this.selectedList.length; i++) {
              checkedList.push(this.selectedList[i].id);
            }
            this.ids = checkedList.join(",");
            this.initTransferOwner(this.objId, this.ids);
            this.transferClientOwnerDialog = true;
          } else if (this.prefix === "001" && this.selectNum > 200) {
            let srr1 = this.$i18n.t("vue_label_commonobjects_view_up_to_data");
            this.open(srr1);
          }
          if (
            this.prefix !== "001" &&
            this.selectNum > 0 &&
            this.selectNum <= 200
          ) {
            let checkedList = [];
            for (let i = 0; i < this.selectedList.length; i++) {
              checkedList.push(this.selectedList[i].id);
            }
            this.ids = checkedList.join(",");
            this.initTransferOwner(this.objId, this.ids);
            this.transferClientOwnerDialog = true;
          } else if (this.prefix !== "001" && this.selectNum == 0) {
            let srr1 = this.$i18n.t("lable.opppdt.selPdt.leastone");
            this.open(srr1);
          } else if (this.prefix !== "001" && this.selectNum > 200) {
            let srr1 = this.$i18n.t("vue_label_commonobjects_view_up_to_data");
            this.open(srr1);
          }
        } else {
          let str = this.$parent.str;
          this.str = str.split(",");
          for (let i = 0; i < this.str.length; i++) {
            this.selectNum++;
          }
          if (this.prefix === "001" && this.selectNum == 0) {
            let srr1 = this.$i18n.t("lable.opppdt.selPdt.leastone");
            this.open(srr1);
          } else if (
            this.prefix === "001" &&
            this.selectNum > 0 &&
            this.selectNum <= 200
          ) {
            this.ids = str;
            this.initTransferOwner(this.objId, this.ids);
            this.transferClientOwnerDialog = true;
          } else if (this.prefix === "001" && this.selectNum > 200) {
            let srr1 = this.$i18n.t("vue_label_commonobjects_view_up_to_data");
            this.open(srr1);
          }
          if (
            this.prefix !== "001" &&
            this.selectNum > 0 &&
            this.selectNum <= 200
          ) {
            this.ids = str;
            this.initTransferOwner(this.objId, this.ids);
            this.transferClientOwnerDialog = true;
          } else if (this.prefix !== "001" && this.selectNum == 0) {
            let srr1 = this.$i18n.t("lable.opppdt.selPdt.leastone");
            this.open(srr1);
          } else if (this.prefix !== "001" && this.selectNum > 200) {
            let srr1 = this.$i18n.t("vue_label_commonobjects_view_up_to_data");
            this.open(srr1);
          }
        }
      }
      this.$emit(action);
    },
    closeTransferOwnerDialog() {
      this.transferClientOwnerDialog = false;
    },
    /**
     * 改变选择
     * @param {Object} row 此行数据
     */
    changeSelect(row) {
      this.ownerName = row.data.name;
      this.ownerId = row.data.id;
      this.showSearchTable = false;
    },
    /**
     * 回收、领取、恢复
     */
    RecycleGetRecoverClientPoolConfirmEvent() {
      this.$emit(
        "clientPoolRightBtnClick",
        this.clientPoolOprationObject //操作数据
      );
      // 调用取消事件，关闭弹窗
      this.clientPoolCancle(this.clientPoolOprationObject);
    },
    /**
     * 退回按钮
     */
    Back_DiscardClientPoolConfirmEvent() {
      this.$emit(
        "clientPoolRightBtnClick",
        this.clientPoolOprationObject, //操作数据
        this.Back_Discard_Form //表单数据
      );
      // 调用取消事件，关闭弹窗
      this.clientPoolCancle();
    },
    /**
     * 退回公海池，废弃
     */
    async marketseaReturnAndRecovery() {
      let id = this.selectedList.map((item) => item.id).join(",");
      let params = {
        marketseaid: this.Back_Discard_Form.backtopoolId,
        objectApi: this.objectApi,
        thyyms: this.Back_Discard_Form.secondDesc,
        id,
        type: "retuen",
        rejectreason: this.Back_Discard_Form.firstSelect,
      };
      let res = await CommonObjApi.getReturnSeasPool(params);
      // 调用取消事件，关闭弹窗
      this.clientPoolCancle();
      if (res.result == true) {
        this.Back_Discard_Form.backtopoolId = "";
        this.Back_Discard_Form.firstSelect = "";
        this.Back_Discard_Form.secondDesc = "";
        this.$emit("change");
      }
    },
    /**
     * 转移
     */
    TransferClientPoolConfirmEvent() {
      this.$emit(
        "clientPoolRightBtnClick",
        this.clientPoolOprationObject, //操作数据
        this.Transfer_Form //表单数据
      );
      // 调用取消事件，关闭弹窗
      this.clientPoolCancle();
    },
    /**
     * 分配
     */
    AssignClientPoolConfirmEvent() {
      this.$emit(
        "clientPoolRightBtnClick",
        this.clientPoolOprationObject, //操作数据
        this.Assign_Form //表单数据
      );

      // 调用取消事件，关闭弹窗
      this.clientPoolCancle();
    },
    /**
     * 批量删除
     */
    DeleteClientPoolConfirmEvent() {
      this.$emit(
        "clientPoolRightBtnClick",
        this.clientPoolOprationObject //操作数据
      );
      // 调用取消事件，关闭弹窗
      this.clientPoolCancle();
    },
    /**
     * 创建项目模板 关闭弹框
     */
    createplanthandleClose() {
      this.createplant = false;
    },
    /**
     * 知识库发布关闭弹窗
     */
    releasehandleClose() {
      this.releasedialogVisible = false;
    },
    /**
     * 指派
     */
    assignhandleClose() {
      this.assigndialogVisible = false;
    },
    /**
     * 提交进行翻译
     */
    submittranslationhandleClose() {
      this.submittranslationdialogVisible = false;
    },
    /**
     * 项目模板新建
     */
    createplantevevt() {
      CommonObjApi.queryProjectTemplateList().then((res) => {
        if (res.result) {
          if (res.data && res.data.length > 0) {
            this.createplant = true;
            this.createpformoption = res.data;
            let date = new Date();
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            let nowDate =
              year +
              "-" +
              (month < 10 ? "0" + month : month) +
              "-" +
              (day < 10 ? "0" + day : day);
            this.createpform.date = nowDate;
            this.createpform.region = this.createpformoption[0].id;
            this.createpform.name = "";
            this.$refs.createplattform.resetFields();
          } else {
            this.$message.warning(
              this.$i18n.t("front-project-module-v1-no-selectemplat")
            );
          }
        }
      });
    },
    /**
     * 保存项目模板
     */
    saveteplant() {
      this.$refs.createplattform.validate((valid) => {
        this.$message(this.$i18n.t("label.project.template.one"));
        if (valid) {
          let params = {
            projectName: this.createpform.name,
            projectId: this.createpform.region,
            startDate: this.createpform.date,
          };
          CommonObjApi.templateSave(params).then((res) => {
            if (res.result) {
              this.$store.commit("setViewId", this.viewId);
              this.$router.push({
                path: `/commonObjects/project-detail/${res.data.id}/DETAIL`,
              });
              this.createplant = false;
            } else {
              this.$message.error(this.$i18n.t("label.custombutton.newerror"));
            }
          });
        } else {
          this.$message.warning(
            this.$i18n.t("vue_label_commonobjects_detail_check_the_content")
          );
          return false;
        }
      });
    },
  },
  watch: {
    showButtons: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.init();
        });
      },
    },
    $route(nval) {
      this.objectApi = nval.params.objectApi;
      this.objId = nval.params.objId;
    },
  },
  computed: {
    // 改变标签名称
    clientPoolCheckDialogTitle() {
      let val = "";
      if (this.objId === "lead") {
        //  如果是潜在客户
        val = this.$i18n.t("vue_label_normal_lead_sf");
      } else if (this.objId === "account") {
        //  如果是客户
        val = this.$i18n.t("vue_label_normal_account_sf");
      }
      return val;
    },
  },
};
</script>

<style lang="scss">
// 自定义气泡样式
.more-button-list-popper {
  margin-top: 0 !important;
  padding: 0 !important;
  border: 1px solid #e1e1e1 !important;

  .buttonList {
    margin: 4px 4px 0 4px;

    .item {
      font-size: 12px;
      color: #080707;
      margin-bottom: 4px;
      padding: 6px 12px;
      cursor: pointer;

      &:hover {
        background: #f5f5f5;
      }
    }

    .button-item {
      font-size: 14px;

      &:hover {
        color: #2d6cfc;
      }
    }
  }
}

.more-btn {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  background: #eeeeee;
  color: #888888;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  margin-left: 8px;
}
</style>
<style lang="scss" scoped>
::v-deep .selectedData {
  border: 1px solid #e1e1e1;
  border-radius: 19px;
  color: #080707;
  margin-right: 8px;
  background-color: $--background-color-base;

  .el-tag__close {
    color: #888 !important;

    &:hover {
      color: #888 !important;
      background: #fff;
    }
  }
}

.textItem {
  font-size: 12px;
  color: #666;
  cursor: pointer;
  line-height: 25px;
  padding: 0 12px;
  margin: 0 -12px;

  &:hover {
    background: #f3f2f2;
    color: #006dcc;
  }
}

.buttonWrapper {
  height: 32px;
  position: relative;
  .pdf-btn {
    width: 120px;
    margin-right: 10px;
  }
  .boxCard {
    width: 120px;
    position: absolute;
    right: 0;
    z-index: 10;

    ::v-deep .el-card__body {
      padding: 12px;
    }
  }
  .quickbook {
    height: 32px;
    padding: 0 12px;
    background: #ffffff;
    border: 1px solid #dedcda;
    box-shadow: none;
    font-size: 12px;
    cursor: pointer;
    color: #006dcc;
    margin: 0;
    border-radius: 0px;
  }
  .createBtn {
    height: 28px;
  }

  .top-btns {
    border: 1px solid #dedcda;
    border-radius: 3px;
    height: 30px;
    line-height: 27px;
    background: #ffff;
    margin-left: 10px;
    li:last-child {
      border-right: 0;
      span:first-child {
        // border-right: 1px solid #dedcda;
      }
    }
    li {
      font-size: 12px;
      cursor: pointer;
      border-right: 1px solid #dedcda;
      line-height: 29px;
      .btnName {
        padding: 0 12px;
        height: 100%;
        display: inline-block;
        cursor: pointer;
        color: #006dcc;
        &:hover {
          color: #006dcc;
        }
      }

      .dividingLine {
        font-size: 16px;
        color: #c8c6c6;
      }

      .icon {
        width: 29px;
        height: 27px;
        font-size: 18px;
        vertical-align: baseline;
        overflow: inherit;
        border-left: 1px solid #dedcda;
        padding: 3px 5px 0 5px;
      }
    }
  }

  .line-two-btn {
    .btn {
      padding: 0;
      height: 29px;
      border: 1px solid #c8c6c6;
    }

    img {
      width: 18px;
      height: 18px;
    }

    .group-btn {
      margin-top: -15px;
    }

    .in-left {
      margin-left: 10px;
    }

    .in-right {
      margin-right: 10px;
    }
  }
}
.tank {
  height: 480px;
  width: 90%;
  background: rgba(255, 255, 255, 1);
  z-index: 6;
  margin: 100px auto 0;
}
.title {
  height: 53px;
  line-height: 53px;
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid rgba(240, 240, 240, 1);
  color: rgba(109, 114, 120, 1);
  font-size: 18px;
}
.btnp {
  font-weight: bold;
  font-size: 17px;
}
.btnx {
  font-size: 16px;
}
.tankone {
  width: 30%;
  min-height: 359px;
  background: rgba(255, 255, 255, 1);
  margin: 110px auto;
}
.tanktwo {
  width: 90%;
  min-height: 694px;
  background: rgba(255, 255, 255, 1);
  margin: 110px auto;
}
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: rgba(51, 51, 51, 1);
}
::v-deep .el-input__icon {
  height: auto;
}
.al {
  margin: 0 24px;
  width: auto;
  overflow: auto;
  height: 259px;
  border: 1px solid rgba(190, 225, 255, 1);
}
.masstransfer {
  height: 18px;
  font-size: 18px;
  margin-top: 34px;
  margin-left: 35px;
  font-weight: bold;
  color: #666666;
  display: inline-block;
  line-height: 18px;
}
/*.al::-webkit-scrollbar-track {
  !*display: none;*!
  width: 5px;
}*/
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
::v-deep .el-input__icon {
  margin-top: -4px;
}
.mtransferli {
  list-style: inside;
  height: 34px;
  font-size: 12px;
  font-weight: 400;
  color: #0c0b0b;
  line-height: 18px;
}
ul {
  margin: 0;
}
.selectnum {
  height: 20px;
  font-size: 12px;
  margin-top: -20px;
  font-weight: 400;
  color: #666666;
  padding-bottom: 22px;
}
.checkboxs {
  margin-left: 6px;
  font-size: 12px;
  line-height: 28px;
}
.spancolor {
  color: #fa6400;
}
.spans {
  height: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  padding-top: 12px;
}
.remoteBtn {
  cursor: pointer;
}

.clientPoolDialogIsShow_Back_Discard-margin {
  margin: 5px 0;
}
.clientPoolDialogIsShow_Transfer-box-style {
  margin: 20px 0;
}

::v-deep .batch-operation-button:hover {
  border-color: $--color-primary;
  background-color: #f3f7ff;
  color: $--color-primary;
}
</style>
