<template>
  <div class="buttonWrapper">
    <!-- 第二类按钮：普通操作按钮 -->
    <div
      v-if="
        btnList.length > 0 || otherButtons.length > 0 || fileShowBtns.length > 0
      "
      class="pull-left"
    >
      <!-- 普通操作按钮和其他按钮统一展示的下拉 -->
      <el-popover
        placement="bottom"
        trigger="click"
        :visible-arrow="false"
        ref="buttonListPopper"
        popper-class="button-list-popper"
      >
        <el-button
          plain
          slot="reference"
          size="small"
          class="button-list-box button-point"
          @mouseover.native="mouseoverBtn"
          @mouseleave.native="mouseleaveBtn"
        >
          <span>
            <svg class="icon" aria-hidden="true">
              <use :href="moreIcon"></use>
            </svg>
          </span>
        </el-button>
        <div class="buttonListBox" :class="fileShowBtns.length?'pdfButtonListBox':''">
          <!-- 排序字段按钮 -->
          <ul class="sortList" v-if="fileShowBtns.length > 0">
            <div class="sort-title">
              {{ $t("label.sort") }}
            </div>
            <li v-for="item in fileShowBtns.filter((item) => item.show)"
              :key="item.id"
              class="item button-item"
              :class="sortvalue==item.id?'button-item-hover':''"
              @click="handleClick(item, item.action)"
            >
              {{ item.label }}
            </li>
          </ul>
          <!-- 普通操作按钮或者其他操作按钮个数大于1的时候 -->
          <ul class="buttonList">
            <li
              v-for="item in btnList.filter((item) => item.show)"
              :key="item.icon"
              :devid="item.id"
              class="item button-item"
              @click="handleClick(item, item.action)"
            >
              {{ item.label }}
            </li>
          </ul>
          <!-- 其他按钮 -->
          <ul
            :class="
              btnList.length > 0 ? 'buttonList otherButtons' : 'buttonList'
            "
            v-if="otherButtons.length > 0"
          >
            <li
              v-for="(item, idx) in otherButtons"
              :key="idx"
              :devid="item.id"
              class="item button-item"
              @click="handleClick(item, item.action)"
            >
              {{ item.label }}
            </li>
          </ul>
        </div>
      </el-popover>
    </div>

    <!-- 批量删除确认dialog -->
    <el-dialog
      :visible.sync="delDialogVisible"
      :title="$t('label_chatter_delete')"
      top="15%"
      width="25%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <span style="font-size: 14px; color: #080707">
        <!-- 确认删除选中的{{ selectNum }}条数据吗？ -->
        {{ delContent }}
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="delDialogVisible = false"
          size="mini"
          :loading="deleteLoadingStatus"
        >
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          @click="confirmDel"
          type="primary"
          size="mini"
          :loading="deleteLoadingStatus"
        >
          <!-- 删除 -->
          {{ $t("label.delete") }}
        </el-button>
      </span>
    </el-dialog>

    <!--批量新增dialog-->
    <el-dialog
      :visible.sync="batchAddDialog"
      top="10%"
      :title="batchAddDialogTitle"
      width="90%"
      :before-close="closeBatchAddDialog"
    >
      <batchAdd
        ref="batchAdd"
        :prefix="prefix"
        @closeBatchAddDialog="closeBatchAddDialog"
        @refresh="refresh"
        :pageFlag="pageFlag"
        :currentClientPoolSelectObj="currentClientPoolSelectObj"
        @getBatchLoading="getBatchLoading"
      />
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="closeBatchAddDialog">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          size="mini"
          type="primary"
          @click="saveBatchAdd"
          :loading="isBatchLoading"
        >
          <!-- 确定 -->
          {{ $t("label.confirm") }}
        </el-button>
      </div>
    </el-dialog>

    <!-- 转移所有人dialog-->
    <transfer-owner-dialog
      :id="ids"
      :objId="objId"
      :show-select-num="true"
      :select-num="selectNum"
      :isKnowledge="isKnowledge"
      :transfer-deault="transferDeault"
      :owner-type-list="ownerTypeList"
      :owner-raletion-list="ownerRaletionList"
      ref="transferClientOwner"
      :transfer-client-owner-dialog="transferClientOwnerDialog"
      @closeTransferOwnerDialog="closeTransferOwnerDialog"
      @saveTransferOwner="saveTransferOwner"
    ></transfer-owner-dialog>
    <!-- 批量打分dialog -->
    <el-dialog
      :title="$t('Lead_Tools_BatchScoring')"
      :visible.sync="scoringVisible"
      width="30%"
      :close-on-click-modal="false"
      class="scoring"
    >
      <div style="margin-bottom: 30px">
        <!-- 点击打分，为当前视图内潜在客户进行打分。 -->
        {{ $t("vue_label_lead_to_score") }}
        <!-- 打分过程可能需要花费较长时间，完成打分后您会收到确认邮件。 -->
        {{ $t("label.lead.score.confirm1") }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="scoringBtn" size="mini">
          <!-- 确定 -->
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 查找/查找多选dialog -->
    <el-dialog
      :title="$t('label.searchs')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
    >
      <search-table
        ref="searchTable"
        :fieldId="fieldId"
        :relevant-objid="relevantObjId"
        :relevant-prefix="relevantPrefix"
        @changeSelect="changeSelect"
      />
    </el-dialog>

    <!-- 批量发送邮件 -->
    <batch-send-email></batch-send-email>

    <!--资产条形码，二维码弹窗-->
    <code-dialog ref="codeStyleDialog"> </code-dialog>

    <!-- 批量发送短信 -->
    <shortMessageDialogMassHair></shortMessageDialogMassHair>

    <!-- 公海池右边操作按钮对话框【恢复，回收，领取】 dialog-->
    <el-dialog
      :visible.sync="clientPoolDialogIsShow_Recover_Recycle_Get"
      :title="
        $t('vue_label_pool_assign1', [
          clientPoolOprationObject.oprationDirctiveName,
          clientPoolCheckDialogTitle,
        ])
      "
      top="15%"
      width="25%"
      :close-on-click-modal="true"
      :before-close="handleClose"
      @closed="clientPoolClosed"
    >
      <!-- 对话框场景适配   【回收、领取、恢复】-->
      <span v-if="tableCheckedObjectArr.length != 0">
        <span
          style="font-size: 16px; font-weight: bold"
          v-if="
            clientPoolOprationObject.oprationDirctiveName ===
            this.$i18n.t('vue_label_pool_recycle')
          "
        >
          {{
            tableCheckedObjectArr.length > 1
              ? this.$i18n.t("lable.Confirm.recallpotential.customers", {
                  num: tableCheckedObjectArr.length,
                })
              : this.$i18n.t("lable.Confirm .potential.customers", {
                  name: tableCheckedObjectArr[0].name,
                })
          }}
        </span>
        <span
          style="font-size: 16px; font-weight: bold"
          v-if="
            clientPoolOprationObject.oprationDirctiveName ===
            this.$i18n.t('vue_label_pool_restore')
          "
        >
          {{
            tableCheckedObjectArr.length > 1
              ? this.$i18n.t("lable.Confirm.customers", {
                  num: tableCheckedObjectArr.length,
                })
              : this.$i18n.t("lable.Confirm.recovery.potential.customers", {
                  name: tableCheckedObjectArr[0].name,
                })
          }}
        </span>
        <span
          style="font-size: 16px; font-weight: bold"
          v-if="
            clientPoolOprationObject.oprationDirctiveName ===
            this.$i18n.t('vue_label_pool_adopt')
          "
        >
          {{
            tableCheckedObjectArr.length > 1
              ? this.$i18n.t("lable.Confirm.potential.customers", {
                  num: tableCheckedObjectArr.length,
                })
              : this.$i18n.t("lable.Confirm.collect.potential.customers", {
                  name: tableCheckedObjectArr[0].name,
                })
          }}
        </span>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clientPoolCancle" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          @click="RecycleGetRecoverClientPoolConfirmEvent"
          type="primary"
          size="mini"
        >
          <!-- 确定 -->
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 退回dialog -->
    <el-dialog
      :visible.sync="clientPoolDialogIsShow_Back_Discard"
      :title="
        $t('vue_label_pool_assign1', [
          clientPoolOprationObject.oprationDirctiveName,
          clientPoolCheckDialogTitle,
        ])
      "
      top="15%"
      width="25%"
      :close-on-click-modal="true"
      :before-close="handleClose"
      @closed="clientPoolClosed"
    >
      <div>
        <div class="clientPoolDialogIsShow_Back_Discard-margin">
          <span style="color: red">*</span>
          <!-- 原因 -->
          {{
            $t("vue_label_pool_reason", [
              clientPoolOprationObject.oprationDirctiveName,
            ])
          }}
        </div>
        <el-select
          v-model="Back_Discard_Form.firstSelect"
          prop="firstSelect"
          :rules="{
            required: true,
            message: 'xxxxxxxxxxxx',
            trigger: ['change', 'blur'],
          }"
          style="width: 100%"
        >
          <el-option
            :label="item.codevalue"
            :value="item.codekey"
            v-for="(item, index) in Back_Discard_FormOptionList"
            :key="index"
          />
        </el-select>
        <div class="clientPoolDialogIsShow_Back_Discard-margin">
          <!-- 原因描述 -->
          {{
            $t("vue_label_pool_reasondescription", [
              clientPoolOprationObject.oprationDirctiveName,
            ])
          }}
        </div>
        <el-input
          type="textarea"
          v-model="Back_Discard_Form.secondDesc"
        ></el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="clientPoolCancle" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          @click="Back_DiscardClientPoolConfirmEvent()"
          type="primary"
          size="mini"
          :loading="Back_Discard_FormOptionListLoading"
        >
          <!-- 确定 -->
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 公海池右边操作按钮对话框【退回，废弃】dialog -->
    <el-dialog
      :visible.sync="seaShow"
      :title="
        $t('vue_label_pool_assign1', [
          clientPoolOprationObject.oprationDirctiveName,
          clientPoolCheckDialogTitle,
        ])
      "
      top="15%"
      width="25%"
      :close-on-click-modal="true"
      :before-close="handleClose"
      @closed="clientPoolClosed"
    >
      <div v-loading="Back_Discard_FormOptionListLoading">
        <div class="clientPoolDialogIsShow_Back_Discard-margin">
          <el-form :model="Back_Discard_Form" ref="ruleForm">
            <span style="color: red">*</span>
            <!-- 选择退回的公海池 -->
            {{ $t("lable.Select.the.returned.high.seas.pool") }}
            <br />
            <el-select
              v-model="Back_Discard_Form.backtopoolId"
              prop="backtopoolId"
              :rules="{
                required: true,
                message: '请至少选择一条',
                trigger: ['change', 'blur'],
              }"
              :placeholder="$t('label_tabpage_pleaseSelectz')"
              style="width: 100%"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="(item, index) in seaoption"
                :key="index"
              />
            </el-select>
          </el-form>
        </div>

        <div class="clientPoolDialogIsShow_Back_Discard-margin">
          <span style="color: red">*</span>
          <!-- 原因 -->
          {{
            $t("vue_label_pool_reason", [
              clientPoolOprationObject.oprationDirctiveName,
            ])
          }}
        </div>
        <el-select
          v-model="Back_Discard_Form.firstSelect"
          prop="firstSelect"
          :rules="{
            required: true,
            message: 'xxxxxxxxxxxx',
            trigger: ['change', 'blur'],
          }"
          style="width: 100%"
        >
          <el-option
            :label="item.codevalue"
            :value="item.codekey"
            v-for="(item, index) in Back_Discard_FormOptionList"
            :key="index"
          />
        </el-select>
        <div class="clientPoolDialogIsShow_Back_Discard-margin">
          <!-- 原因描述 -->
          {{
            $t("vue_label_pool_reasondescription", [
              clientPoolOprationObject.oprationDirctiveName,
            ])
          }}
        </div>
        <el-input
          type="textarea"
          v-model="Back_Discard_Form.secondDesc"
        ></el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="clientPoolCancle" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          @click="marketseaReturnAndRecovery()"
          type="primary"
          size="mini"
          :loading="Back_Discard_FormOptionListLoading"
        >
          <!-- 确定 -->
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 公海池右边操作按钮对话框【转移】dialog -->
    <el-dialog
      :visible.sync="clientPoolDialogIsShow_Transfer"
      :title="
        $t('vue_label_pool_assign1', [
          clientPoolOprationObject.oprationDirctiveName,
          clientPoolCheckDialogTitle,
        ])
      "
      top="15%"
      width="25%"
      :close-on-click-modal="true"
      :before-close="handleClose"
      @closed="clientPoolClosed"
    >
      <div class="clientPoolDialogIsShow_Transfer-box-style">
        <div class="clientPoolDialogIsShow_Back_Discard-margin">
          <!-- 选择转入的公海池 -->
          {{ $t("vue_label_pool_selecttransferinto") }}
        </div>
        <el-select
          v-model="Transfer_Form.firstSelect"
          filterable
          style="width: 100%"
        >
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="(item, index) in Transfer_FormOptionList"
            :key="index"
          />
        </el-select>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="clientPoolCancle" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          @click="TransferClientPoolConfirmEvent"
          type="primary"
          size="mini"
        >
          <!-- 确定 -->
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 公海池右边操作按钮对话框【分配】dialog -->
    <el-dialog
      :visible.sync="clientPoolDialogIsShow_Assign"
      :title="
        $t('vue_label_pool_assign1', [
          clientPoolOprationObject.oprationDirctiveName,
          clientPoolCheckDialogTitle,
        ])
      "
      top="15%"
      width="25%"
      :close-on-click-modal="true"
      :before-close="handleClose"
      @closed="clientPoolClosed"
    >
      <div class="clientPoolDialogIsShow_Transfer-box-style">
        <div class="clientPoolDialogIsShow_Back_Discard-margin">
          <!-- 选择分配成员 -->
          {{ $t("vue_label_pool_assignselect") }}
        </div>
        <el-select
          v-model="Assign_Form.firstSelect"
          filterable
          style="width: 100%"
        >
          <el-option
            :label="item.username"
            :value="item.userid"
            v-for="(item, index) in Assign_FormOptionList"
            :key="index"
          />
        </el-select>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="clientPoolCancle" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          @click="AssignClientPoolConfirmEvent"
          type="primary"
          size="mini"
        >
          <!-- 确定 -->
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 公海池批量删除 dialog-->
    <el-dialog
      :visible.sync="clientPoolDialogIsShow_Delete"
      :title="$t('label_chatter_delete')"
      top="15%"
      width="25%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <span style="font-size: 16px; font-weight: bold">
        <!-- 确认删除选中的{{ tableCheckedObjectArr.length }}条数据吗？ -->
        {{
          $t("vue_label_pool_deleteconfirm", {
            tableCheckedObjectArrlength: tableCheckedObjectArr.length,
          })
        }}
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clientPoolCancle" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          @click="DeleteClientPoolConfirmEvent"
          type="primary"
          size="mini"
        >
          <!-- 删除 -->
          {{ $t("label.delete") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 知识库，删除文章dialog -->
    <Dialog
      :dialogSign="dialogSign"
      :btnName="btnName"
      :titleType="titleType"
      @dialogEdit="dialogEdit"
      @dialoghandleClose="dialoghandleClose"
    >
      <template v-slot:Dialog>
        <div>
          <div v-if="btnSign == 'draftdel'">
            <!-- "您无法恢复已删除的草稿。"
           "现有草稿翻译将链接到主语言文章的上一个发布版本。如果没有发布版本，将删除任何译稿。" -->
            <div class="dialog-type">
              {{ $t("label.knowledgebase.unable.restore.draft") }}
            </div>
            <div>
              {{
                $t(
                  "label.knowledgebase.delete.translation.associated.with.draft"
                )
              }}
            </div>
          </div>
          <div v-else-if="btnSign == 'articledel'" class="dialog-type">
            <!-- "删除已归档文章会将其和所有关联版本从知识库中永久移除。" -->
            <div>
              {{ $t("label.knowledgebase.permanently.remove.articles") }}
            </div>
          </div>
          <div v-else-if="btnSign == 'file'" class="dialog-type">
            <!-- "已发布翻译也将归档，将删除处于草稿状态的翻译。" -->
            <div>{{ $t("label.knowledgebase.published.translations") }}</div>
          </div>
          <div v-else-if="btnSign == 'recovery'" class="dialog-type">
            <!-- "还原会从此版本创建草稿。您将需要发布草稿，以使其可用。" -->
            <div>{{ $t("label.knowledgebase.restore.creates.draft") }}</div>
          </div>
          <div v-else-if="btnSign == 'draftedit'" class="dialog-type">
            <!-- "已发布版本仍将在线，直到您发布此草稿。" -->
            <div>{{ $t("label.knowledgebase.published.version.online") }}</div>
          </div>
        </div>
      </template>
    </Dialog>

    <!-- 知识库发布 -->
    <Release
      :ids="ids"
      :transfer-owner-dialog="releasedialogVisible"
      @releasehandleClose="releasehandleClose"
      :markNewVersion="markNewVersion"
      :affectTranslation="affectTranslation"
      @refreshright="refreshright"
    ></Release>

    <!-- 指派 -->
    <Distribution
      :ids="ids"
      :transfer-owner-dialog="assigndialogVisible"
      @assignhandleClose="assignhandleClose"
      @refreshright="refreshright"
    >
    </Distribution>

    <!-- 提交进行翻译 -->
    <Submittranslation
      :ids="ids"
      :tableData="tableData"
      :transfer-owner-dialog="submittranslationdialogVisible"
      @submittranslationhandleClose="submittranslationhandleClose"
      @refreshright="refreshright"
    >
    </Submittranslation>

    <Quickoperationerror
      ref="mychild"
      v-show="warningstate"
      :errortable="errortable"
      @close="close"
      @narrow="narrow"
      @errorchange="Errorchange"
      @refreshright="refreshright"
    ></Quickoperationerror>

    <!-- 项目模板新建dialog -->
    <el-dialog
      :title="$t('front-project-module-v1-template-newcreate')"
      :visible.sync="createplant"
      width="30%"
      :before-close="createplanthandleClose"
    >
      <el-form
        ref="createplattform"
        :model="createpform"
        :rules="createmplatrules"
        label-width="auto"
        size="small"
      >
        <el-form-item
          :label="$t('front-project-module-v1-select-projecttemplate')"
          prop="region"
        >
          <el-select
            v-model="createpform.region"
            :placeholder="$t('front-project-module-v1-select-projecttemplate')"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in createpformoption"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('label.projectManagement.entryname')"
          prop="name"
        >
          <el-input
            v-model="createpform.name"
            :placeholder="$t('label.projectManagement.Pleaseenterprojectname')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('label.chatter.startDate')" prop="date">
          <el-date-picker
            type="date"
            :placeholder="$t('label.chatter.startDate')"
            v-model="createpform.date"
            value-format="yyyy-MM-dd"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createplant = false">{{
          $t("label.emailtocloudcc.button.back")
        }}</el-button>
        <el-button type="primary" @click="saveteplant">{{
          $t("component.button.ok")
        }}</el-button>
      </span>
    </el-dialog>

    <!-- 在线个案二维码 -->
    <el-dialog
      :title="$t('c1764')"
      :visible.sync="caseQrCode"
      width="50%"
      :before-close="handleCaseCodeCLose"
    >
      <div class="case-code" v-loading="qrCodeLoading">
        <div class="case-code-item">
          <div class="case-code-item-left">{{ $t("c1767") }}:</div>
          <div class="case-code-item-right">
            <div class="case-code-item-right-show" v-if="!isEditCaseQR">
              <div class="case-code-item-right-show-value">
                {{$t(showQRCodeDays.days) }} 
                <span v-if="caseCodeInfo.linkValidityPeriodCopy!=='permanent'">{{"（" + $t("c1769", { Date: showQRCodeDays.date }) + "）"}}</span>
              </div>
              <div
                class="case-code-item-right-show-btn"
                @click="handleCaseCodeEdit"
              >
                <i class="el-icon-edit-outline"></i>
                <span style="padding-left: 12px">{{ $t("c1768") }}</span>
              </div>
            </div>
            <div class="case-code-item-right-edit" v-else>
              <el-select v-model="caseCodeInfo.linkValidityPeriod">
                <el-option
                  v-for="item in linkValidityPeriodOptions"
                  :key="item.value"
                  :label="$t(item.label)"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <svg @click="saveCaseCodeEdit" class="case-code-item-right-edit-icon" aria-hidden="true">
                <use href="#icon-checkMark"></use>
              </svg>
              <i
                class="el-icon-circle-close case-code-item-right-edit-icon"
                @click="cancelCaseCodeEdit"
              ></i>
            </div>
          </div>
        </div>
        <div class="case-code-item">
          <div class="case-code-item-left">{{ $t("label.address") }}:</div>
          <div class="case-code-item-right">
            <div class="case-code-item-right-show">
              <div class="case-code-item-right-show-value">
                {{ caseCodeInfo.url }}
              </div>
              <div class="case-code-item-right-show-btn" @click="copyText(caseCodeInfo.url)">
                <i class="el-icon-copy-document"></i>
                <span style="padding-left: 12px">
                  {{ $t("label.clone") }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="case-code-item">
          <div class="case-code-item-left">{{ $t("label.qrcode") }}:</div>
          <div class="case-code-item-right">
            <div class="case-code-item-right-qrcode">
              <img :src="caseCodeInfo.QRcode" alt="">
            </div>
            <div
                class="case-code-item-right-download"
                @click="handleDownlaod"
              >
              <i class="el-icon-download"></i>
              <span style="padding-left: 12px">{{ $t("c205") }}</span>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 订单列表页生成发票弹窗dialog -->
    <create-edit-obj
      ref="orderCeateEditObj"
      :object-name="orderNameRecive"
      :prefix="orderPrefix"
      :objectApi="orderApi"
      :isOrderPage="true"
      :orderInfo="orderInfo"
      :checkedLists="selectedList"
    ></create-edit-obj>
  </div>
</template>

<script>
/**
 * 新版视图页使用的button按钮
 * 提示：知识文章目前不使用这个文件
 */
import TransferOwnerMixin from "@/mixin/transferOwner.js";
import * as CommonObjApi from "./api.js";
import DialogMixin from "@/mixin/Dialog.js";
import debounce from "lodash.debounce";
import { copyText } from "@/utils/copyInfo";

export default {
  mixins: [TransferOwnerMixin, DialogMixin],
  components: {
    TransferOwnerDialog: () =>
      import("@/components/TransferOwnerDialog/index.vue"),
    batchAdd: () => import("@/components/ButtonWrapper/batchAdd"),
    batchSendEmail: () => import("@/views/Email/batchSendEmail"),
    shortMessageDialogMassHair: () =>
      import("@/views/noteSms/components/shortMessageDialogMassHair"),
    CodeDialog: () => import("@/views/commonObjects/components/CodeDialog"),
    Distribution: () => import("@/components/Distribution/index.vue"),
    Submittranslation: () => import("@/components/Submittranslation/index.vue"),
    Dialog: () => import("@/components/Dialog/index.vue"),
    Release: () => import("@/components/Release/index.vue"),
    Quickoperationerror: () => import("@/components/Quickoperationerror/index"),
    createEditObj: () =>
      import("@/views/commonObjects/components/create-edit-obj.vue"),
  },
  props: {
    // 未知变量
    // addable: {
    //   type: Boolean,
    //   default: false,
    // },

    // 被选中的列表数据
    selectedList: {
      type: Array,
      default: () => [],
    },
    // 显示的按钮：目前拆分为两大类
    showButtons: {
      type: Array,
      default: () => [],
    },
    // 其他按钮：目前放在第二类的横线下面
    otherButtons: {
      type: Array,
      default: () => [],
    },
    // 排序字段按钮：PDF视图中包含排序按钮
    fileShowBtns: {
      type: Array,
      default: () => [],
    },
    // 视图id，用于区分同一个类型数据的不同视图
    viewId: {
      type: String,
      default: "",
    },
    // 当前对象名称，目前不是多语言，后端翻译好了
    tabName: {
      type: String,
      default: "",
    },
    // 对象前缀
    prefix: {
      type: String,
      default: "",
    },
    // 公海池表格所选中的所有的对象
    tableCheckedObjectArr: {
      type: Array,
      default: () => [],
    },
    // 公海池页面标记，目前看是公海池使用的，用于标记对象的
    pageFlag: {
      type: String,
      default: "",
    },
    // 公海池ID，获取公海池成员时使用
    clientPoolId: {
      type: String,
      default: "",
    },
    // 当前的公海池对象
    currentClientPoolSelectObj: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      val: "",
      recordTypeList: [],
      index: "",
      sortvalue: "", //排序方式
      // 右边两类按钮：第二类，普通按钮，如：删除、编辑、发布、提交等
      btnList: [],
      // 商务云：订单信息
      orderInfo: {},
      // 商务云：订单名称
      orderNameRecive: "",
      // 商务云：api标识
      orderApi: "Invoices",
      // 商务云：发票前缀
      orderPrefix: "021",
      // 销售云：公海池视图选项
      seaoption: [],
      // 群发邮件标识：true为群发邮件，false为单发邮件
      batchEmail: "",
      // 拥有者姓名
      ownerName: "",
      // 拥有者id
      ownerId: "",
      // 所有人 信息写死
      fieldId: "ffe100000001265aWyi8",
      // 查找dialog中搜索组件传入对象id
      relevantObjId: "user",
      // 查找dialog中搜索组件传入前缀
      relevantPrefix: "005",
      // 是否显示查找Dialog
      showSearchTable: false,
      // 批量删除dialog是否显示
      delDialogVisible: false,
      // 批量添加dialog是否显示
      batchAddDialog: false,
      // 控制批量新增按钮loading状态
      isBatchLoading: false,
      // 批量新增dialog显示的title
      batchAddDialogTitle: this.$i18n.t("label.object.batchadd", {
        objName: this.tabName,
      }),
      // 控制转移所有人dialog是否显示
      transferClientOwnerDialog: false,
      // 控制知识库发布的dialog显隐
      releasedialogVisible: false,
      // 控制指派dialog的显隐
      assigndialogVisible: false,
      // 提交进行翻译dialog的显隐
      submittranslationdialogVisible: false,
      // 错误数据详细信息
      tableData: [],
      // 是否标记为新版本：true是，那么词条的版本号会自动加1；false不是，那么词条的版本号不会变化
      markNewVersion: "true",
      // 是否翻译关联的文章
      affectTranslation: "true",
      // 标记是否为知识库
      isKnowledge: "",
      // 按钮集合
      buttonList: [
        {
          // 产品系列设置
          label: this.$i18n.t("label.partnerCloud.seriesSettings"),
          action: "Product Family Settings",
          name: "Product Family Settings",
        },
        {
          // 新建发票
          label: this.$i18n.t("front-generate-v1-invoice"),
          action: "New Invoice",
          name: "New Invoice",
        },
        {
          // 添加标签
          label: this.$i18n.t("label.tag.addtags"),
          action: "addTag",
          name: "addTag",
        },
        {
          // 批量新增
          label: this.$i18n.t("label.batchadd"),
          action: "batchAdding",
          name: "Batch Added",
        },
        {
          // 批量转移
          label: this.$i18n.t("label.batchtransfer"),
          action: "batchRemove",
          name: "Batch Transfer",
        },
        {
          // 批量打分
          label: this.$i18n.t("Lead_Homepage_Tools_BatchScoring"),
          action: "batchScoring",
          name: "batchScoring",
        },
        {
          // 批量删除
          label: this.$i18n.t("label.batchDelete"),
          action: "batchDelete",
          name: "Batch Delete",
        },
        {
          //群发邮件
          label: this.$i18n.t("label.emailobject.groupemail"),
          action: "GroupEmail",
          name: "Group Email",
        },
        {
          // 批量发送短信
          label: this.$i18n.t("label.sendsms.batch"),
          action: "groupSMS",
          name: "Group SMS",
        },
        {
          // Batch Print Asset Code 批量打印资产标签
          label: "批量打印标签",
          action: "batchPrintAsset",
          name: "Batch Print Asset Code",
        },
        {
          //查重合并
          label: this.$i18n.t("label.mergeRechecking"),
          action: "checkDuplicationAndMerge",
          name: "mergeRechecking",
        },
        {
          // 撞单分析
          label: "撞单分析",
          action: "collisionAnalysis",
          name: "myzdfx",
        },
        {
          // 合并
          label: this.$i18n.t("label.checkfield.merge"),
          action: "merge",
          name: "Merge",
        },
        {
          // 领取
          // label: this.$i18n.t('label.checkfield.merge'),
          action: "Get",
          name: "get",
        },
        {
          // 分配公海池
          // label: this.$i18n.t('label.checkfield.merge'),
          action: "Assign",
          name: "assign",
        },
        {
          // 退回公海池
          label: this.$i18n.t("vue_label_pool_return"),
          action: "returnPool",
          name: "Return Pool",
        },
        {
          // 回退
          // label: this.$i18n.t('vue_label_pool_return'),
          action: "Back",
          name: "back",
        },
        {
          // 回收
          // label: this.$i18n.t('label.checkfield.merge'),
          action: "Recycle",
          name: "recycle",
        },
        {
          // 废弃公海池
          // label: this.$i18n.t('label.checkfield.merge'),
          action: "Discard",
          name: "discard",
        },
        {
          // 恢复公海池
          // label: this.$i18n.t('label.checkfield.merge'),
          action: "Recover",
          name: "recover",
        },
        {
          // 转移公海池
          // label: this.$i18n.t('label.checkfield.merge'),
          action: "Transfer",
          name: "transfer",
        },
        {
          // Send Invoice From QB
          label: "Send Invoice From QB",
          action: "SendQB",
          name: "Send Invoice From QB",
        },
        {
          // Update Status From QB
          label: "Update Status From QB",
          action: "UpdateQB",
          name: "Update Status From QB",
        },
        // 归档
        {
          label: this.$i18n.t("label.group.archived"),
          action: "file",
          name: "Archive",
        },
        // 发布
        {
          label: this.$i18n.t("pagecreator.button.publish"),
          action: "release",
          name: "Publish",
        },
        // 删除文章
        {
          label: this.$i18n.t("label.knowledgebase.delete.article"),
          action: "articledel",
          name: "Delete Article",
        },
        // 分配知识文章库
        {
          label: this.$i18n.t("vue_label_pool_assign"),
          action: "assign",
          name: "Allot",
        },
        {
          // 删除草稿
          label: this.$i18n.t("label.knowledgebase.delete.draft.t"),
          action: "draftdel",
          name: "Delete Draft",
        },
        {
          // 还原
          label: this.$i18n.t("label.knowledgebase.reduction"),
          action: "recovery",
          name: "Restore",
        },
        {
          // 提交进行翻译
          label: this.$i18n.t("label.knowledgebase.submit.for.translation"),
          action: "submittranslation",
          name: "Submit For Translation",
        },
        {
          // 更改所有人
          label: this.$i18n.t("label.tabpage.cownerz"),
          action: "transferOwner",
          name: "Change Owner",
        },
        {
          // 从模板新建
          label: this.$i18n.t("front-project-module-v1-from-template-create"),
          action: "NewfromTemplate",
          name: "New from Template",
        },
        {
          // 在线个案二维码 c1764
          label: this.$i18n.t("c1764"),
          action: "OnlineCaseCode",
          name: "Online Case QR Code",
        },
      ],

      // 标记当前选择数据的数量
      selectNum: 0,
      // 当前对象Id
      objId: this.$route.params.objId,
      // 当前对象api标识
      objectApi: this.$route.params.objectApi,
      // 选择数据的id集合
      ids: "",
      // prefix: this.$route.params.prefix, // 对象前缀
      //wj
      checkedList: [],
      // 批量打分
      scoringVisible: false,
      //删除看板数据的所有id
      str: "",

      //公海池右边操作按钮对话框【恢复，回收，领取】
      clientPoolDialogIsShow_Recover_Recycle_Get: false,
      //公海池右边操作按钮对话框【退回，废弃】
      clientPoolDialogIsShow_Back_Discard: false,
      //公海池右边操作按钮对话框【转移】
      clientPoolDialogIsShow_Transfer: false,
      //公海池右边操作按钮对话框【分配】
      clientPoolDialogIsShow_Assign: false,
      //公海池右边操作按钮对话框【批量删除】
      clientPoolDialogIsShow_Delete: false,
      // 操作指令对象
      clientPoolOprationObject: {
        oprationDirctive: "", //操作指令,当前对话框所附带的事件类型（每一个 action 按钮的事件名称 ）
        oprationDirctiveName: "", //操作的指令名称
      },
      // 退回、废弃表单(选中的参数)
      Back_Discard_Form: {
        firstSelect: "",
        secondDesc: "",
        //退回公海池id
        backtopoolId: "",
      },
      // 退回、废弃的下拉选项列表
      Back_Discard_FormOptionList: [],
      // 退回、废弃的下拉选项列表请求接口时loading
      Back_Discard_FormOptionListLoading: false,
      // 转移公海池时查询可转入的公海池列表
      Transfer_FormOptionList: [],
      // 转移的表单
      Transfer_Form: { firstSelect: "" },
      // 分配的选项列表
      Assign_FormOptionList: [],
      // 分配的表单
      Assign_Form: { firstSelect: "" },
      // 删除提示语
      delContent: "",
      // 退回公海池，
      seaShow: false,
      // 删除、批量删除按钮 loading 状态
      deleteLoadingStatus: false,
      // 控制批量错误弹窗是否显示
      warningstate: false,
      // ele加载框挂载的DOM
      loadingOption: {
        target: "#table-panel",
      },
      // 错误信息列表
      errortable: [],
      // 错误信息个数显示
      errorNum: 0,
      // 创建项目模板
      createplant: false,
      // 创建项目模板弹框数据
      createpform: {
        date: "",
        name: "",
        region: "",
      },
      // 从模板新建 弹框的验证规则
      createmplatrules: {
        date: [
          {
            required: true,
            message: this.$i18n.t(
              "front-project-module-v1-plase-input-start-time"
            ),
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: this.$i18n.t(
              "front-project-module-v1-plase-input-project-name"
            ),
            trigger: "blur",
          },
        ],
        region: [
          {
            required: true,
            message: this.$i18n.t("front-project-module-v1-plase-selectemplat"),
            trigger: "blur",
          },
        ],
      },
      // 从模板新建 弹框的模板列表
      createpformoption: [],
      // 同步到QB的loading
      toQB: false,
      // 更新到CC的loading
      toCC: false,
      // 更多图标控制
      moreIcon: "#icon-Normal-more1",
      // 在线个案二维码弹窗
      caseQrCode: false,
      // 在线个案二维码有效时间选项
      linkValidityPeriodOptions: [
        { label: "label.appointment.wizard.subtitle4-2-7", value: "3" },
        { label: "c1797", value: "10" },
        { label: "c1798", value: "15" },
        { label: "c1504", value: "30" },
        { label: "c1799", value: "permanent" },
      ],
      // 在线个案二维码编辑有效期
      isEditCaseQR: false,
      // 在线个案二维码信息
      caseCodeInfo: {},
      // 在线个案二维码loading
      qrCodeLoading: false,
    };
  },
  mounted() {
    //如果当前是知识文章，那么添加错误提示按钮
    if (this.objectApi === "CloudccKArticle") {
      this.btnList.push({
        label: this.$i18n.t("label.knowledgebase.display.error", {
          a: this.errorNum,
        }),
        action: "knowledgeError",
        name: "knowledgeError",
        show: this.errortable.length > 0,
      });
      // 获取错误信息
      this.warningerror();
    }
  },
  methods: {
    /**
     * 生成发票
     */
    async createInvoice() {
      // 获取发票对象信息
      CommonObjApi.getObjectGetObjectInfo({ id: "invoices" }).then((res) => {
        this.orderNameRecive = res.data.labelname;
      });
      let checkedList = JSON.parse(JSON.stringify(this.selectedList));
      if (checkedList.length === 0) {
        // 请至少选择一条
        this.$message(this.$i18n.t("lable.opppdt.selPdt.leastone"));
      } else {
        let orderid = [];
        checkedList.forEach((list) => {
          orderid.push(list.id);
        });
        orderid = orderid.join(",");
        let count = 0;
        // 判断全部订单是否为同一个客户
        let result = await CommonObjApi.isSameCustomer({ orderid });
        if (result.data.flag) {
          this.orderInfo.contract = {
            id: result.data.accountid,
            value: result.data.accountname,
          };
          count++;
        } else {
          // 仅支持同一客户下的订单生成发票。
          this.$message.error(this.$i18n.t("label.invoice.customer.is.error"));
          return;
        }
        // 判断全部订单是否为激活状态
        let result2 = await CommonObjApi.isActiveOrder({ orderid });
        if (result2.result) {
          count++;
        }
        // 判断所选订单开具发票状态
        let res = await CommonObjApi.isAlreadyGenerated({ orderid });
        if (res.result) {
          count++;
        }
        // 判断所选订单是否属于同一个币种以及价格手册
        let result3 = await CommonObjApi.isSameCurencyAndPriceBook({ orderid });
        if (result3.result) {
          this.orderInfo.pricebook2id = {
            id: result3.data.pricebookId,
            value: result3.data.pricebookname,
          };
          this.orderInfo.currency = result3.data.currency;
          count++;
        }
        if (count === 4) {
          this.$nextTick(() => {
            this.$refs.orderCeateEditObj.add();
          });
        }
      }
    },
    /**
     * 鼠标移入更改更多图标
     */
    mouseoverBtn() {
      this.moreIcon = "#icon-Click-more";
    },
    /**
     * 鼠标移除更改更多图标
     */
    mouseleaveBtn() {
      this.moreIcon = "#icon-Normal-more1";
    },
    /**
     * 获取QB权限
     */
    getQBtogether() {
      CommonObjApi.quickbookStart().then((res) => {
        if (res.result) {
          if (res.data && res.data == "1") {
            // 同步至QB
            this.btnList.push({
              // 同步至QuickBooks
              label: this.$i18n.t("front-sales-button-toQB"),
              action: "synctoQB",
              name: "synctoQB",
              show: true,
            });
            // 从QB同步：072回款，021发票
            if (this.prefix === "072" || this.prefix == "021") {
              this.btnList.push({
                label: this.$i18n.t("front-sales-button-fromQB"),
                action: "syncfromQB",
                name: "syncfromQB",
                show: true,
              });
            }
          }
        }
      });
    },
    /**
     * 同步cloudcc订单信息至QuickBooks并关联
     */
    synctoQB() {
      if (this.selectedList.length > 0 && this.selectedList.length < 11) {
        this.toQB = true;
        let idlist = [];
        this.selectedList.forEach((res) => {
          idlist.push(res.id);
        });
        let params = idlist.join(",");
        // 032 订单
        if (this.prefix == "032") {
          CommonObjApi.cloudccOrderToQBEstimate({ id: params }).then((res) => {
            this.toQB = false;
            if (res.result && res.data == "") {
              this.$message.success(
                // 同步成功
                this.$i18n.t("label.companyprofile.syncsuccess")
              );
            } else {
              if (res.data.length > 0) {
                let lastobj = res.data[res.data.length - 1];
                let lastarr = lastobj.split(",");
                let succmess = Number(
                  lastarr[0].substring(2, lastarr[0].length - 1)
                );
                let errmess = Number(
                  lastarr[1].substring(3, lastarr[1].length - 1)
                );
                if (succmess > 0 && errmess > 0) {
                  this.$message.warning(lastobj);
                } else if (succmess > 0 && errmess == 0) {
                  this.$message.success("成功" + succmess + "条");
                } else if (succmess == 0 && errmess > 0) {
                  this.$message.error("失败" + errmess + "条");
                } else {
                  this.$message.error(
                    // 同步失败
                    this.$i18n.t("lable.Synchronization.failure")
                  );
                }
              }
            }
          });
          //021发票
        } else if (this.prefix == "021") {
          CommonObjApi.cloudccInvoicesToQBInvoices({ id: params }).then(
            (res) => {
              this.toQB = false;
              if (res.result && res.data == "") {
                this.$message.success(
                  this.$i18n.t("label.companyprofile.syncsuccess")
                );
              } else {
                if (res.data.length > 0) {
                  let lastobj = res.data[res.data.length - 1];
                  let lastarr = lastobj.split(",");
                  let succmess = Number(
                    lastarr[0].substring(2, lastarr[0].length - 1)
                  );
                  let errmess = Number(
                    lastarr[1].substring(3, lastarr[1].length - 1)
                  );
                  if (succmess > 0 && errmess > 0) {
                    this.$message.warning(lastobj);
                  } else if (succmess > 0 && errmess == 0) {
                    this.$message.success("成功" + succmess + "条");
                  } else if (succmess == 0 && errmess > 0) {
                    this.$message.error("失败" + errmess + "条");
                  } else {
                    this.$message.error(
                      this.$i18n.t("lable.Synchronization.failure")
                    );
                  }
                }
              }
            }
          );
          // 072回款
        } else if (this.prefix == "072") {
          CommonObjApi.cloudccPaymentToQBPayment({ id: params }).then((res) => {
            this.toQB = false;
            if (res.result && res.data == "") {
              this.$message.success(
                this.$i18n.t("label.companyprofile.syncsuccess")
              );
            } else {
              if (res.data.length > 0) {
                let lastobj = res.data[res.data.length - 1];
                let lastarr = lastobj.split(",");
                let succmess = Number(
                  lastarr[0].substring(2, lastarr[0].length - 1)
                );
                let errmess = Number(
                  lastarr[1].substring(3, lastarr[1].length - 1)
                );
                if (succmess > 0 && errmess > 0) {
                  this.$message.warning(lastobj);
                } else if (succmess > 0 && errmess == 0) {
                  this.$message.success("成功" + succmess + "条");
                } else if (succmess == 0 && errmess > 0) {
                  this.$message.error("失败" + errmess + "条");
                } else {
                  this.$message.error(
                    this.$i18n.t("lable.Synchronization.failure")
                  );
                }
              }
            }
          });
        }
      } else if (this.selectedList.length == 0) {
        // 请至少选择一条
        this.$message.error(this.$i18n.t("lable.opppdt.selPdt.leastone"));
      } else if (this.selectedList.length > 10) {
        // 该操作最多支持10条数据
        this.$message.error(
          this.$i18n.t("vue_label_commonobjects_view_support_to_datas")
        );
      }
    },
    /**
     * 从QuickBooks同步订单信息至cloudcc
     */
    syncfromQB() {
      if (this.selectedList.length < 11) {
        this.toCC = true;
        let idlist = [];
        this.selectedList.forEach((res) => {
          idlist.push(res.id);
        });
        let params = idlist.join(",");
        if (this.prefix == "021") {
          CommonObjApi.syncInvoicesFromQB({ id: params }).then((res) => {
            this.toCC = false;
            this.refresh();
            if (res.result && res.data == "") {
              this.$message.success(
                this.$i18n.t("label.projectManagement.update.succeeded")
              );
            } else {
              if (res.data.length > 0) {
                if (this.selectedList.length > 0) {
                  let lastobj = res.data[res.data.length - 1];
                  let lastarr = lastobj.split(",");
                  let succmess = Number(
                    lastarr[0].substring(2, lastarr[0].length - 1)
                  );
                  let errmess = Number(
                    lastarr[1].substring(3, lastarr[1].length - 1)
                  );
                  if (succmess > 0 && errmess > 0) {
                    this.$message.warning(lastobj);
                  } else if (succmess > 0 && errmess == 0) {
                    this.$message.success("成功" + succmess + "条");
                  } else if (succmess == 0 && errmess > 0) {
                    this.$message.error("失败" + errmess + "条");
                  } else {
                    this.$message.error(
                      this.$i18n.t("front-verkoop-bywerking-error")
                    );
                  }
                } else {
                  let mess = res.data.substring(0, 9);
                  this.$message.success(mess);
                }
              }
            }
          });
        } else if (this.prefix == "072") {
          CommonObjApi.syncPaymentFromQB({ id: params }).then((res) => {
            this.toCC = false;
            this.refresh();
            if (res.result && res.data == "") {
              this.$message.success(
                this.$i18n.t("label.projectManagement.update.succeeded")
              );
            } else {
              if (res.data.length > 0) {
                if (this.selectedList.length > 0) {
                  let lastobj = res.data[res.data.length - 1];
                  let lastarr = lastobj.split(",");
                  let succmess = Number(
                    lastarr[0].substring(2, lastarr[0].length - 1)
                  );
                  let errmess = Number(
                    lastarr[1].substring(3, lastarr[1].length - 1)
                  );
                  if (succmess > 0 && errmess > 0) {
                    this.$message.warning(lastobj);
                  } else if (succmess > 0 && errmess == 0) {
                    this.$message.success("成功" + succmess + "条");
                  } else if (succmess == 0 && errmess > 0) {
                    this.$message.error("失败" + errmess + "条");
                  } else {
                    this.$message.error(
                      this.$i18n.t("front-verkoop-bywerking-error")
                    );
                  }
                } else {
                  let mess = res.data.substring(0, 8);
                  this.$message.success(mess);
                }
              }
            }
          });
        }
      } else {
        this.$message.error(
          this.$i18n.t("vue_label_commonobjects_view_support_to_datas")
        );
      }
    },
    /**
     * 获取对象下公海池
     * @param {String} val 对下ID
     */
    async selMarketsea(val) {
      let params = {
        id: val,
      };
      let res = await CommonObjApi.queryMarketSeaList(params);
      this.seaoption = res.data;
    },
    /**
     * 初始化
     */
    init() {
      this.btnList = [];
      this.transferClientOwnerDialog = false;
      // 如果是潜在客户或联系人，则判断是否显示导出至maichimp按钮
      if (
        (this.objectApi === "Lead" || this.objectApi === "Contact") &&
        this.$router.history.current.params.type !== "kanban"
      ) {
        this.GetMailchimpGetMailChimpPermissions();
      }
      // 如果是：021发票、032订单、072回款对象 QB相关按钮是否展示
      if (
        this.prefix == "032" ||
        this.prefix == "072" ||
        this.prefix == "021"
      ) {
        this.getQBtogether();
      }

      this.showButtons.forEach((btn) => {
        // 新建类按钮：新建、批量新增、从模板新建
        if (
          btn.name !== "New" &&
          btn.name !== "Batch Added" &&
          btn.name !== "New from Template"
        ) {
          this.buttonList.forEach((item) => {
            if (btn.name === item.name) {
              // 暂不展示Update Status From QB和Send Invoice From QB按钮

              if (item.name === "Online Case QR Code") {
                CommonObjApi.showQRCodeButton().then(res => {
                  if (res.data) {
                    this.$set(item, "label", btn.label);
                    this.$set(item, "show", true);
                    this.btnList.push(item);
                  }
                })
              } else if (
                !(
                  item.label === "Update Status From QB" ||
                  item.label === "Send Invoice From QB" ||
                  item.name === "Online Case QR Code"
                )
              ) {
                this.$set(item, "label", btn.label);
                this.$set(item, "show", true);
                (item.function_code =
                  btn.function_code || btn.functionCode || ""),
                  this.btnList.push(item);
              }
            }
          });
        }
      });
    },
    /**
     * 显示提示消息
     * @param {String} ssr 提示消息
     */
    open(ssr) {
      this.$message(ssr);
    },
    /**
     * 批量删除
     */
    batchdelete() {
      if (this.selectNum == 0) {
        this.$confirm(
          this.$i18n.t("lable_opppdt_selPdt_leastone"),
          this.$i18n.t("label.prompt"),
          {
            type: "warning",
          }
        );
      } else {
        if (
          this.objectApi === "CloudccProject" ||
          this.objectApi === "cloudccMilestone" ||
          this.objectApi === "CloudccProblem" ||
          this.objectApi === "cloudccTask" ||
          this.objectApi === "cloudccSubtask"
        ) {
          // 批量删除已选择的信息，将删除选中的信息以及与信息有关联的所有信息，且数据无法恢复，是否确认删除？
          this.delContent = this.$i18n.t(
            "front-project-v1-batch-deletion-confirmation-information"
          );
        } else {
          // 确认删除选中的{selectNum}条数据吗？
          this.delContent = this.$i18n.t(
            "vue_label_commonobjects_view_notice_deletecertification",
            { selectNum: this.selectNum }
          );
        }
        this.delDialogVisible = true;
      }
    },
    /**
     * 批量删除确认
     */
    confirmDel() {
      let param = {
        ids: this.ids,
        prefix: this.prefix,
      };
      this.deleteLoadingStatus = true;
      CommonObjApi.batchDelete(param)
        .then((res) => {
          this.deleteLoadingStatus = false;
          if (
            res.data.deleteerrorlist === undefined ||
            res.data.deleteerrorlist === null
          ) {
            this.$message.success(res.returnInfo);
            this.$parent.refreshAfterBatchDelete();
          } else {
            let returnInfo = "";
            res.data.deleteerrorlist.forEach((deleteerror) => {
              returnInfo += this.$i18n.t(
                "vue_label_commonobjects_view_notice_deletefailreason",
                [deleteerror.id, deleteerror.message]
              );
            });
            this.$message.error(returnInfo);
            if (
              res.data.deleteerrorlist.length !== param.ids.split(",").length
            ) {
              this.$parent.refreshAfterBatchDelete();
            }
          }
          this.val = "";
          this.delDialogVisible = false;
        })
        .catch(() => {
          this.deleteLoadingStatus = false;
        });
    },
    /**
     * 点击空白部分关闭弹窗（公海池使用）
     * @param {Function} done 事件
     */
    handleClose(done) {
      done();
      // 关闭批量删除dialog
      this.delDialogVisible = false;
    },
    /**
     * 公海池弹窗主动关闭
     */
    clientPoolCancle() {
      // 弹窗隐藏
      this.clientPoolDialogIsShow_Recover_Recycle_Get = false;
      this.clientPoolDialogIsShow_Back_Discard = false;
      this.clientPoolDialogIsShow_Transfer = false;
      this.clientPoolDialogIsShow_Assign = false;
      this.clientPoolDialogIsShow_Delete = false;
      this.seaShow = false;
    },
    /**
     * 公海池的对话框动画执行完毕，自动清除事件指令【交互优化】
     */
    clientPoolClosed() {
      // 清除公海池的弹窗保留的事件名称
      this.clientPoolOprationObject = {};
    },
    /**
     * 刷新页面
     */
    refresh() {
      this.$emit("refresh");
    },
    /**
     * 处理按钮点击事件,添加防抖
     * @param {Object} item 对象
     * @param {Object} action 动作
     * @param {Object} type 按钮类型
     */
    handleClick: debounce(
      function (item, action, type) {
        // 如果标准按钮如果设置了自定义事件，执行自定义事件
        if (item.function_code !== "null" &&item.function_code !== "" &&
            item.function_code !== undefined &&
            item.function_code !== null &&
            item.action !== "CustomButton") {
          let funText = item.function_code
            ? item.function_code
            : item.functionCode;
          let newFun = Function(funText);
          newFun();
          return;
        }
        this.checkedList = JSON.parse(JSON.stringify(this.selectedList));
        let keys = [];
        for (let index in this.recordTypeList) {
          if (Object.prototype.hasOwnProperty.call(this.recordTypeList, index))
            keys.push(index);
          // if (this.recordTypeList.hasOwnProperty(index)) keys.push(index);
        }
        for (let i = 0; i < keys.length; i++) {
          this.index = i + 1;
        }
        if (action == "Product Family Settings") {
          // 产品系列设置 在新标签页打开lightning设置-平台设置-产品系列设置页面
          window.open("/#/systemSettings/setuppage/productSetting");
        }
        // 新建发票
        else if (action == "New Invoice") {
          this.createInvoice();
        }
        else if (action == "batchAdding") {
          // 批量新增
          this.batchAddDialog = true;
          this.$nextTick(() => {
            this.$refs.batchAdd && this.$refs.batchAdd.init();
            this.$refs.batchAdd && this.$refs.batchAdd.getRecordType();
          });
          this.batchAddDialogTitle = `${this.$i18n.t("label.object.batchadd", {
            objName: this.tabName,
          })}`;
        } else if (action == "batchRemove") {
          // 批量转移
          this.selectNum = 0;
          this.ownerName = "";
          this.ownerId = "";
          this.checkList = [];
          if (this.checkedList) {
            this.selectNum = this.checkedList.length;
            if (this.selectNum == 0) {
              let srr1 = this.$i18n.t("lable.opppdt.selPdt.leastone");
              this.open(srr1);
            } else if (this.selectNum > 0 && this.selectNum <= 200) {
              let checkedList = [];
              for (let i = 0; i < this.checkedList.length; i++) {
                checkedList.push(this.checkedList[i].id);
              }
              this.ids = checkedList.join(",");
              this.initTransferOwner(this.objId, this.ids);
              this.transferClientOwnerDialog = true;
            } else if (this.selectNum > 200) {
              let srr1 = this.$i18n.t(
                "vue_label_commonobjects_view_up_to_data"
              );
              this.open(srr1);
            }
          } else {
            let str = this.$parent.str;
            this.str = str.split(",");
            for (let i = 0; i < this.str.length; i++) {
              this.selectNum++;
            }
            if (this.selectNum == 0) {
              let srr1 = this.$i18n.t("lable.opppdt.selPdt.leastone");
              this.open(srr1);
            } else if (this.selectNum > 0 && this.selectNum <= 200) {
              this.ids = str;
              this.initTransferOwner(this.objId, this.ids);
              this.transferClientOwnerDialog = true;
            } else if (this.selectNum > 200) {
              let srr1 = this.$i18n.t(
                "vue_label_commonobjects_view_up_to_data"
              );
              this.open(srr1);
            }
          }
        } else if (action == "batchDelete") {
          // 公海池页面选中数量和提示语检测
          if (this.pageFlag === "clientPool") {
            if (!this.checkCheckedCount()) {
              return;
            }
            this.clientPoolDialogIsShow_Delete = true;
            this.clientPoolOprationObject.oprationDirctive = action;
            this.clientPoolOprationObject.oprationDirctiveName = "删除";
            return;
          }

          // 批量删除
          if (this.checkedList) {
            this.selectNum = 0;
            for (let i = 0; i < this.checkedList.length; i++) {
              this.selectNum++;
            }
            if (this.selectNum > 0 && this.selectNum <= 200) {
              let checkedList = [];
              for (let i = 0; i < this.checkedList.length; i++) {
                checkedList.push(this.checkedList[i].id);
              }
              this.ids = checkedList.join(",");
              this.batchdelete();
            } else if (this.selectNum == 0) {
              let srr1 = this.$i18n.t("lable.opppdt.selPdt.leastone");
              this.open(srr1);
            } else if (this.selectNum > 200) {
              let srr1 = this.$i18n.t(
                "vue_label_commonobjects_view_up_to_data"
              );
              this.open(srr1);
            }
          } else {
            let str = this.$parent.str;
            this.str = str.split(",");
            this.selectNum = 0;
            for (let i = 0; i < this.str.length; i++) {
              this.selectNum++;
            }
            if (this.selectNum > 0 && this.selectNum <= 200) {
              this.ids = str;
              this.batchdelete();
            } else if (this.selectNum == 0) {
              let srr1 = this.$i18n.t("lable.opppdt.selPdt.leastone");
              this.open(srr1);
            } else if (this.selectNum > 200) {
              let srr1 = this.$i18n.t(
                "vue_label_commonobjects_view_up_to_data"
              );
              this.open(srr1);
            }
          }
        } else if (action === "merge") {
          // 合并
          this.selectNum = 0;
          for (let i = 0; i < this.checkedList.length; i++) {
            this.selectNum++;
          }
          if (this.selectNum < 2) {
            // 请选择至少两条数据进行合并
            let srr2 = this.$i18n.t("label.mr.selectTwoData");
            this.open(srr2);
          }
          if (this.selectNum >= 2 && this.selectNum <= 10) {
            let checkedList = [];
            for (let i = 0; i < this.checkedList.length; i++) {
              checkedList.push(this.checkedList[i].id);
            }
            let str = checkedList.join(",");
            this.$router.push({
              path: "/commonObjects/combination",
              query: {
                ids: str,
                prefix: this.prefix,
                labelName: this.tabName,
              },
            });
          }
          if (this.selectNum > 10) {
            let srr1 = this.$i18n.t(
              "vue_label_commonobjects_view_support_to_datas"
            );
            this.open(srr1);
          }
        } else if (
          action === "checkDuplicationAndMerge" ||
          action === "collisionAnalysis"
        ) {
          // 查重合并
          this.$router.push({
            path: "/commonObjects/cnki",
            query: {
              prefix: this.prefix,
              viewId: this.viewId,
              objId: this.$route.params.objId,
              objApi: this.objectApi,
            },
          });
        } else if (action == "NewfromTemplate") {
          this.createplantevevt();
        } else if (action === "batchScoring") {
          if (
            this.viewId !== "0" &&
            this.viewId !== "1" &&
            this.viewId !== "2" &&
            this.viewId !== "3"
          ) {
            this.scoringVisible = true;
          } else {
            this.scoringVisible = false;
          }
        } else if (action === "GroupEmail") {
          CommonObjApi.getEmailPageInfo({}).then((result) => {
            if (result.data && result.data.relatedActiveMailSetting == true) {
              if (this.checkedList.length > 0) {
                let ids = this.checkedList.map((res) => res.id).join();
                let tabName = "";
                if (this.prefix === "004") {
                  tabName = "lead";
                } else if (this.prefix == "003") {
                  tabName = "contact";
                }
                let option = {
                  ids: ids,
                  type: tabName,
                };
                this.batchEmail = "true";
                this.$Bus.$emit("deliver-list-array", option);
              } else {
                this.batchEmail = "false";
                this.$Bus.$emit("deliver-zero-array", this.checkedList);
              }
            } else {
              this.$router.push({ path: "/relation" });
            }
          });
        } else if (action === "groupSMS") {
          if (this.checkedList.length > 0) {
            let tabName = "";
            if (this.prefix === "004") {
              tabName = "lead";
            } else if (this.prefix === "003") {
              tabName = "contact";
            }
            this.$Bus.$emit("deliver-sms-data", this.checkedList, tabName);
          } else {
            this.$alert(this.$i18n.t("label.attendance.onrecord"), {
              //请至少选择一条联系人
              confirmButtonText: this.$i18n.t("label.confirm"), //确定
            });
          }
        } else if (action === "batchPrintAsset") {
          // 批量打印资产标签
          if (this.checkedList.length > 0) {
            let assetIdArray = [];
            this.checkedList.forEach((item) => {
              assetIdArray.push(item.id);
            });
            this.$refs.codeStyleDialog.openDialog(assetIdArray, "table");
          } else {
            this.$message.warning("请至少勾选一条记录");
          }
        } else if (action === "Get") {
          // 领取公海池
          // 公海池勾选的客户数量检测
          if (!this.checkCheckedCount()) {
            return;
          }
          this.clientPoolDialogIsShow_Recover_Recycle_Get = true;
          this.clientPoolOprationObject.oprationDirctive = action;
          this.clientPoolOprationObject.oprationDirctiveName = this.$i18n.t(
            "vue_label_pool_adopt"
          ); //领取
        } else if (action === "Assign") {
          // 清除之前表单
          this.clearClientPoolFormDataFun();
          // 分配公海池
          // 公海池勾选的客户数量检测
          if (!this.checkCheckedCount()) {
            return;
          }
          /**
         * 参数名	必选	类型	说明
          marketseaId	是	string	公海池id
         */
          CommonObjApi.getMarketseaSpecMem({
            marketseaId: this.clientPoolId,
          }).then((res) => {
            if (!res.result) {
              this.$message.error(res.stackTrace);
              return;
            }
            this.Assign_FormOptionList = res.data;
            this.clientPoolDialogIsShow_Assign = true;
            this.clientPoolOprationObject.oprationDirctive = action;
            this.clientPoolOprationObject.oprationDirctiveName = this.$i18n.t(
              "vue_label_pool_assign"
            ); //分配
          });
        } else if (action === "Back") {
          if (this.$route.params.objId === "account") {
            this.selMarketsea("account");
          } else if (this.$route.params.objId === "lead") {
            this.selMarketsea("lead");
          }
          // 退回公海池
          // 公海池勾选的客户数量检测
          if (!this.checkCheckedCount()) {
            return;
          }
          // 清除之前【 退回、废弃 】 操作的选项绑定的参数
          this.clearClientPoolFormDataFun();
          //  废弃、退回 下拉操作列表获取
          this.Back_Discard_FormOptionListFun("Back");
          // 弹窗显示
          this.clientPoolDialogIsShow_Back_Discard = true;
          this.clientPoolOprationObject.oprationDirctive = action;
          this.clientPoolOprationObject.oprationDirctiveName = this.$i18n.t(
            "vue_label_pool_return"
          ); //退回
        } else if (action === "Recycle") {
          // 回收公海池
          // 公海池勾选的客户数量检测
          if (!this.checkCheckedCount()) {
            return;
          }
          this.clientPoolDialogIsShow_Recover_Recycle_Get = true;
          this.clientPoolOprationObject.oprationDirctive = action;
          this.clientPoolOprationObject.oprationDirctiveName = this.$i18n.t(
            "vue_label_pool_recycle"
          ); //回收
        } else if (action === "Discard") {
          // 废弃公海池
          // 公海池勾选的客户数量检测
          if (!this.checkCheckedCount()) {
            return;
          }
          // 清除之前【 退回、废弃 】 操作的选项绑定的参数
          this.clearClientPoolFormDataFun();
          //  废弃、退回 下拉操作列表获取
          this.Back_Discard_FormOptionListFun("Discard");
          // 弹窗显示
          this.clientPoolDialogIsShow_Back_Discard = true;
          this.clientPoolOprationObject.oprationDirctive = action;
          this.clientPoolOprationObject.oprationDirctiveName = this.$i18n.t(
            "vue_label_pool_discard"
          ); //废弃
        } else if (action === "Recover") {
          // 恢复公海池
          // 公海池勾选的客户数量检测
          if (!this.checkCheckedCount()) {
            return;
          }
          this.clientPoolDialogIsShow_Recover_Recycle_Get = true;
          this.clientPoolOprationObject.oprationDirctive = action;
          this.clientPoolOprationObject.oprationDirctiveName = this.$i18n.t(
            "vue_label_pool_restore"
          ); //恢复
        } else if (action === "Transfer") {
          // 转移公海池
          // 公海池勾选的客户数量检测
          if (!this.checkCheckedCount()) {
            return;
          }
          // 清除之前转移的表单
          this.clearClientPoolFormDataFun();
          // 获取可转移的公海池列表选项
          CommonObjApi.selMarketsea({
            objid: this.objId,
          }).then((res) => {
            res.result && (this.Transfer_FormOptionList = res.data);
            if (!res.result) {
              this.$message.error(res.info);
              return;
            }
            this.clientPoolDialogIsShow_Transfer = true;
            this.clientPoolOprationObject.oprationDirctive = action;
            this.clientPoolOprationObject.oprationDirctiveName = this.$i18n.t(
              "vue_label_pool_move"
            ); //转移
          });
        } else if (action == "returnPool") {
          if (this.selectedList.length < 1) {
            this.$message({
              type: "warning",
              message: this.$i18n.t("lable.opppdt.selPdt.leastone"),
            });
          } else if (this.selectedList.length > 50) {
            this.$message({
              type: "warning",
              message: this.$i18n.t("label.report.selected.field.cannot"),
            });
          } else {
            this.seaShow = true;
            this.clientPoolOprationObject.oprationDirctive = action;
            this.clientPoolOprationObject.oprationDirctiveName = this.$i18n.t(
              "vue_label_pool_return"
            ); //退回
          }

          if (this.$route.params.objId === "account") {
            this.selMarketsea("account");
          } else if (this.$route.params.objId === "lead") {
            this.selMarketsea("lead");
          }
          this.Back_Discard_FormOptionListFun("Back");
        } else if (action == "UpdateQB") {
          //发票页面按钮
          if (this.selectedList.length < 1) {
            this.$message({
              type: "warning",
              message: this.$i18n.t("lable.opppdt.selPdt.leastone"),
            });
          } else {
            if (this.selectedList.length >= 200) {
              this.$message({
                type: "warning",
                message: this.$i18n.t("label.Select.maximumecords.operate"),
              });
              return;
            }
            CommonObjApi.updateStatusFromQB({
              accessToken: this.$CCDK.CCToken.getToken(),
              ids: sessionStorage.getItem("id"),
            }).then((res) => {
              if (res.result == true) {
                this.$message({
                  type: "success",
                  message: this.$i18n.t("label.companyprofile.syncsuccess"),
                });
              } else {
                this.$message({
                  type: "warning",
                  message: this.$i18n.t("lable.Synchronization.failure"),
                });
              }
            });
          }
        } else if (action == "SendQB") {
          //发票页面按钮
          if (this.selectedList.length < 1) {
            this.$message({
              type: "warning",
              message: this.$i18n.t("lable.opppdt.selPdt.leastone"),
            });
          } else {
            if (this.selectedList.length >= 200) {
              this.$message({
                type: "warning",
                message: this.$i18n.t("label.Select.maximumecords.operate"),
              });
              return;
            }
            CommonObjApi.sendInvoiceFromQB({
              accessToken: this.$CCDK.CCToken.getToken(),
              ids: sessionStorage.getItem("id"),
            }).then((res) => {
              if (res.result == true) {
                this.$message({
                  type: "success",
                  message: this.$i18n.t("label.companyprofile.syncsuccess"),
                });
              } else {
                this.$message({
                  type: "warning",
                  message: this.$i18n.t("lable.Synchronization.failure"),
                });
              }
            });
          }
        } else if (item.action == "draftdel") {
          //删除草稿
          if (this.selectedList.length > 0) {
            let thierid = [];
            for (let i in this.selectedList) {
              thierid.push(this.selectedList[i].id);
            }
            this.ids = thierid.join(",");
            this.dialogAssembly("", "draftdel");
          } else {
            // 请至少勾选一条记录
            this.$message.warning(
              this.$i18n.t("label.knowledgebase.please.check.record")
            );
          }
        } else if (item.action == "recovery") {
          //还原
          if (this.selectedList.length > 0) {
            let thierid = [];
            for (let i in this.selectedList) {
              thierid.push(this.selectedList[i].id);
            }
            this.ids = thierid.join(",");
            this.dialogAssembly("", "recovery");
          } else {
            // 请至少勾选一条记录
            this.$message.warning(
              this.$i18n.t("label.knowledgebase.please.check.record")
            );
          }
        } else if (item.action == "submittranslation") {
          //提交进行翻译
          //查询语言接口
          let params = {
            id: "",
          };
          CommonObjApi.queryLanguage(params).then((res) => {
            for (let i in res.data.languageList) {
              res.data.languageList[i].data = [
                {
                  id: res.data.languageList[i].default_assignee,
                  name: res.data.languageList[i].assignee_name,
                },
              ];
              res.data.languageList[i].id = Number(i);
            }
            res.data.languageList.map((o) => {
              return Object.assign(o, { value: o.default_assignee });
            });
            res.data.languageList.map((item) => {
              return Object.assign(item, {
                ownerType: item.assignee_ownertype,
              });
            });
            this.tableData = res.data.languageList;
          });
          if (this.selectedList.length > 0) {
            let thierid = [];
            for (let i in this.selectedList) {
              thierid.push(this.selectedList[i].id);
            }
            this.ids = thierid.join(",");
            this.submittranslationdialogVisible = true;
          } else {
            // 请至少勾选一条记录
            this.$message.warning(
              this.$i18n.t("label.knowledgebase.please.check.record")
            );
          }
        } else if (item.action == "articledel") {
          //删除文章
          if (this.selectedList.length > 0) {
            let thierid = [];
            for (let i in this.selectedList) {
              thierid.push(this.selectedList[i].id);
            }
            this.ids = thierid.join(",");
            this.dialogAssembly("", "articledel");
          } else {
            // 请至少勾选一条记录
            this.$message.warning(
              this.$i18n.t("label.knowledgebase.please.check.record")
            );
          }
        } else if (item.action == "assign") {
          //分配
          if (this.selectedList.length > 0) {
            let thierid = [];
            for (let i in this.selectedList) {
              thierid.push(this.selectedList[i].id);
            }
            this.ids = thierid.join(",");
            this.assigndialogVisible = true;
          } else {
            // 请至少勾选一条记录
            this.$message.warning(
              this.$i18n.t("label.knowledgebase.please.check.record")
            );
          }
        } else if (item.action == "file") {
          //归档
          if (this.selectedList.length > 0) {
            let thierid = [];
            for (let i in this.selectedList) {
              thierid.push(this.selectedList[i].id);
            }
            this.ids = thierid.join(",");
            this.dialogAssembly("", "file");
          } else {
            // 请至少勾选一条记录
            this.$message.warning(
              this.$i18n.t("label.knowledgebase.please.check.record")
            );
          }
        } else if (item.action == "release") {
          //发布
          if (this.selectedList.length > 0) {
            let thierid = [];
            for (let i in this.selectedList) {
              thierid.push(this.selectedList[i].id);
            }
            this.ids = thierid.join(",");
            this.releasedialogVisible = true;
          } else {
            // 请至少勾选一条记录
            this.$message.warning(
              this.$i18n.t("label.knowledgebase.please.check.record")
            );
          }
        } else if (action == "transferOwner") {
          //知识文章批量更改所有人
          this.isKnowledge = "true";
          this.selectNum = 0;
          this.ownerName = "";
          this.ownerId = "";
          this.checkList = [];
          if (this.selectedList) {
            this.selectNum = this.selectedList.length;
            if (this.prefix === "001" && this.selectNum == 0) {
              let srr1 = this.$i18n.t("lable.opppdt.selPdt.leastone");
              this.open(srr1);
            } else if (
              this.prefix === "001" &&
              this.selectNum > 0 &&
              this.selectNum <= 200
            ) {
              let checkedList = [];
              for (let i = 0; i < this.selectedList.length; i++) {
                checkedList.push(this.selectedList[i].id);
              }
              this.ids = checkedList.join(",");
              this.initTransferOwner(this.objId, this.ids);
              this.transferClientOwnerDialog = true;
            } else if (this.prefix === "001" && this.selectNum > 200) {
              let srr1 = this.$i18n.t(
                "vue_label_commonobjects_view_up_to_data"
              );
              this.open(srr1);
            }
            if (
              this.prefix !== "001" &&
              this.selectNum > 0 &&
              this.selectNum <= 200
            ) {
              let checkedList = [];
              for (let i = 0; i < this.selectedList.length; i++) {
                checkedList.push(this.selectedList[i].id);
              }
              this.ids = checkedList.join(",");
              this.initTransferOwner(this.objId, this.ids);
              this.transferClientOwnerDialog = true;
            } else if (this.prefix !== "001" && this.selectNum == 0) {
              let srr1 = this.$i18n.t("lable.opppdt.selPdt.leastone");
              this.open(srr1);
            } else if (this.prefix !== "001" && this.selectNum > 200) {
              let srr1 = this.$i18n.t(
                "vue_label_commonobjects_view_up_to_data"
              );
              this.open(srr1);
            }
          } else {
            let str = this.$parent.str;
            this.str = str.split(",");
            for (let i = 0; i < this.str.length; i++) {
              this.selectNum++;
            }
            if (this.prefix === "001" && this.selectNum == 0) {
              let srr1 = this.$i18n.t("lable.opppdt.selPdt.leastone");
              this.open(srr1);
            } else if (
              this.prefix === "001" &&
              this.selectNum > 0 &&
              this.selectNum <= 200
            ) {
              this.ids = str;
              this.initTransferOwner(this.objId, this.ids);
              this.transferClientOwnerDialog = true;
            } else if (this.prefix === "001" && this.selectNum > 200) {
              let srr1 = this.$i18n.t(
                "vue_label_commonobjects_view_up_to_data"
              );
              this.open(srr1);
            }
            if (
              this.prefix !== "001" &&
              this.selectNum > 0 &&
              this.selectNum <= 200
            ) {
              this.ids = str;
              this.initTransferOwner(this.objId, this.ids);
              this.transferClientOwnerDialog = true;
            } else if (this.prefix !== "001" && this.selectNum == 0) {
              let srr1 = this.$i18n.t("lable.opppdt.selPdt.leastone");
              this.open(srr1);
            } else if (this.prefix !== "001" && this.selectNum > 200) {
              let srr1 = this.$i18n.t(
                "vue_label_commonobjects_view_up_to_data"
              );
              this.open(srr1);
            }
          }
        } else if (action === "knowledgeError") {
          // 知识库操作错误按钮
          this.warningstate = true;
          this.warningerror();
        } else if (action === "synctoQB") {
          // 同步至QB
          this.synctoQB();
        } else if (action === "syncfromQB") {
          // 从QB同步
          this.syncfromQB();
        } else if (action === "sort") {
          // 单击升序，双击降序
          if(!this.sortvalue){
            this.sortvalue=item.id;
            this.$emit("fileSortFun",item.codevalue,'asc')
          }else if(item.id==this.sortvalue){
            this.sortvalue="";
            this.$emit("fileSortFun",item.codevalue,'desc');
          }else{
            this.sortvalue=item.id;
            this.$emit("fileSortFun",item.codevalue,'asc')
          }
        } else if (action === "OnlineCaseCode") {
          // 在线个人二维码
          this.openCaseCode();
        }
        this.$emit(action);
        //公海池按钮点击
        this.$emit("highSpoolAction", action);

        // 关闭对应按钮列表气泡
        if (type === "create") {
          this.$refs.moreButtonPopper.doClose();
        } else {
          this.$refs.buttonListPopper.doClose();
        }
        // 指定在延迟开始前调用
      },
      1000,
      {
        leading: true,
        trailing: false,
      }
    ),
    /**
     * 编辑有效期
     */
    handleCaseCodeEdit() {
      this.isEditCaseQR = true;
      this.caseCodeInfo.linkValidityPeriod = this.caseCodeInfo.linkValidityPeriodCopy;
    },
    /**
     * 保存编辑有效期
     */
    saveCaseCodeEdit() {
      this.qrCodeLoading = true;
      CommonObjApi.getCaseQRCode({
        expiryDate: this.caseCodeInfo.linkValidityPeriod
      }).then(res=>{
        if (res.result) {
          this.caseCodeInfo = {
            linkValidityPeriod: res.data.expiry,
            linkValidityPeriodCopy: res.data.expiry,
            url: res.data.address,
            QRcode: res.data.QRCode,
          }
          this.isEditCaseQR = false;
          this.qrCodeLoading = false;
        }
      })
      this.caseCodeInfo.linkValidityPeriodCopy = this.caseCodeInfo.linkValidityPeriod;
    },
    /**
     * 取消编辑有效期
     */
    cancelCaseCodeEdit() {
      this.isEditCaseQR = false;
      this.caseCodeInfo.linkValidityPeriod = this.caseCodeInfo.linkValidityPeriodCopy;
    },
    /**
     * 在线个案二维码
     */
    openCaseCode() {
      this.qrCodeLoading = true;
      CommonObjApi.getCaseQRCode(
      {
        expiryDate: "30"
      }
      ).then(res=>{
        if (res.result) {
          this.caseCodeInfo = {
            linkValidityPeriod: res.data.expiry,
            linkValidityPeriodCopy: res.data.expiry,
            url: res.data.address,
            QRcode: res.data.QRCode,
          }
          this.caseQrCode = true;
          this.qrCodeLoading = false;
        }
      })
    },
    /**
     * 获取当前日期n天后的日期
     */
    getExpiryDate(num){
      let n = num * 1
      var date = new Date();
      date.setDate(date.getDate() + n)	
      let year = date.getFullYear()
      let month = date.getMonth() + 1;
      let day = date.getDate();				
      if(month >= 1 && month <= 9) {
        month = `0${month}`
      }
      if(day >= 1 && day <= 9) {
        day = `0${day}`
      }
      return `${year}-${month}-${day}`
    },
    copyText,
    /**
     * 在线个案二维码
     */
    handleCaseCodeCLose() {
      this.caseQrCode = false;
    },
    /**
     * 下载二维码
     */
    handleDownlaod() {
      const a = document.createElement('a')
      a.href = this.caseCodeInfo.QRcode
      a.download = this.$i18n.t("c1764")
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    /**
     * 批量打分
     */
    scoringBtn() {
      CommonObjApi.BatchLeadScore({ viewId: this.viewId }).then((res) => {
        if (res.result && res.returnCode === "1") {
          this.$message({
            showClose: true,
            type: "success",
            message: this.$i18n.t("vue_label_lead_score_successsful"),
          });
        } else {
          this.$message.error(res.returnInfo);
        }
      });
      this.scoringVisible = false;
    },
    /**
     * 保存批量新增
     */
    saveBatchAdd() {
      this.$refs.batchAdd.savedata();
    },
    /**
     * 关闭批量新增
     */
    closeBatchAddDialog() {
      this.batchAddDialog = false;
    },
    /**
     * 获取批量新增保存loading状态
     * @param {Boolean} n 按钮对象
     */
    getBatchLoading(n) {
      this.isBatchLoading = n;
    },
    /**
     * 更改所有人
     * 混入中调用了
     */

    saveTransferOwnerSuccess() {
      this.$emit("refresh");
      this.closeTransferOwnerDialog();
      if (this.objectApi === "CloudccKArticle") {
        this.warningerror();
      }
    },
    /**
     * 关闭更改所有人Dialog
     */
    closeTransferOwnerDialog() {
      this.transferClientOwnerDialog = false;
    },
    /**
     * 改变选择
     * @param {Object} row 此行数据
     */
    changeSelect(row) {
      this.ownerName = row.data.name;
      this.ownerId = row.data.id;
      this.showSearchTable = false;
    },
    /**
     * 回收、领取、恢复
     */
    RecycleGetRecoverClientPoolConfirmEvent() {
      // 公海池弹窗二次确认事件调用（发射）
      this.$emit(
        "clientPoolRightBtnClick",
        this.clientPoolOprationObject //操作数据
      );
      // 调用取消事件，关闭弹窗
      this.clientPoolCancle(this.clientPoolOprationObject);
    },
    /**
     * 退回按钮
     */
    Back_DiscardClientPoolConfirmEvent() {
      this.$emit(
        "clientPoolRightBtnClick",
        this.clientPoolOprationObject, //操作数据
        this.Back_Discard_Form //表单数据
      );
      // 调用取消事件，关闭弹窗
      this.clientPoolCancle();
    },
    /**
     * 退回公海池，废弃
     */
    async marketseaReturnAndRecovery() {
      let id = this.selectedList.map((item) => item.id).join(",");
      let params = {
        marketseaid: this.Back_Discard_Form.backtopoolId,
        objectApi: this.objectApi,
        thyyms: this.Back_Discard_Form.secondDesc,
        id,
        type: "retuen",
        rejectreason: this.Back_Discard_Form.firstSelect,
      };
      let res = await CommonObjApi.getReturnSeasPool(params);
      // 调用取消事件，关闭弹窗
      this.clientPoolCancle();
      if (res.result == true) {
        this.Back_Discard_Form.backtopoolId = "";
        this.Back_Discard_Form.firstSelect = "";
        this.Back_Discard_Form.secondDesc = "";
        this.$emit("change");
      }
    },

    /**
     * 转移
     */
    TransferClientPoolConfirmEvent() {
      this.$emit(
        "clientPoolRightBtnClick",
        this.clientPoolOprationObject, //操作数据
        this.Transfer_Form //表单数据
      );
      // 调用取消事件，关闭弹窗
      this.clientPoolCancle();
    },
    /**
     * 分配
     */
    AssignClientPoolConfirmEvent() {
      this.$emit(
        "clientPoolRightBtnClick",
        this.clientPoolOprationObject, //操作数据
        this.Assign_Form //表单数据
      );
      // 调用取消事件，关闭弹窗
      this.clientPoolCancle();
    },
    /**
     * 批量删除
     */
    DeleteClientPoolConfirmEvent() {
      this.$emit(
        "clientPoolRightBtnClick",
        this.clientPoolOprationObject //操作数据
      );
      // 调用取消事件，关闭弹窗
      this.clientPoolCancle();
    },
    /**
     * 公海池勾选的客户数量检测
     */
    checkCheckedCount() {
      if (this.tableCheckedObjectArr.length === 0) {
        this.$message.warning(this.$i18n.t("lable.opppdt.selPdt.leastone")); //请选择至少1条数据
        return false;
      } else if (this.tableCheckedObjectArr.length > 200) {
        this.$message.warning(
          this.$i18n.t("vue_label_commonobjects_view_up_to_data")
        ); //最多选择200条数据
        return false;
      } else {
        return true;
      }
    },
    /**
     * 废弃、退回 下拉操作列表获取
     * @param {String} status 数据状态
     */
    Back_Discard_FormOptionListFun(status) {
      /**
          ffeleadmarketsea05  潜在客户 退回原因
          ffeaccountmarketsea5  客户

          ffeleadmarketsea07 潜在 客户废弃
          ffeaccountmarketsea7 客户废弃
         */
      let fieldId = null;
      if (this.objId === "lead") {
        //  如果是潜在客户
        if (status === "Back") {
          // 如果是 【退回操作】
          fieldId = "ffeleadmarketsea05";
        } else if (status === "Discard") {
          // 如果是 【废弃操作】
          fieldId = "ffeleadmarketsea07";
        }
      } else if (this.objId === "account") {
        //  如果是客户
        if (status === "Back") {
          // 如果是 【退回操作】
          fieldId = "ffeaccountmarketsea5";
        } else if (status === "Discard") {
          // 如果是 【废弃操作】
          fieldId = "ffeaccountmarketsea7";
        }
      }
      // 加载中
      this.Back_Discard_FormOptionListLoading = true;
      // 获取选项下拉列表
      CommonObjApi.getSelectValue({
        fieldId,
      }).then((res) => {
        res.result && (this.Back_Discard_FormOptionList = res.data);
        // 加载中
        this.Back_Discard_FormOptionListLoading = false;
      });
    },
    /**
     * 公海池每次打开某个对话框，清除之前表单记录
     */
    clearClientPoolFormDataFun() {
      // 退回、废弃表单(选中的参数)
      this.Back_Discard_Form.firstSelect = "";
      this.Back_Discard_Form.secondDesc = "";
      //转移的表单
      this.Transfer_Form.firstSelect = "";
      //分配的表单
      this.Assign_Form.firstSelect = "";
    },
    /**
     * 判断用户是否有导出至公海池权限，如果有，那么添加导出至公海池按钮
     */
    async GetMailchimpGetMailChimpPermissions() {
      //获取Mailchimp权限
      let res = await CommonObjApi.GetMailchimpGetMailChimpPermission();
      if (
        res.data &&
        (this.objectApi === "Lead" || this.objectApi === "Contact")
      ) {
        // 导出至mailchimp
        this.btnList.push({
          label: this.$i18n.t("label.integrations.mailchimp.export"),
          action: "mailchimp",
          name: "mailchimp",
          show: true,
        });
      }
    },
    /**
     * 创建项目模板 关闭弹框
     */
    createplanthandleClose() {
      this.createplant = false;
    },
    /**
     * 知识库发布关闭弹窗
     */
    releasehandleClose() {
      this.releasedialogVisible = false;
    },
    /**
     * 指派
     */
    assignhandleClose() {
      this.assigndialogVisible = false;
    },
    /**
     * 提交进行翻译
     */
    submittranslationhandleClose() {
      this.submittranslationdialogVisible = false;
    },
    /**
     * 知识文章操作刷新
     */
    refreshright() {
      this.$emit("refresh");
    },
    /**
     * 获取快速操作错误信息
     */
    warningerror() {
      let params = {
        articleId: "",
      };
      CommonObjApi.getErrorInfo(params).then((res) => {
        this.errortable = res.data;
        if (this.errortable.length > 0) {
          this.errorNum = this.errortable.length;
          // 显示知识库操作错误按钮
          this.btnList.foreach((item) => {
            if (item.action === "knowledgeError") {
              this.$set(item, "show", this.errorNum > 0);
            }
          });
        }
      });
      this.$refs.mychild.open();
    },
    /**
     * 处理知识库操作错误信息后判断查看错误信息按钮还展示与否
     */
    Errorchange(num) {
      this.errorNum = num;
      // 显示知识库操作错误按钮
      this.btnList.foreach((item) => {
        if (item.action === "knowledgeError") {
          this.$set(item, "show", this.errorNum > 0);
        }
      });
    },
    /**
     * 查看错误信息
     */
    narrow() {
      this.warningstate = false;
    },
    /**
     * 清空错误提示
     */
    close() {
      this.errortable = [];
      this.warningstate = false;
    },
    /**
     * 保存项目模板
     */
    saveteplant() {
      this.$refs.createplattform.validate((valid) => {
        this.$message(this.$i18n.t("label.project.template.one"));
        if (valid) {
          let params = {
            projectName: this.createpform.name,
            projectId: this.createpform.region,
            startDate: this.createpform.date,
          };
          CommonObjApi.templateSave(params).then((res) => {
            if (res.result) {
              // this.$message.success(this.$i18n.t('vue_label_notice_new_success'))
              this.$store.commit("setViewId", this.viewId);
              this.$router.push({
                path: `/commonObjects/project-detail/${res.data.id}/DETAIL`,
              });
              // this.$emit("createdtemplainit")
              this.createplant = false;
            } else {
              this.$message.error(this.$i18n.t("label.custombutton.newerror"));
            }
          });
        } else {
          this.$message.warning(
            this.$i18n.t("vue_label_commonobjects_detail_check_the_content")
          );
          return false;
        }
      });
    },
  },
  watch: {
    showButtons: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.init();
        });
      },
    },
    // 监听route变化，更新对象api和id
    $route(nval) {
      this.objectApi = nval.params.objectApi;
      this.objId = nval.params.objId;
    },
  },
  computed: {
    // 改变标签名称
    clientPoolCheckDialogTitle() {
      let val = "";
      if (this.objId === "lead") {
        //  如果是潜在客户
        val = this.$i18n.t("vue_label_normal_lead_sf");
      } else if (this.objId === "account") {
        //  如果是客户
        val = this.$i18n.t("vue_label_normal_account_sf");
      }
      return val;
    },
    // 在线个案二维码到期天数展示
    showQRCodeDays() {
      let obj = {}
      this.linkValidityPeriodOptions.forEach(item => {
        if (item.value === this.caseCodeInfo.linkValidityPeriodCopy) {
          obj.days = item.label
          if (item.value !== "permanent") {
            obj.date = this.getExpiryDate(item.value)
          }
        }
      })
      return obj;
    },
  },
};
</script>

<style lang="scss">
// 自定义气泡样式
.button-list-popper {
  margin-top: 4px !important;
  padding: 0 !important;
  border: 1px solid #e1e1e1 !important;

  .buttonListBox {
    max-height: 644px;
    overflow: auto;
    .sortList {
      border-bottom: 1px solid #e1e1e1;
      .sort-title {
        color: #080707;
        font-weight: bold;
        padding: 6px 12px;
        line-height: 16px;
        font-size: 14px;
      }
    }
    .sortList,
    .buttonList {
      padding: 4px 4px 0 4px;

      .item {
        font-size: 12px;
        color: #080707;
        margin-bottom: 4px;
        padding: 6px 12px;
        cursor: pointer;

        &:hover {
          background: #f5f5f5;
          border-radius: $--border-radius-base;
        }
      }

      .button-item {
        font-size: 14px;
      }
      .button-item-hover{
        background: #f5f5f5;
          border-radius: $--border-radius-base;
      }
    }

    .otherButtons {
      border-top: 1px solid #e1e1e1;
    }
  }
  .pdfButtonListBox{
    max-height: 400px;
  }
}

.more-button-popper {
  margin: 14px 0 0 0 !important;
  transform: translateX(-22px);
  .buttonList {
    margin: 4px 4px 0 4px;

    .item {
      font-size: 12px;
      color: #080707;
      margin-bottom: 4px;
      padding: 6px 12px;
      cursor: pointer;

      &:hover {
        background: #f5f5f5;
      }
    }

    .button-item {
      font-size: 14px;
    }
  }
}

.button-list-box {
  padding: 0 !important;

  > span {
    display: inline-block;
    padding: 9px;
  }

  .first-button {
    padding-right: 12px;
    margin-right: 10px;
    border-right: 0.5px solid #fff;
  }
}
.button-point:hover {
  background: #f3f7ff !important;
}
</style>
<style lang="scss" scoped>
.myButton {
  height: $--cloudcc-base-height;
  display: flex;
  align-items: center;
  border-radius: $--border-radius-base;
  background-color: $--color-primary;
  color: #ffffff;
  cursor: pointer;
  .one {
    padding: 9px 8px;
  }
  &:hover {
    background-color: $--color-primary-light-2;
  }
  &:focus {
    background-color: $--color-primary-light-2;
  }
  // 分割线
  ::v-deep .el-divider--vertical {
    margin: 0;
  }
  .iBox {
    padding: 10px 6px;
  }
}

.textItem {
  font-size: 12px;
  color: #666;
  cursor: pointer;
  line-height: 25px;
  padding: 0 12px;
  margin: 0 -12px;

  &:hover {
    background: #f3f2f2;
    color: #006dcc;
  }
}

.buttonWrapper {
  height: 32px;
  position: relative;

  .boxCard {
    width: 120px;
    position: absolute;
    right: 0;
    z-index: 10;

    ::v-deep .el-card__body {
      padding: 12px;
    }
  }
  .quickbook {
    height: 32px;
    padding: 0 12px;
    background: #ffffff;
    border: 1px solid #dedcda;
    box-shadow: none;
    font-size: 12px;
    cursor: pointer;
    color: #006dcc;
    margin: 0;
    border-radius: 0px;
  }
  .createBtn {
    height: 28px;
  }

  .top-btns {
    border: 1px solid #dedcda;
    border-radius: 3px;
    height: 30px;
    line-height: 27px;
    background: #ffff;
    margin-left: 10px;
    li:last-child {
      border-right: 0;
      span:first-child {
        // border-right: 1px solid #dedcda;
      }
    }
    li {
      font-size: 12px;
      cursor: pointer;
      border-right: 1px solid #dedcda;
      line-height: 29px;
      .btnName {
        padding: 0 12px;
        height: 100%;
        display: inline-block;
        cursor: pointer;
        color: #006dcc;
        &:hover {
          color: #006dcc;
        }
      }

      .dividingLine {
        font-size: 16px;
        color: #c8c6c6;
      }

      .icon {
        width: 29px;
        height: 27px;
        font-size: 18px;
        vertical-align: baseline;
        overflow: inherit;
        border-left: 1px solid #dedcda;
        padding: 3px 5px 0 5px;
      }
    }
  }

  .line-two-btn {
    .btn {
      padding: 0;
      height: 29px;
      border: 1px solid #c8c6c6;
    }

    img {
      width: 18px;
      height: 18px;
    }

    .group-btn {
      margin-top: -15px;
    }

    .in-left {
      margin-left: 10px;
    }

    .in-right {
      margin-right: 10px;
    }
  }
}
.tank {
  height: 480px;
  width: 90%;
  background: rgba(255, 255, 255, 1);
  z-index: 6;
  margin: 100px auto 0;
}
.title {
  height: 53px;
  line-height: 53px;
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid rgba(240, 240, 240, 1);
  color: rgba(109, 114, 120, 1);
  font-size: 18px;
}
.btnp {
  font-weight: bold;
  font-size: 17px;
}
.btnx {
  font-size: 16px;
}
.tankone {
  width: 30%;
  min-height: 359px;
  background: rgba(255, 255, 255, 1);
  margin: 110px auto;
}
.tanktwo {
  width: 90%;
  min-height: 694px;
  background: rgba(255, 255, 255, 1);
  margin: 110px auto;
}
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: rgba(51, 51, 51, 1);
}
::v-deep .el-input__icon {
  height: auto;
}
.al {
  margin: 0 24px;
  width: auto;
  overflow: auto;
  height: 259px;
  border: 1px solid rgba(190, 225, 255, 1);
}
.masstransfer {
  height: 18px;
  font-size: 18px;
  margin-top: 34px;
  margin-left: 35px;
  font-weight: bold;
  color: #666666;
  display: inline-block;
  line-height: 18px;
}
/*.al::-webkit-scrollbar-track {
  !*display: none;*!
  width: 5px;
}*/
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
::v-deep .el-input__icon {
  margin-top: -4px;
}
.mtransferli {
  list-style: inside;
  height: 34px;
  font-size: 12px;
  font-weight: 400;
  color: #0c0b0b;
  line-height: 18px;
}
ul {
  margin: 0;
}
.selectnum {
  height: 20px;
  font-size: 12px;
  margin-top: -20px;
  font-weight: 400;
  color: #666666;
  padding-bottom: 22px;
}
.checkboxs {
  margin-left: 6px;
  font-size: 12px;
  line-height: 28px;
}
.spancolor {
  color: #fa6400;
}
.spans {
  height: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  padding-top: 12px;
}
.remoteBtn {
  cursor: pointer;
}

.clientPoolDialogIsShow_Back_Discard-margin {
  margin: 5px 0;
}
.clientPoolDialogIsShow_Transfer-box-style {
  margin: 20px 0;
}

.case-code {
  padding: 34px 54px;
  .case-code-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 32px;
    .case-code-item-left {
      flex-shrink: 0;
      width: 120px;
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }
    .case-code-item-right {
      .case-code-item-right-show {
        display: flex;
        align-items: flex-start;
        .case-code-item-right-show-value {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
          margin-right: 50px;
          word-break: break-all;
        }
        .case-code-item-right-show-btn {
          cursor: pointer;
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #2d6cfc;
          line-height: 20px;
          flex-shrink: 0;
        }
      }
      .case-code-item-right-edit {
        display: flex;
        align-items: center;
        ::v-deep .el-select {
          padding-left: 0;
        }
        .case-code-item-right-edit-icon {
          width: 16px;
          height: 16px;
          font-size: 16px;
          margin-left: 8px;
          cursor: pointer;
        }
      }
      .case-code-item-right-qrcode {
        height: 148px;
        width: 148px;
        padding: 5px;
        border: 1px dashed #333333;
        margin-bottom: 12px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .case-code-item-right-download {
          cursor: pointer;
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #2d6cfc;
          line-height: 20px;
          flex-shrink: 0;
          text-align: center;
        }
    }
  }
  .case-code-item:last-child {
    margin-bottom: 0;
  }
}
</style>
