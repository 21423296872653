<template>
  <el-dialog
    :visible="dialogVisible"
    top="15%"
    width="40%"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <div slot="title" class="header-title">
      <svg class="icon" aria-hidden="true">
        <use
          :href="
            objid == 'lead'
              ? '#icon-applicationlead'
              : '#icon-kehugonghaichi'
          "
        ></use>
      </svg>
      <span>{{
        highSeaPoolStatus.marketseaState == "true" ? $t("c916") : $t("c915")
      }}</span>
    </div>
    <div class="content">
      <div class="describe">
        <p v-if="objid == 'account'">
          <!-- 使用公海池激发团队的潜能，提高客户跟进效率。将客户纳入公海池统一管理，公海池管理员批量进行分配，销售人员也可自行甄别线索，领取跟进。 -->
          {{$t('c917')}}
        </p>
        <p v-if="objid == 'account'">
          <!-- 若配置了自动回收规则，客户超时未成交，系统会自动将记录收回至公海池，提高客户的跟进效率。 -->
          {{$t('c918')}}
        </p>
        <p v-if="objid == 'lead'">
          <!-- 使用公海池激发团队的潜能，提高潜在客户转化率。将潜在客户纳入公海池统一管理，公海池管理员批量进行分配，销售人员也可自行甄别线索，领取跟进。 -->
          {{$t('c919')}}
        </p>
        <p v-if="objid == 'lead'">
          <!-- 若配置了自动回收规则，潜在客户超时未转换，系统会自动将记录回收至公海池，提高潜在客户的跟进效率。 -->
          {{$t('c920')}}
        </p>
      </div>
      <div class="img">
        <img src="@/assets/images/pool-bg.svg" alt="" class="icons" />
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">
        <!-- 取消 -->
        {{ $t("label.cancel") }}
      </el-button>
      <el-button @click="confirmOpen" type="primary" :loading="isBtnLoading">
        <!-- 开启 -->
        {{
          highSeaPoolStatus.marketseaState == "true"
            ? $t("label.tabpage.closez")
            : $t("front-epwechat-module-v1-open")
        }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
/**
 * 控制客户/潜在客户公海池开启关闭弹框
 */
import { setMarketseaSwitchState } from "./api.js";

export default {
  name: "",
  props: {
    // objid对象id
    objid:{
      type: String,
      default: ''
    },
    //控制弹框开关
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    //存放当前对象是否开启公海池状态
    highSeaPoolStatus: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isBtnLoading:false,
    };
  },
  methods: {
    /**
     * 确认开启/关闭公海池
     */
    async confirmOpen() {
      let option = {
        marketseaState:
          this.highSeaPoolStatus.marketseaState == "true" ? "false" : "true",
        objid: this.objid,
      };
      let result = await setMarketseaSwitchState(option);
      if (result.result) {
        this.$message.success(this.$i18n.t('label.operatesuccess'))
        let status =
          this.highSeaPoolStatus.marketseaState == "true" ? "false" : "true";
        if (status == "true") {
          //开启公海池跳转对应设置页面
          if (this.objid == "lead") {
            window.open(
              `${this.$cookies.get(
                "domainName"
              )}/marketSeaSetup.action?m=groupList&type=lead&setupId=GroupingSteup1`
            );
          } else {
            window.open(
              `${this.$cookies.get(
                "domainName"
              )}/marketSeaSetup.action?m=groupList&type=account&setupId=GroupingSteup1`
            );
          }
        }
        this.$emit("closeDialog", status);
        //关闭公海池后刷新页面
        setTimeout(() => {
          if(status == 'false'){
            this.$router.go(0)
          }
        }, 500);
      }
    },
    /**
     * 关闭弹窗触发方法
     */
    handleClose() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style lang='scss' scoped>
.icon {
  width: 34px;
  height: 34px;
}
.icons {
  width: 180px;
  height: 180px;
}
.header-title {
  display: flex;
  align-items: center;
  span {
    font-size: 16px;
    font-weight: bold;
    color: #080707;
    margin-left: 10px;
  }
}
.content {
  display: flex;
  align-items: flex-start;
  .describe {
    margin-right: 20px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    margin-bottom: 15px;
  }
}
::v-deep .el-dialog__footer {
  text-align: center;
  border: none;
}
::v-deep .el-button {
  padding: 10px 25px;
}
::v-deep .el-dialog__header {
  border-bottom: none;
  padding: 10px 20px 10px;
  background: #f9f9f9;
}
</style>