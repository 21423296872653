<template>
  <div class="buttonWrapper">
    <!-- 第一类按钮：新建类按钮 -->
    <div v-if="addButtons.length > 0" class="pull-left addButtons">
      <!-- 大于一个时显示的按钮 -->
      <div class="myButton" v-show="addButtons.length > 1">
        <!-- 新建 -->
        <div
          class="one"
          :devid="addButtons[0].id"
          :style="{ cursor: addIng ? 'not-allowed' : 'pointer' }"
          @click="handleClick(addButtons[0], addButtons[0].action)"
        >
          {{ addButtons[0].label }}
        </div>
        <!-- 分割线 -->
        <el-divider direction="vertical"></el-divider>
        <!-- 更多按钮 -->
        <el-popover
          placement="bottom"
          trigger="click"
          :visible-arrow="false"
          ref="moreButtonPopper"
          popper-class="button-list-popper more-button-popper"
        >
          <span slot="reference">
            <i class="el-icon-caret-bottom iBox"></i>
          </span>
          <ul class="buttonList">
            <li
              v-for="(item, idx) in addButtons"
              v-show="idx > 0"
              :key="item.icon"
              :devid="item.id"
              class="item button-item"
              @click="handleClick(item, item.action, 'create')"
            >
              {{ item.label }}
            </li>
          </ul>
        </el-popover>
      </div>
      <!-- 只有一个按钮展示的效果 -->
      <el-button
        v-show="addButtons.length <= 1"
        size="small"
        :disabled="addIng"
        type="primary"
        :devid="addButtons[0].id"
        class="filterBtn"
        @click="handleClick(addButtons[0], addButtons[0].action)"
      >
        {{ addButtons[0].label }}
      </el-button>
    </div>
    <!--批量新增dialog-->
    <el-dialog
      :visible.sync="batchAddDialog"
      top="10%"
      :title="batchAddDialogTitle"
      width="90%"
      :before-close="closeBatchAddDialog"
    >
      <batchAdd
        ref="batchAdd"
        :prefix="prefix"
        @closeBatchAddDialog="closeBatchAddDialog"
        @refresh="refresh"
        :pageFlag="pageFlag"
        :currentClientPoolSelectObj="currentClientPoolSelectObj"
        @getBatchLoading="getBatchLoading"
      />
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="closeBatchAddDialog">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          size="mini"
          type="primary"
          @click="saveBatchAdd"
          :loading="isBatchLoading"
        >
          <!-- 确定 -->
          {{ $t("label.confirm") }}
        </el-button>
      </div>
    </el-dialog>
    <!-- 项目模板新建dialog -->
    <el-dialog
      :title="$t('front-project-module-v1-template-newcreate')"
      :visible.sync="createplant"
      width="30%"
      :before-close="createplanthandleClose"
    >
      <el-form
        ref="createplattform"
        :model="createpform"
        :rules="createmplatrules"
        label-width="auto"
        size="small"
      >
        <el-form-item
          :label="$t('front-project-module-v1-select-projecttemplate')"
          prop="region"
        >
          <el-select
            v-model="createpform.region"
            :placeholder="$t('front-project-module-v1-select-projecttemplate')"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in createpformoption"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('label.projectManagement.entryname')"
          prop="name"
        >
          <el-input
            v-model="createpform.name"
            :placeholder="$t('label.projectManagement.Pleaseenterprojectname')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('label.chatter.startDate')" prop="date">
          <el-date-picker
            type="date"
            :placeholder="$t('label.chatter.startDate')"
            v-model="createpform.date"
            value-format="yyyy-MM-dd"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createplant = false">{{
          $t("label.emailtocloudcc.button.back")
        }}</el-button>
        <el-button type="primary" @click="saveteplant">{{
          $t("component.button.ok")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/**
 * 新版视图页使用的button按钮
 * 提示：知识文章目前不使用这个文件
 */
import * as CommonObjApi from "./api.js";
import { Loading } from "element-ui";
import DialogMixin from "@/mixin/Dialog.js";
import debounce from "lodash.debounce";

export default {
  mixins: [DialogMixin],
  components: {
    batchAdd: () => import("@/components/ButtonWrapper/batchAdd"),
  },
  props: {
    // 被选中的列表数据
    selectedList: {
      type: Array,
      default: () => [],
    },
    // 显示的按钮：目前拆分为两大类
    showButtons: {
      type: Array,
      default: () => [],
    },
    // 视图id，用于区分同一个类型数据的不同视图
    viewId: {
      type: String,
      default: "",
    },
    // 当前对象名称，目前不是多语言，后端翻译好了
    tabName: {
      type: String,
      default: "",
    },
    // 对象前缀
    prefix: {
      type: String,
      default: "",
    },
    // 公海池页面标记，目前看是公海池使用的，用于标记对象的
    pageFlag: {
      type: String,
      default: "",
    },
    // 当前的公海池对象
    currentClientPoolSelectObj: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      recordTypeList: [],
      index: "",
      // 右边两类按钮：第一类，常用的重要按钮，如：新建类按钮：新建、批量新增、从模板新建
      addButtons: [],
      // 批量添加dialog是否显示
      batchAddDialog: false,
      // 控制批量新增按钮loading状态
      isBatchLoading: false,
      // 批量新增dialog显示的title
      batchAddDialogTitle: this.$i18n.t("label.object.batchadd", {
        objName: this.tabName,
      }),
      // 控制转移所有人dialog是否显示
      transferClientOwnerDialog: false,

      // 标记当前选择数据的数量
      selectNum: 0,
      // 当前对象Id
      objId: this.$route.params.objId,
      // 当前对象api标识
      objectApi: this.$route.params.objectApi,
      // 选择数据的id集合
      ids: "",
      // prefix: this.$route.params.prefix, // 对象前缀
      //wj
      checkedList: [],

      //公海池右边操作按钮对话框【恢复，回收，领取】
      clientPoolDialogIsShow_Recover_Recycle_Get: false,
      // 操作指令对象
      clientPoolOprationObject: {
        oprationDirctive: "", //操作指令,当前对话框所附带的事件类型（每一个 action 按钮的事件名称 ）
        oprationDirctiveName: "", //操作的指令名称
      },
      // ele加载框挂载的DOM
      loadingOption: {
        target: "#table-panel",
      },
      // 创建项目模板
      createplant: false,
      // 创建项目模板弹框数据
      createpform: {
        date: "",
        name: "",
        region: "",
      },
      // 从模板新建 弹框的验证规则
      createmplatrules: {
        date: [
          {
            required: true,
            message: this.$i18n.t(
              "front-project-module-v1-plase-input-start-time"
            ),
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: this.$i18n.t(
              "front-project-module-v1-plase-input-project-name"
            ),
            trigger: "blur",
          },
        ],
        region: [
          {
            required: true,
            message: this.$i18n.t("front-project-module-v1-plase-selectemplat"),
            trigger: "blur",
          },
        ],
      },
      // 从模板新建 弹框的模板列表
      createpformoption: [],
      addIng: false, //判断是否正在新建中
    };
  },
  mounted() {
    //更改新建按钮状态
    this.$Bus.$off("deliver-addbutton-state");
    this.$Bus.$on("deliver-addbutton-state", this.deliverAddButtonStateCallback);
  },
  beforeDestroy(){
    this.$Bus.$off("deliver-addbutton-state", this.deliverAddButtonStateCallback);
  },
  methods: {
    deliverAddButtonStateCallback(){
      this.addIng = false;
    },
    /**
     * 初始化
     */
    init() {
      this.addButtons = [];
      this.transferClientOwnerDialog = false;
      this.showButtons.forEach((btn) => {
        // 新建类按钮：新建、批量新增、从模板新建
        if (
          btn.name === "New" ||
          btn.name === "Batch Added" ||
          btn.name === "New from Template"
        ) {
          this.addButtons.push({
            label: btn.label,
            action:
              btn.name === "New"
                ? "add"
                : btn.name === "Batch Added"
                ? "batchAdding"
                : "NewfromTemplate",
            name: btn.name,
            id: btn.id,
            function_code: btn.function_code || btn.functionCode || "",
          });
        }
      });
    },
    /**
     * 刷新页面
     */
    refresh() {
      this.$emit("refresh");
    },
    /**
     * 处理按钮点击事件
     * @param {Object} item 对象
     * @param {Object} action 动作
     * @param {Object} type 按钮类型
     */
    handleClick: debounce(
      function (item, action, type) {
        // 如果标准按钮如果设置了自定义事件，执行自定义事件
        if (item.function_code !== "null" && item.function_code !== "" &&
            item.function_code !== undefined &&
            item.function_code !== null &&
           item.action !== "CustomButton") {
          let funText = item.function_code
            ? item.function_code
            : item.functionCode;
          let newFun = Function(funText);
          newFun();
          return;
        }
        if (action == "add" && !this.addIng) {
          this.addIng = true;
        }
        this.checkedList = JSON.parse(JSON.stringify(this.selectedList));
        let keys = [];
        for (let index in this.recordTypeList) {
          if (Object.prototype.hasOwnProperty.call(this.recordTypeList, index))
            keys.push(index);
          // if (this.recordTypeList.hasOwnProperty(index)) keys.push(index);
        }
        for (let i = 0; i < keys.length; i++) {
          this.index = i + 1;
        }
        if (action == "batchAdding") {
          // 批量新增
          this.batchAddDialog = true;
          this.$nextTick(() => {
            this.$refs.batchAdd && this.$refs.batchAdd.init();
            this.$refs.batchAdd && this.$refs.batchAdd.getRecordType();
          });
          this.batchAddDialogTitle = `${this.$i18n.t("label.object.batchadd", {
            objName: this.tabName,
          })}`;
        } else if (
          action === "checkDuplicationAndMerge" ||
          action === "collisionAnalysis"
        ) {
          // 查重合并
          this.$router.push({
            path: "/commonObjects/cnki",
            query: {
              prefix: this.prefix,
              viewId: this.viewId,
              objId: this.$route.params.objId,
              objApi: this.objectApi,
            },
          });
        } else if (action == "NewfromTemplate") {
          this.createplantevevt();

          // 回收公海池
          // 公海池勾选的客户数量检测
          if (!this.checkCheckedCount()) {
            return;
          }
          this.clientPoolDialogIsShow_Recover_Recycle_Get = true;
          this.clientPoolOprationObject.oprationDirctive = action;
          this.clientPoolOprationObject.oprationDirctiveName = this.$i18n.t(
            "vue_label_pool_recycle"
          ); //回收
        } else if (item.action === "CustomButton") {
          // lightning版本JavaScript类型自定义按钮
          if (item.event === "lightning") {
            this.selectNum = this.checkedList.length;
            let checkedList = [];
            for (let i = 0; i < this.checkedList.length; i++) {
              checkedList.push(this.checkedList[i].id);
            }
            this.ids = checkedList.join(",");

            // 自定义按钮
            let param = JSON.parse(item.functionCode);
            param.id = this.ids;
            param.token = this.$CCDK.CCToken.getToken();
            if (param.maxRecord && this.selectNum > param.maxRecord) {
              this.$message.warning(
                `最多只能选中${param.maxRecord}条数据进行操作`
              );
            } else {
              if (param.params && param.params.length > 0) {
                // 替换id
                param.params.forEach((item) => {
                  if (item.argValue === "{!ids}") {
                    if (this.selectNum < 1) {
                      this.$message.warning(
                        this.$i18n.t("lable.opppdt.selPdt.leastone")
                      );
                    }
                    item.argValue = this.ids;
                  }
                  if (item.argValue === "{!viewId}") {
                    item.argValue = this.viewId;
                  }
                  if (item.token === "{!binding}") {
                    item.token = this.$CCDK.CCToken.getToken();
                  }
                });
              }
              let loadingInstance = Loading.service(this.loadingOption);
              CommonObjApi.openCall(param).then((res) => {
                loadingInstance.close();
                if (res.data && res.data.action) {
                  // 刷新页面
                  if (res.data.action === "refresh") {
                    this.refresh();
                  } else if (res.data.action === "redirect") {
                    // 重定向 需判断当前窗口打开还是新窗口打开
                    if (res.data.mode === "new") {
                      window.open(res.data.url);
                    } else if (res.data.mode === "now") {
                      window.location.href = res.data.url;
                    }
                  } else if (res.data.action === "alert") {
                    // 不同类型的消息提示   wj
                    this.$message({
                      message: res.data.message,
                      type: res.data.messageType || "info",
                    });
                  }
                  //先弹窗，在当前页面刷新
                  else if (res.data.action === "alert_refresh") {
                    // 不同类型的消息提示   wj
                    this.$message({
                      message: res.data.message,
                      type: res.data.messageType || "info",
                    });
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  }
                  //先弹窗,在新标签页跳转
                  else if (res.data.action === "alert_redirect") {
                    // 不同类型的消息提示   wj
                    this.$message({
                      message: res.data.message,
                      type: res.data.messageType || "info",
                    });
                    setTimeout(() => {
                      // 重定向 需判断当前窗口打开还是新窗口打开
                      if (res.data.mode === "new") {
                        window.open(res.data.url);
                      } else if (res.data.mode === "now") {
                        if (`/${window.location.hash}` === res.data.url) {
                          window.location.reload();
                        } else {
                          window.location.href = res.data.url;
                        }
                      }
                    }, 1000);
                  }
                }
              });
            }
          }
          // 自定义按钮lightning-script
          if (item.event === "lightning-script") {
            let funText = item.function_code
              ? item.function_code
              : item.functionCode;
            let newFun = Function(funText);
            newFun();
          }
          if (item.event === "lightning-url") {
            let searchUrl = item.functionCode.split("?")[1];
            let paramsObj = this.$qs.parse(searchUrl); // 获取从？开始的url部分
            if (paramsObj.isNeedRecordId && paramsObj.isNeedRecordId == "1") {
              // 需要选记录
              if (this.checkedList.length == 0) {
                this.$message.warning(
                  this.$i18n.t("lable.opppdt.selPdt.leastone")
                );
                return;
              }
            }
            let ids = [];
            let idsKey = new Date().getTime();
            for (let i = 0; i < this.checkedList.length; i++) {
              ids.push(this.checkedList[i].id);
            }
            ids = ids.join(",");
            localStorage.setItem(idsKey, ids);
            // 当前窗口打开
            if (item.behavior === "self") {
              if (item.functionCode.substring(0, 7) == "cloudcc") {
                // 新版自定义页面
                let searchUrl = item.functionCode.split("?")[1]; // 获取从？开始的url部分
                let paramsObj = this.$qs.parse(searchUrl); // 将参数转为对象
                let page = paramsObj.page;
                // 判断是否显示菜单栏
                let routeData = "";
                if (item.menubar == "show") {
                  // 展示菜单栏
                  routeData = this.$router.resolve({
                    path: "/injectionComponent",
                    query: {
                      page: page,
                      viewId: this.viewId,
                      idsKey: idsKey,
                      button: "Home",
                    },
                  });
                } else {
                  // 不展示菜单栏
                  routeData = this.$router.resolve({
                    path: "/injectionComponent1",
                    query: {
                      page: page,
                      viewId: this.viewId,
                      idsKey: idsKey,
                    },
                  });
                }
                if (paramsObj.target) {
                  if (paramsObj.target == "dialog") {
                    let obj = {
                      title: paramsObj.dialogTitle || "详情",
                      width: paramsObj.dialogWidth || "50%",
                      height: paramsObj.dialogHeight || "",
                      pageApi: page,
                      idsKey: idsKey,
                    };
                    this.$bus.$emit("openDialog", obj);
                  } else if (paramsObj.target == "window") {
                    window.open(
                      routeData.href,
                      "newWindow",
                      "width=800,height=600,left=400,top=100,menubar=0,scrollbars=1, resizable=1,status=1,titlebar=0,toolbar=0,location=1"
                    );
                  }
                } else {
                  window.open(routeData.href, "_self");
                }
              } else {
                if (item.functionCode.indexOf("?") == -1) {
                  // 说明链接里面没有用？号拼接参数
                  window.location.href = `${item.functionCode}?viewId=${this.viewId}&idsKey=${idsKey}`;
                } else {
                  // 说明链接里面用？号拼接参数了
                  window.location.href = `${item.functionCode}&viewId=${this.viewId}&idsKey=${idsKey}`;
                }
              }
            } else {
              if (item.functionCode.substring(0, 7) == "cloudcc") {
                let searchUrl = item.functionCode.split("?")[1]; // 获取从？开始的url部分
                let paramsObj = this.$qs.parse(searchUrl); // 将参数转为对象
                let page = paramsObj.page;
                // 判断是否显示菜单栏
                let routeData = "";
                if (item.menubar == "show") {
                  // 展示菜单栏
                  routeData = this.$router.resolve({
                    path: "/injectionComponent",
                    query: {
                      page: page,
                      viewId: this.viewId,
                      idsKey: idsKey,
                      button: "Home",
                    },
                  });
                } else {
                  // 不展示菜单栏
                  routeData = this.$router.resolve({
                    path: "/injectionComponent1",
                    query: {
                      page: page,
                      viewId: this.viewId,
                      idsKey: idsKey,
                    },
                  });
                }
                window.open(routeData.href, "_blank");
              } else {
                if (item.functionCode.indexOf("?") == -1) {
                  // 说明链接里面没有用？号拼接参数
                  window.open(
                    `${item.functionCode}?viewId=${this.viewId}&idsKey=${idsKey}`
                  );
                } else {
                  // 说明链接里面用？号拼接参数了
                  window.open(
                    `${item.functionCode}&viewId=${this.viewId}&idsKey=${idsKey}`
                  );
                }
              }
            }
          }
          if (item.event === "JavaScript" || item.event === "URL") {
            // JavaScript和URL类型提示切换系统
            this.$message.warning(this.$i18n.t("lable.Please.switch"));
          }
        }
        if (this.action == "add") {
          //新建时判断是否处于已点击状态
          if (this.addIng) {
            return;
          } else {
            this.$emit(action);
          }
        } else {
          this.$emit(action);
        }
        //公海池按钮点击
        this.$emit("highSpoolAction", action);

        // 关闭对应按钮列表气泡
        if (type === "create") {
          this.$refs.moreButtonPopper.doClose();
        }
      },
      3000,
      { leading: true, trailing: false }
    ),
    /**
     * 保存批量新增
     */
    saveBatchAdd() {
      this.$refs.batchAdd.savedata();
    },
    /**
     * 关闭批量新增
     */
    closeBatchAddDialog() {
      this.batchAddDialog = false;
    },
    /**
     * 获取批量新增保存loading状态
     * @param {Boolean} n 按钮对象
     */
    getBatchLoading(n) {
      this.isBatchLoading = n;
    },
    /**
     * 创建项目模板 关闭弹框
     */
    createplanthandleClose() {
      this.createplant = false;
    },
    /**
     * 项目模板新建
     */
    createplantevevt() {
      CommonObjApi.queryProjectTemplateList().then((res) => {
        if (res.result) {
          if (res.data && res.data.length > 0) {
            this.createplant = true;
            this.createpformoption = res.data;
            let date = new Date();
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            let nowDate =
              year +
              "-" +
              (month < 10 ? "0" + month : month) +
              "-" +
              (day < 10 ? "0" + day : day);
            this.createpform.date = nowDate;
            this.createpform.region = this.createpformoption[0].id;
            this.createpform.name = "";
            this.$refs.createplattform.resetFields();
          } else {
            this.$message.warning(
              this.$i18n.t("front-project-module-v1-no-selectemplat")
            );
          }
        }
      });
    },
    /**
     * 保存项目模板
     */
    saveteplant() {
      this.$refs.createplattform.validate((valid) => {
        this.$message(this.$i18n.t("label.project.template.one"));
        if (valid) {
          let params = {
            projectName: this.createpform.name,
            projectId: this.createpform.region,
            startDate: this.createpform.date,
          };
          CommonObjApi.templateSave(params).then((res) => {
            if (res.result) {
              // this.$message.success(this.$i18n.t('vue_label_notice_new_success'))
              this.$store.commit("setViewId", this.viewId);
              this.$router.push({
                path: `/commonObjects/project-detail/${res.data.id}/DETAIL`,
              });
              // this.$emit("createdtemplainit")
              this.createplant = false;
            } else {
              this.$message.error(this.$i18n.t("label.custombutton.newerror"));
            }
          });
        } else {
          this.$message.warning(
            this.$i18n.t("vue_label_commonobjects_detail_check_the_content")
          );
          return false;
        }
      });
    },
  },
  watch: {
    showButtons: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.init();
        });
      },
    },
    // 监听route变化，更新对象api和id
    $route(nval) {
      this.objectApi = nval.params.objectApi;
      this.objId = nval.params.objId;
    },
  },
};
</script>

<style lang="scss">
// 自定义气泡样式
.button-list-popper {
  margin-top: 4px !important;
  padding: 0 !important;
  border: 1px solid #e1e1e1 !important;
}

.more-button-popper {
  margin: 14px 0 0 0 !important;
  transform: translateX(-22px);
  .buttonList {
    margin: 4px 4px 0 4px;

    .item {
      font-size: 12px;
      color: #080707;
      margin-bottom: 4px;
      padding: 6px 12px;
      cursor: pointer;

      &:hover {
        background: #f5f5f5;
      }
    }

    .button-item {
      font-size: 14px;
    }
  }
}
</style>
<style lang="scss" scoped>
.myButton {
  height: $--cloudcc-base-height;
  display: flex;
  align-items: center;
  border-radius: $--border-radius-base;
  background-color: $--color-primary;
  color: #ffffff;
  cursor: pointer;
  .one {
    padding: 9px 8px;
  }
  &:hover {
    background-color: $--color-primary-light-2;
  }
  &:focus {
    background-color: $--color-primary-light-2;
  }
  // 分割线
  ::v-deep .el-divider--vertical {
    margin: 0;
  }
  .iBox {
    padding: 10px 6px;
  }
}

.textItem {
  font-size: 12px;
  color: #666;
  cursor: pointer;
  line-height: 25px;
  padding: 0 12px;
  margin: 0 -12px;

  &:hover {
    background: #f3f2f2;
    color: #006dcc;
  }
}

.buttonWrapper {
  height: 32px;
  position: relative;

  .boxCard {
    width: 120px;
    position: absolute;
    right: 0;
    z-index: 10;

    ::v-deep .el-card__body {
      padding: 12px;
    }
  }
  .quickbook {
    height: 32px;
    padding: 0 12px;
    background: #ffffff;
    border: 1px solid #dedcda;
    box-shadow: none;
    font-size: 12px;
    cursor: pointer;
    color: #006dcc;
    margin: 0;
    border-radius: 0px;
  }
  .createBtn {
    height: 28px;
  }

  .top-btns {
    border: 1px solid #dedcda;
    border-radius: 3px;
    height: 30px;
    line-height: 27px;
    background: #ffff;
    margin-left: 10px;
    li:last-child {
      border-right: 0;
    }
    li {
      font-size: 12px;
      cursor: pointer;
      border-right: 1px solid #dedcda;
      line-height: 29px;
      .btnName {
        padding: 0 12px;
        height: 100%;
        display: inline-block;
        cursor: pointer;
        color: #006dcc;
        &:hover {
          color: #006dcc;
        }
      }

      .dividingLine {
        font-size: 16px;
        color: #c8c6c6;
      }

      .icon {
        width: 29px;
        height: 27px;
        font-size: 18px;
        vertical-align: baseline;
        overflow: inherit;
        border-left: 1px solid #dedcda;
        padding: 3px 5px 0 5px;
      }
    }
  }

  .line-two-btn {
    .btn {
      padding: 0;
      height: 29px;
      border: 1px solid #c8c6c6;
    }

    img {
      width: 18px;
      height: 18px;
    }

    .group-btn {
      margin-top: -15px;
    }

    .in-left {
      margin-left: 10px;
    }

    .in-right {
      margin-right: 10px;
    }
  }
}
.tank {
  height: 480px;
  width: 90%;
  background: rgba(255, 255, 255, 1);
  z-index: 6;
  margin: 100px auto 0;
}
.title {
  height: 53px;
  line-height: 53px;
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid rgba(240, 240, 240, 1);
  color: rgba(109, 114, 120, 1);
  font-size: 18px;
}
.btnp {
  font-weight: bold;
  font-size: 17px;
}
.btnx {
  font-size: 16px;
}
.tankone {
  width: 30%;
  min-height: 359px;
  background: rgba(255, 255, 255, 1);
  margin: 110px auto;
}
.tanktwo {
  width: 90%;
  min-height: 694px;
  background: rgba(255, 255, 255, 1);
  margin: 110px auto;
}

::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: rgba(51, 51, 51, 1);
}
::v-deep .el-input__icon {
  height: auto;
}
.al {
  margin: 0 24px;
  width: auto;
  overflow: auto;
  height: 259px;
  border: 1px solid rgba(190, 225, 255, 1);
}
::v-deep .el-input__icon {
  margin-top: -4px;
}

ul {
  margin: 0;
}
.selectnum {
  height: 20px;
  font-size: 12px;
  margin-top: -20px;
  font-weight: 400;
  color: #666666;
  padding-bottom: 22px;
}
.checkboxs {
  margin-left: 6px;
  font-size: 12px;
  line-height: 28px;
}
.spancolor {
  color: #fa6400;
}
.spans {
  height: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  padding-top: 12px;
}
.remoteBtn {
  cursor: pointer;
}

.clientPoolDialogIsShow_Back_Discard-margin {
  margin: 5px 0;
}
.clientPoolDialogIsShow_Transfer-box-style {
  margin: 20px 0;
}
</style>
