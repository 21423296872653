<template>
  <div class="buttonWrapper">
    <!-- 选中数据条数统计 -->
    <el-tag
      closable
      :disable-transitions="false"
      effect="plain"
      class="selectedData"
      @close="cancelBatchOperation"
    >
      {{
        $t("vue_label_commonobjects_view_selected", {
          checkedLength: selectedList.length,
        }).split(",")[0]
      }}
    </el-tag>
  </div>
</template>

<script>
/**
 * 新版视图页使用的批量操作快捷键
 */

export default {
  props: {
    // 被选中的列表数据
    selectedList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    /**
     * 取消批量操作
     */
    cancelBatchOperation() {
      this.$emit("cancelBatchOperation");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .selectedData {
  border: 1px solid #e1e1e1;
  border-radius: 19px;
  color: #080707;
  margin-right: 8px;
  background-color: $--background-color-base;

  .el-tag__close {
    color: #888 !important;

    &:hover {
      color: #888 !important;
      background: #fff;
    }
  }
}

</style>
