import axios from '@/config/httpConfig'
// 获取视图排序
export function getSortView(data) {
    return axios.post('/view/getSortView', data)
}
// 保存视图排序
export function saveSortView(data) {
    return axios.post('/view/saveSortView', data)
}
// 获取默认视图
export function SetViewDefault(data) {
    return axios.post('/view/list/setViewDefault', data)
}
// 删除视图
export function DeleteGroup(data) {
    return axios.post('/view/deleteView', data)
}
// 获取用户界面设置
export function getUserSetup(data) {
    return axios.get('/user/getUserSetup', data)
}
// 获取视图列表
export function GetViewList(data) {
    return axios.post('/view/list/getViewList', data)
}
// 获取视图详细信息
export function GetViewInfo(data) {
    return axios.post('/view/getViewInfo', data)
}