// 应用程序api
import axios from '@/config/httpConfig'

/**
 * 请求获取用户信息
 * @param {data} 请求参数 
 * @param {enforce} 是否强制请求最新用户信息 
 * @returns 用户信息
 */
export function getUserInfo(data, enforce = false) {
  if (enforce || localStorage.getItem("userInformation") === null) {
    return axios.get('/user/getUserInfo', data)
  } else {
    return new Promise(function (resolve) {
      resolve(JSON.parse(localStorage.getItem("userInformation"))); // 回调成功返回
    })
  }
}

// 获取应用程序列表
export function getApplicationList(data) {
  return axios.post('/application/getApplicationList', data)
}
// 查询公海池开关设置
export function getMarketseaSwitchState(data) {
  return axios.post('marketseaSwitch/getMarketseaSwitchState', data)
}
// 公海池开关设置
export function setMarketseaSwitchState(data) {
  return axios.post('marketseaSwitch/setMarketseaSwitchState', data)
}
// 获取应用程序logo
export function getAppLogo(data) {
  return axios.get(`/application/getAppLogo?id=${data.id}&bgcolor=${data.bgcolor}`, { responseType: 'blob' })
}

// 获取应用程序选项卡
export function getApplicationTab(data) {
  return axios.post('/application/getApplicationTab', data)
}

//应用程序选项卡搜索
export function seachApplicationTab(data) {
  return axios.post('/application/showAllTabs', data)
}

//判断快速新建权限
export function getNewAuth(data) {
  return axios.post('/objectdetail/getObjectPermission', data)
}

//获取个人菜单设置（自定义菜单)
export function getmymenusetting(data) {
  return axios.post('application/getMyTabSetting', data)
}

//保存个人菜单设置（自定义菜单）
export function savemymenusetting(data) {
  return axios.post('application/saveMyTabSetting', data)
}

// 立即订购剩余时间
export function getUserVersionInfo(data) {
  return axios.post('/user/getUserVersionInfo', data)
}

//获取伙伴云用户菜单
export function getPartnerTab() {
  return axios.post('partner/getTabList')
}
