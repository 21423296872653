<template>
  <!-- 新建、编辑视图组件 -->
  <div>
    <el-dialog
      :title="title"
      :visible.sync="Visible"
      class="newBox"
      top="5%"
      center
      :close-on-click-modal="false"
      :before-close="closeViewDialogEmit"
    >
      <div style="text-align: center">
        <i
          v-if="loading"
          class="el-icon-loading"
          style="font-size: 20px; margin-top: 200px"
        >
        </i>
      </div>
      <el-row v-if="!loading" v-loading="loading">
        <el-form
          :rules="rules"
          ref="objForms"
          :model="objForm"
          :label-width="formLabelWidth"
          label-position="left"
          size="small"
        >
          <!-- <el-form-item label="名称"  -->
          <el-form-item
            :label="$t('label.name')"
            prop="label"
            class="label_name"
          >
            <el-input
              v-model="objForm.label"
              autocomplete="off"
              class="input_name"
            ></el-input>
            <el-checkbox v-model="isUsedForMobileCopy" @input="changeIsUsedForMobile" class="mobileFilter">
              <!-- 应用于移动端 -->
              {{ $t("label.tab.profile.ismobile") }}
            </el-checkbox>
          </el-form-item>
          
          <!--  <el-form-item label="筛选条件" -->
          <el-col :span="24">
            <el-form-item
              :label="$t('label.filterconditions')"
              style="margin-left: 12px"
            >
              <span class="setSize" style="float: left">
                <el-popover
                  width="540"
                  trigger="hover"
                  placement="bottom-start"
                >
                  <div>
                    <div style="word-break: break-word">
                      <!-- 记录类型字段，选项列表字段和选项列表（多选）字段，建议最多输入10个关键字，用英文逗号隔开实现或关系，如：“北京,上海,广州”。 -->
                      1、{{ $t("label_tabpage_fieldoptions") }} <br />2、
                      <!-- 日期字段等于时可以使用“昨天”，“今天”，“明天”，“上个月”，“本月”，“下个月”，“上星期”，“本星期”，“下星期”或 “YYYY-MM-DD、MM/DD/YYYY”格式。 -->
                      {{ $t("label.view.desc") }}
                      <br />3、
                      <!-- 当不启用高级筛选时，各个筛选条件之间是“与”的关系，-->
                      {{ $t("label.hight.tip") }}
                      <span v-html="$t('vue_label_filternotice1')"></span>
                      {{ $t("label.field.for.example") }}
                    </div>
                  </div>
                  <svg class="icon tipsBox" slot="reference" aria-hidden="true">
                    <use href="#icon-explain"></use>
                  </svg>
                </el-popover>
                <!-- 所有人 -->
                {{ $t("label.owner") }}
              </span>
              <el-radio-group
                v-model="objForm.screeRadio"
                @change="queueBtn"
                style="
                  display: flex;
                  float: left;
                  line-height: 36px;
                  height: 36px;
                  align-items: center;
                "
              >
                <el-radio label="0">
                  <!-- 全部 -->
                  {{ $t("label.quickbooks.view.all") }}
                </el-radio>
                <el-radio label="1">
                  <!-- 我的 -->
                  {{ $t("label.my") }}
                </el-radio>
                <el-radio label="2" v-if="isQueue">
                  {{ $t("label_tabpage_queue") }}
                  <!-- 队列 -->
                </el-radio>
                <el-select
                  v-model="queryQueueValueCopy"
                  @change="changeQueryQueueValue"
                  :placeholder="$t('label.report.select.condition')"
                  v-if="isQueueSelect"
                  style="margin-top: -8px; margin-left: 7px; width: 170px"
                >
                  <el-option
                    v-for="item in queryQueueList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <div
              class="screen_s"
              v-for="(ev, index) in objForm.list"
              :key="ev.idKey"
            >
              <el-form-item>
                <span style="width: 21px">{{ index + 1 }}</span>
                <!-- placeholder="选择字段" -->
                <el-select
                  v-model="ev.fieldId"
                  :placeholder="$t('label.selectfield')"
                  class="select_one"
                  filterable
                  clearable
                  @change="selectChange($event, index)"
                  @clear="clearField(ev)"
                >
                  <el-option
                    v-for="item in optionst_one"
                    :key="item.id"
                    :label="item.labelName"
                    :value="item.id"
                  ></el-option>
                </el-select>
                <el-select
                  v-model="ev.op"
                  :placeholder="$t('label.operator')"
                  class="select_two"
                  filterable
                >
                  <el-option
                    v-for="item in ev.optionst_two"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
                <EditableCell
                  ref="editableCell"
                  :inputType="ev.inputType"
                  :options="selecArr"
                  :value="ev.val"
                  :origin-type="filter"
                  :prefix="prefix"
                  :fieldId="ev.fieldId"
                  :objid="ev.objid"
                  :min="0"
                  :max="1000000000000000000"
                  @remoteSearch="remoteSearch(ev, index)"
                  @selectes="ev.fieldId === '' ? '' : selectes(ev)"
                  @remoteMethods="remoteMethods"
                  style="width: 38%; position: relative"
                >
                </EditableCell>
                <div style="width: 100px">
                  <!-- “-”一直展示，但是为第一个的时候作用是清空当前内容 -->
                  <span class="btn_s" @click="dels(index)">-</span>
                  <span
                    class="btn_s"
                    @click="adds"
                    v-if="objForm.list.length - 1 === index"
                    >+</span
                  >
                </div>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <el-form-item style="margin: 6px 0 20px 13px; display: flex">
              <div style="float: left">
                <!-- 高级筛选 -->
                <el-checkbox v-model="filterChecked" style="margin-right: 15px">{{
                  $t("advancedconditions")
                }}</el-checkbox>
              </div>
              <textarea
                name="text"
                v-model="objForm.filter"
                class="height_s"
                autosize
                :rows="2"
                v-if="filterChecked"
                style="
                  width: 352px;
                  margin-left: 10px;
                  border-radius: 3px;
                  float: left;
                  border: 1px solid #dcdfe6;
                  outline-color: none;
                "
              >
              </textarea>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!-- <el-form-item label="选择字段" -->
            <el-form-item
              :label="$t('label.selectfield')"
              prop="viewSelectedFieldArr"
              style="margin-left: 12px"
            >
              <oneShuttle
                ref="oneShuttle"
                :viewUnselectedFieldList="viewUnselectedFieldList"
                :viewSelectedFieldArr="viewSelectedFieldList"
                @selFun="getSelFun"
                v-if="this.selLength > 0"
              />
            </el-form-item>
          </el-col>
          <!-- 自定义按钮 -->
          <el-col :span="24">
            <el-form-item
              :label="$t('label.custom.button')"
              style="margin-left: 12px"
            >
              <el-checkbox-group
                v-model="checkedBtns">
                <el-checkbox 
                  v-for="item in customBtns" 
                  :key="item.id" 
                  :label="item.id">
                  {{ item.label }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="visibRadio">
              <span class="setSize see_s">
                <!-- 可见性 -->
                {{ $t("label.tag.addtags.visibility") }}
              </span>
              <el-radio-group
                v-model="objForm.visibRadio"
                @change="visibBtn"
                style="margin-left: 50px"
              >
                <el-radio label="fsharenone">
                  <!-- '仅对我可见' -->
                  {{ $t("label_tabpage_onlymevisible") }}
                </el-radio>
                <el-radio label="fshareall">
                  <!-- 所有用户可见 -->
                  {{ $t("label_tabpage_allvisible") }}
                </el-radio>
                <el-radio label="fsharegroups">
                  <!-- '某些用户组可见' -->
                  {{ $t("label_tabpage_groupsVisiblez") }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!-- <el-form-item label="选择成员" -->
            <el-form-item
              :label="$t('vue_label_commonobjects_view_select_member')"
              v-show="visibFlag"
              style="margin-left: 12px; margin-top: 13px"
            >
              <Shuttle
                ref="shuttles"
                :selectedGroupList="selectedGroupList"
                :viewcell="viewcell"
                @memberFun="memberFun"
              />
            </el-form-item>
          </el-col>
          <!-- 编辑视图 -->
          <el-col
          :span="24"
            v-show="this.title === $t('label.view.introduce')"
            style="margin-top: 5px"
          >
            <!-- <el-form-item label="创建人" -->
            <el-form-item
              :label="$t('label.createby')"
              style="margin-left: 12px"
            >
              <span>
                {{ objForm.createbyidccname }}
                {{ objForm.createdate }}
              </span>
            </el-form-item>
          </el-col>
          <!-- 编辑视图 -->
          <el-col
          :span="24"
            v-show="this.title === $t('label.view.introduce')"
            style="margin-top: 5px"
            class="label_w"
          >
            <!-- <el-form-item label="最后修改人"> -->
            <el-form-item
              :label="$t('label.lastmodifyby')"
              style="margin-left: 12px"
            >
              <span
                >{{ objForm.lastmodifybyidccname }}
                {{ objForm.lastmodifydate }}
              </span>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeViewDialogEmit">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          size="small"
          @click="saveAsBtn"
          v-show="this.title === $t('label.view.introduce')"
        >
          <!-- 另存为 -->
          {{ $t("label.saveas") }}
        </el-button>
        <el-button type="primary" size="small" @click="saveView('objForm')">
          <!-- 保存 -->
          {{ $t("label.save") }}
        </el-button>
      </div>
    </el-dialog>
    <!-- 查找/查找多选 -->
    <el-dialog
      :title="$t('label.searchs')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
    >
      <search-table
        ref="searchTable"
        :fieldId="fieldId"
        :checked="searchChecked"
        :fromTableView="true"
        :remoType="remoType"
        @changeSelect="changeSelect"
      />
    </el-dialog>
  </div>
</template>

<script>
import oneShuttle from "@/components/ViewBox/oneShuttle.vue";
import Shuttle from "@/components/shuttle/index.vue";
import EditableCell from "@/components/TablePanel/EditableCell.vue";
import operator from "@/config/enumCode/operator.js";
import filterValueInputType from "@/config/enumCode/inputType.js";
import { GetViewGetSelectValue, SaveView, GetCurrencyInfo } from "./api.js";
import { setValueTypeConversion } from "@/config/filter/commaProcessing";
import SearchTable from "@/components/Form/search-table.vue"; //成员搜索弹框
import * as CommonObjApi from "./api.js";

export default {
  components: {
    oneShuttle,
    Shuttle,
    EditableCell,
    SearchTable,
  },
  props: {

    // 控制dialog是否展示
    dialongViewTo: {
      type: Boolean,
      default: false,
    },
    // dialog展示的标题
    title: {
      type: String,
      default: "",
    },
    // 可选字段
    viewUnselectedFieldList: {
      type: Array,
      default: () => [],
    },
    // 标识是否是新建视图
    viewcell: {
      type: String,
      default: "",
    },
    // 是否显示选择成员采集框
    visibFlag: {
      type: Boolean,
      default: false,
    },
    // 已经选择的字段
    selectedFieldIds: {
      type: String,
      default: "",
    },
    // 选择的组列表
    selectedGroupList: {
      type: Array,
      default: () => [],
    },
    // 表单对象
    objFormVal: {
      type: Object,
      default: () => {},
    },
    // 是否启用高级条件筛选
    checked: {
      type: Boolean,
      default: false,
    },
    // 是否启用队列条件
    isQueueSelect: {
      type: Boolean,
      default: false,
    },
    // 队列条件值
    queryQueueValue: {
      type: String,
      default: "",
    },
    // 是否选择队列条件
    isQueue: {
      type: Boolean,
      default: false,
    },
    // 队列集合
    queryQueueList: {
      type: Array,
      default: () => [],
    },
    // 加载框
    loading: {
      type: Boolean,
      default: false,
    },
    // form标题宽度
    formLabelWidth: {
      type: String,
      default: "",
    },
    // 是否应用于移动端
    isUsedForMobile: {
      type: Boolean,
      default: false,
    },
    // 选择的字段长度
    selLength: {
      type: Number,
      default: 0,
    },
    // 自定义按钮集合
    customBtns: {
      type: Array,
      default: () => [],
    },
    // 选中的自定义按钮
    checkCustomBtns: {
      type: Array,
      default: () => [],
    },
    // 第一个选择集合
    optionst_one: {
      type: Array,
      default: () => [],
    },
    // 已选字段的集合
    viewSelectedFieldList: {
      type: Array,
      default: () => [],
    },
    // 对象id
    objId: {
      type: String,
      default: "",
    },
    // 视图id
    viewId: {
      type: String,
      default: "",
    },
    // 对象前缀
    prefix: {
      type: String,
      default: "",
    },
    // 复制的表单对象
    copyForm: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    checkedBtns: {
      get() {
        return this.checkCustomBtns;
      },
      set(val) {
        this.$emit("updateCheckCustomBtns", val);
      }
    }
  },
  watch: {
    dialongViewTo(val) {
      this.Visible = val;
      this.objForm = this.objFormVal;
    },
    // 同步isUsedForMobileCopy和isUsedForMobile，解决props绑定v-model问题
    isUsedForMobile(newVal){
      this.isUsedForMobileCopy=newVal
    },
    queryQueueValue(newVal){
      this.queryQueueValueCopy=newVal
    },
    // 解决刷新页面之后  请求getViewInfo接口是异步  filterChecked一直为false没有更新的问题
    checked(newVal) {
      this.filterChecked = newVal
    }
  },
  data() {
    //视图名称做校验
    var validatorLabel = (rule, value, cb) => {
      if (value.replace(/^\s+$/g, "")) {
        return cb();
      }
      cb(new Error("请填写正确的名称"));
    };
    return {
      // props的data同步数据，为解决v-model绑定props问题
      queryQueueValueCopy: this.queryQueueValue,
      isUsedForMobileCopy: this.isUsedForMobile,
      // 搜索表单是否支持多选
      searchChecked: false,
      // 表单类型
      remoType: '', 
      // 高级筛选控制
      filterChecked: this.checked,
      // 控制dialog是否展示
      Visible: this.dialongViewTo,
      // 过滤条件ID，当添加或是删除一个条件时，保存这个条件的ID，用于后续业务处理
      dataId: 1,
      // 用于保存高级筛选条件文字
      textArr: "",
      selecArr: [],
      filter: "filter",
      fieldId: "",
      rowIndex: 0,
      // 控制是否显示搜索table
      showSearchTable: false,
      // 表单校验规则
      rules: {
        // 名称校验规则
        label: [
          {
            required: true,
            message: this.$i18n.t("setup_layout_label_required"),
            trigger: "blur",
          },
          { validator: validatorLabel, trigger: "blur" },
        ],
        visibRadio: [
          {
            required: true,
            message: this.$i18n.t("setup_layout_label_required"),
            trigger: "change",
          },
        ],
      },
      // 表单对象
      objForm: {
        // 名称
        label: "",
        //高级筛选值
        filter: "",
        // 所有人类型
        screeRadio: "0",
        // 可见性
        visibRadio: "fsharenone",
        // 创建人
        createbyidccname: "",
        // 创建时间
        createdate: "",
        // 最后修改人
        lastmodifybyidccname: "",
        // 最后修改时间
        lastmodifydate: "",
        // 筛选条件集合
        list: [
          {
            // 字段ID
            fieldId: "",
            // 逻辑
            op: "",
            // 值
            val: {
              value: "",
            },
            // 字段类型
            type: 1,
            // 字段id
            id: 1,
            // 输入类型
            inputType: "input",
            // 下拉选择数据
            optionst_two: [],
            // 对象id
            objid: "",
          },
        ],
      },
      //初始数据
      initData: {
        // 字段ID
        fieldId: "",
        // 逻辑
        op: "",
        // 值
        val: {
          value: "",
        },
        // 字段类型
        type: 1,
        // 字段id
        id: 1,
        // 输入类型
        inputType: "input",
        // 下拉选择数据
        optionst_two: [],
        // 对象id
        objid: "",
      },
    };
  },
  methods: {
    /**
     * props直接作为表单元素v-model绑定值
     */
    changeQueryQueueValue(value){
      this.$emit('update:queryQueueValue',value)
    },
    /**
     * props直接作为表单元素v-model绑定值
     */
    changeIsUsedForMobile(value){
      this.$emit('update:isUsedForMobile',value)
    },
    /**
     * 关闭视图弹窗
     */
    closeViewDialogEmit() {
      this.$refs.objForms.resetFields();
      this.$emit("closeViewDialog");
    },

    /**
     * 清除选择字段
     */
    clearField(val) {
      val.op = "";
      val.val = {value: ""};
    },

    /**
     * 视图权限修改
     * @param {String} e 值
     */
    queueBtn(e) {
      if (e === "2") {
        this.$emit('update:isQueueSelect',true)
      } else if (e !== "0" && e !== "1") {
        this.objForm.screeRadio = "2";
        this.$emit('update:isQueueSelect',true)
        this.$emit('update:queryQueueValue',e)
      } else {
        this.$emit('update:isQueueSelect',false)
      }
    },
    /**
     * 根据筛选字段判断值
     * @param {String} val 值
     * @param {Number} index 索引
     */
    selectChange(val, index) {
      this.fieldId = val;
      this.optionst_one.map((item) => {
        let keys = "";
        if (val === item.id) {
          this.remoType = item.schemefieldType;
          this.objForm.list[index].op = "";
          this.objForm.list[index].val = {
            value: "",
          };
          if (item.schemefieldType === "Z") {
            keys = item.expressionType;
            this.objForm.list[index].optionst_two =
              operator.TYPE[item.expressionType].getScreeningOpeTypes();
          } else {
            keys = item.schemefieldType;
            this.objForm.list[index].optionst_two =
              operator.TYPE[item.schemefieldType].getScreeningOpeTypes();
          }
          this.objForm.list[index].op =
            this.objForm.list[index].optionst_two[0].id; // 设置默认操作为第一个选项
          this.objForm.list[index].inputType =
            filterValueInputType[keys === "L" || keys === "R" ? "Q" : keys];
          this.selecArr = [];
          // 无法替换 接口返回
          if (item.schemefield_type == "ct") {
            GetCurrencyInfo().then((res) => {
              res.data.forEach((item) => {
                (item.label = item.currencyname),
                  (item.value = item.currencycode);
              });
              this.selecArr = res.data;
            });
          } else if (item.schemefield_type === "R") {
            this.getRecordTypes();
          } else {
            //(wxr)解决bug编号21297
            //分配状态时label和value需要换位置
            // codekey:下拉项的value；codevalue：下拉项的label（bug：3430）
            if (val === "ffeleadmarketsea02") {
              GetViewGetSelectValue({
                fieldId: val,
              }).then((res) => {
                res.data.forEach((item) => {
                  (item.label = item.codevalue), (item.value = item.codekey);
                });
                this.selecArr = res.data;
              });
            } else {
              GetViewGetSelectValue({
                fieldId: val,
              }).then((res) => {
                res.data.forEach((item) => {
                  (item.label = item.codevalue), (item.value = item.codekey);
                });
                this.selecArr = res.data;
              });
            }
          }
        }
      });
    },
    /**
     * 删除一行视图字段过滤条件
     * @param {Number} id 索引
     */
    dels(id) {
      if (this.objForm.list.length === 1) {
        // 清空第一项内容
        this.objForm.list[0].fieldId = "";
        this.objForm.list[0].op = "";
        this.objForm.list[0].optionst_two = [];
        this.objForm.list[0].inputType = "input";
        this.objForm.list[0].val = { value: "" };
        // 清空高级筛选条件
        this.objForm.filter = ""
      } else {
        // 删除当前项
        this.dataId = id;
        this.objForm.list.splice(id, 1);
        // this.$refs.editableCell.splice(id, 1);
        let an = " and ";
        if (this.objForm.list.length >= 2) {
          this.textArr = "";
          this.objForm.list.forEach((item, index) => {
            index++;
            this.textArr = this.textArr + an + index;
            this.objForm.filter = this.textArr.slice(4);
          });
        } else {
          this.objForm.filter = "";
        }
      }
    },
    /**
     * 新增一行视图字段过滤条件
     */
    adds() {
      if (this.dataId < 10) {
        this.dataId += 1;
        // 增加idKey用于循环key，防止删除后导致最小量更新失败
        this.objForm.list.push({
          ...this.initData,
          id: this.dataId,
          idKey:Math.random()+Date.now()
        });
      } else {
        return;
      }
      let an = " and ";
      if (this.objForm.list.length >= 2) {
        this.textArr = "";
        this.objForm.list.forEach((item, index) => {
          index++;

          this.textArr = this.textArr + an + index;
          this.objForm.filter = this.textArr.slice(4);
        });
      }
    },

    //获取可选成员
    memberFun(n) {
      this.memberGroup = n;
    },

    /**
     * 另存为
     */
    saveAsBtn() {
      this.$emit('update:title',this.$i18n.t("label.saveas"))
      /**
       * 王金龙
       * 1、去除另存为弹窗
       * 2、直接保存
       * 3、去掉重新获取
       */
      // 去除另存为弹窗
      // this.copy();
      // 直接保存
      this.copySave();
      // 去掉重新获取
      // this.getViewInfo();
    },

    /**
     * 增加形参 label 或运算使用
     * @param {String} operationType:保存类型，复制视图的时候使用,operationType为copy
     */
    copySave(operationType) {
      // 
      let copyList =JSON.parse(JSON.stringify(this.objForm.list))
       // 如果是复制视图，以上都不走，直接更改copyList
            if(operationType==='copy'){
              copyList=JSON.parse(JSON.stringify(this.$parent.objForm.list))
            }
      // 选项列表查找多选字段，获取的value为数组，需要转成后端需要的格式
      if (this.$refs.editableCell) {
        if(operationType==='copy'){
          copyList.forEach((item) => {
          if (item.inputType == "multi-select") {
            item.val = setValueTypeConversion(item.val.value);
          } else {
            item.val = item.val.value;
          }
        });
        }else{
        this.$refs.editableCell.forEach((item, index) => {
          if (copyList[index].inputType == "multi-select") {
            copyList[index].val = setValueTypeConversion(item.editValue);
          } else {
            copyList[index].val = item.editValue;
          }
        });
        }
      } else {
        copyList.forEach((item) => {
          if (item.inputType == "multi-select") {
            item.val = setValueTypeConversion(item.val.value);
          } else {
            item.val = item.val.value;
          }
        });
        
      }
      // 删除copyList中的其他属性
            // 去除无用筛选条件（没有fieldId），必须在this.$refs.editableCell对val复制之后进行去除
            let listCopy=[]
            copyList.forEach((item) => {
              if(item.fieldId){
                delete item.type;
                delete item.inputType;
                delete item.id;
                delete item.optionst_two;
                if (item.seq) {
                  delete item.seq;
                }
                listCopy.push(item)
              }
              
            });
            copyList=listCopy
            // 筛选条件的高级筛选条件有问题
            // 如果没有开启高级筛选条件,处理this.objForm.filter
            if(!this.filterChecked){
              if(this.objForm.list.length>1){
                let an=' and '
                let str=''
                copyList.forEach((item,index)=>{
                  index++
                  str=str+an+index
                })
                this.objForm.filter=str.slice(5)
                }else{
                  this.objForm.filter=''
                }
            }
           
            // 
      let params = {
        id: "",
        objId: this.objId, //对象id
        label:
          this.title === this.$i18n.t("vue_label_commonobjects_view_copy_view")
            ? this.copyForm.name
            : this.objForm.label, //名称
        filter:
          this.objForm.screeRadio === "2" ? this.queryQueueValue : this.objForm.screeRadio, //全部/我的
        accessibleuserset: this.memberGroup, //已选成员数据
        selectedFieldIds: this.selectedFieldIds, //已选字段
        conditionVals: copyList.length>0
          ? JSON.stringify({
              data: copyList, //筛选器
              filter: this.objForm.filter,
              mainObjId: this.objId,
            })
          : null, //筛选
        accesstype: this.objForm.visibRadio, //可见用户组
        isUsedForMobile: String(this.isUsedForMobile), //是否应用到移动端
        customButtons: this.checkedBtns.join(","),
      };
      SaveView(params).then((res) => {
        if (res.result === true) {
          this.$message({
            showClose: true,
            type: "success",
            // 保存成功
            message: this.$i18n.t("label.search.saveok"),
          });
          this.newViewId = res.data.id;
          this.$emit("getViewId", this.newViewId);
        } else {
          this.$message({
            showClose: true,
            type: "error",
            // 保存失败
            message: this.$i18n.t("savefail"),
          });
        }
        // 关闭编辑和另存为弹窗
        this.copyInfo();
      });
      
    },
    /**
     * 关闭编辑和另存为弹窗
     */
    copyInfo() {
      this.$emit("closeViewDialog");
    },
    /**
     * 调用远程方法
     */
    remoteMethods(val, fieldId) {
      let params = {
        fieldId: fieldId,
        objId: "",
        prefix: "",
      };
      CommonObjApi.getLookupInfo(params).then((res) => {
        let data = {
          name: val,
          objId: res.data.prefix,
          fieldId: params.fieldId,
          lkdp: "['']",
        };
        CommonObjApi.queryMoreDataByName(data).then((res) => {
          let options = JSON.parse(res.data);
          options.forEach((option) => {
            option.value = option.id;
            option.label = option.queryname;
          });
          this.selecArr = options;
        });
      });
    },

    /**
     * 获取可选字段
     * @param {String} n 可选字段类型
     */
    getSelFun(n) {
       this.$emit('update:selectedFieldIds',n)
    },

    /**
     * 选定用户可见才显示可选成员
     * @param {String} n 可选字段类型
     */
    visibBtn(e) {
      // this.visibRadio = e === "fsharegroups";
      if(e === "fsharegroups"){
        // 打开某些用户组可见穿梭框
      this.$emit('update:visibFlag',true)
      }else{
        // 打开某些用户组可见穿梭框
      this.$emit('update:visibFlag',false)
      }
      
    },

    /**
     * 保存新视图
     */
    saveView() {
      this.$refs.shuttles.curGroupLists();
      this.$refs.objForms.validate((valid) => {
        if (valid) {
          if (this.selectedFieldIds.split(",").length <= 15) {
            let copyList =JSON.parse(JSON.stringify(this.objForm.list))
            // 对筛选条件中的value值做处理
            if (this.$refs.editableCell) {
              this.$refs.editableCell.forEach((item, index) => {
                if (item.editValue !== "" && item.editValue !== undefined) {
                  // 选项列表查找多选字段，获取的value为数组，需要转成后端需要的格式
                  if (copyList[index].inputType == "multi-select") {
                    copyList[index].val = setValueTypeConversion(
                      item.editValue
                    );
                  } else {
                    copyList[index].val = item.editValue;
                  }
                } else {
                  copyList[index].val = ''
                }
              });
            } else {
              copyList.forEach((item) => {
                item.val = item.val.value;
              });
            }
            // 删除copyList中的其他属性
            // 去除无用筛选条件（没有fieldId），必须在this.$refs.editableCell对val复制之后进行去除
            let listCopy=[]
            copyList.forEach((item) => {
              if(item.fieldId){
                delete item.type;
                delete item.inputType;
                delete item.id;
                delete item.optionst_two;
                if (item.seq) {
                  delete item.seq;
                }
                listCopy.push(item)
              }
              
            });
            copyList=listCopy
            // 筛选条件的高级筛选条件有问题
            // 如果没有开启高级筛选条件,处理this.objForm.filter
            if(!this.filterChecked){
              if(this.objForm.list.length>1){
                let an=' and '
                let str=''
                copyList.forEach((item,index)=>{
                  index++
                  str=str+an+index
                })
                this.objForm.filter=str.slice(5)
                }else{
                  this.objForm.filter=''
                }
            }
            if (this.objForm.filter === null) {
              this.objForm.filter = "";
            }
            if (this.selectedFieldIds === "") {
              this.$message.error(
                this.$i18n.t("message_chooseatleastonefield")
              );
              return;
            }
            let params = {
              id: "",
              objId: this.objId, //对象id
              label: this.objForm.label, //名称
              filter:
                this.objForm.screeRadio === "2"
                  ? this.queryQueueValue
                  : this.objForm.screeRadio, //全部/我的
              accessibleuserset: this.memberGroup, //已选成员数据
              selectedFieldIds: this.selectedFieldIds, //已选字段
              conditionVals: copyList.length>0
                ? JSON.stringify({
                    data: copyList, //筛选器
                    filter: this.objForm.filter,
                    mainObjId: this.objId,
                  })
                : null, //筛选
              accesstype: this.objForm.visibRadio, //可见用户组
              isUsedForMobile: String(this.isUsedForMobile), //是否应用到移动端
              customButtons: this.checkedBtns.includes("202210operate") ? this.checkedBtns.join(",") : "202210operate," + this.checkedBtns.join(","),
            };
            // 新建视图
            if (
              this.title ===
              this.$i18n.t("vue_label_commonobjects_view_new_view")
            ) {
              params.id = "";
              SaveView(params).then((res) => {
                if (res.result === true) {
                  this.$message({
                    showClose: true,
                    type: "success",
                    // 保存成功
                    message: this.$i18n.t("label.search.saveok"),
                  });
                  this.newViewId = res.data.id;
                  this.$emit("getViewId", this.newViewId);
                } else {
                  this.$message({
                    showClose: true,
                    type: "error",
                    // 保存失败
                    message: this.$i18n.t("savefail"),
                  });
                }
              });
              this.closeViewDialogEmit();
              // 编辑视图
            } else if (this.title === this.$i18n.t("label.view.introduce")) {
              params.id = this.viewId;
              (params.conditionVals = copyList.length>0
                ? JSON.stringify({
                    data: copyList, //筛选器
                    filter: this.objForm.filter,
                    mainObjId: this.objId,
                  })
                : null), //筛选
                SaveView(params).then((res) => {
                  if (res.result === true) {
                    this.$message({
                      showClose: true,
                      type: "success",
                      // 保存成功
                      message: this.$i18n.t("label.search.saveok"),
                    });
                    this.$emit("getViewId", this.viewId);
                  } else {
                    this.$message({
                      showClose: true,
                      type: "error",
                      // 保存失败
                      message: this.$i18n.t("savefail"),
                    });
                  }
                });
              this.closeViewDialogEmit();
            }
          } else {
            this.$message.error(
              this.$i18n.t("vue_label_commonobjects_view_to_display_fields")
            );
            // this.isShowShuttle = true;
            return false;
          }
        } else {
          return false;
        }
      });
    },
    /**
     * getRecordTypes:获取记录类型
     */
    getRecordTypes() {
      let arr = []
      let params = {
        prefix: this.prefix,
      }
      CommonObjApi.getRecordType(params).then((res) => {
        if (res.result) {
          res.data.recordTypeList.forEach((item) => {
            // 这里使用的是name匹配的，规则跟查找字段一样
            let obj = {
              label: item.name,
              value: item.name,
            }
            arr.push(obj)
            
          })
          this.selecArr = arr
        }
      })
    },
    /**
     * 选择回调方法
     * @param {Object} val 当前选中的对象
     */
    async selectes(val) {
      if (val.type === "R") {
        this.getRecordTypes();
      } else if (val.type === "L" && val.fieldId === "ffe202085ACBDD8EusnH") {
        this.getCountrySelectValue();
      } else if (val.type == "ct") {
        GetCurrencyInfo().then((res) => {
          res.data.forEach((item) => {
            (item.label = item.currencyname), (item.value = item.currencycode);
          });
          this.selecArr = res.data;
        });
      } else {
        //(wxr)解决bug编号21297
        //val.fieldId = ffeleadmarketsea02 分配状态时不继续调这个接口GetViewGetSelectValue
        if (
          val.fieldId === "ffe20120329185449704" ||
          val.fieldId === "ffeleadmarketsea02"
        ) {
          return;
        }
        let arr = [];
        let params = {
          fieldId: val.fieldId,
        };
        let res = await GetViewGetSelectValue(params);
        if (res.result) {
          res.data.forEach((item) => {
            let obj = {
              label: item.codevalue,
              value: item.codekey,
            };
            arr.push(obj);
            this.selecArr = arr;
          });
        }
      }
    },

    /**
     * 获取国家或地区选项列表值
     */
    getCountrySelectValue() {
      CommonObjApi.getSelectValue({ fieldId: "country" }).then((res) => {
        if (res.result && res.returnCode === "1") {
          let options = [];
          res.data.forEach((item) => {
            options.push({
              label: item.codevalue,
              value: item.codekey,
            });
          });
          this.selecArr = options;
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    /**
     * 远程方法
     * @param {String} item 选择的对象
     * @param {Number} num 当前索引
     */
    remoteSearch(item, num) {
      this.fieldId = item.fieldId;
      this.rowIndex = num + 1;
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },

    /**
     * 改变查找/查找多选的值
     * @param {Object} row 当前行
     */
    changeSelect(row) {
      this.showSearchTable = false;
      if (row.data.id) {
        let options = {
          label: row.data.id,
          value: row.data.name,
        };
        this.$set(this.objForm.list[this.rowIndex - 1], "val", options);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
//弹窗
.newBox ::v-deep .el-dialog__header {
  text-align: left !important;
  border-bottom: 1px solid #ccc !important;
  color: #000;
  font-weight: 400;
  height: 65px;
}

.newBox ::v-deep .el-form-item__label {
  color: #333;
  font-size: 14px;
  font-weight: bold;
}

.newBox ::v-deep .el-dialog {
  width: 750px;
  // height: 708px;
  margin-top: 10vh;
  .label_name .el-form-item__label {
    margin-right: 12px;
    margin-bottom: 18px;
  }
  .label_name .el-form-item__error {
    margin-top: -20px;
    margin-left: 15px;
  }
  .input_name,
  .input_name .el-input__inner {
    width: 183px;
  }
  .el-input__inner {
    height: 33px;
  }
}

.newBox ::v-deep .el-dialog__footer {
  border-top: 1px solid #ccc;
  // height: 92px;
}

.newBox .dialog-footer {
  // margin-top: 20px;
  text-align: right;
}
.newBox ::v-deep .el-dialog__body {
  height: 550px;
  padding: 10px 36px 0;
  overflow-y: auto;
}

.newBox ::v-deep .el-radio__label {
  font-size: 12px;
}

.newBox {
  .mobileFilter {
    margin-left: 12px;
    ::v-deep .el-checkbox__label {
      font-size: 12px;
    }
  }
}
.newBox ::v-deep .el-dialog__title {
  line-height: 24px;
  font-size: 18px;
  color: #333;
  font-weight: bold;
}
.newBox ::v-deep .el-radio__input.is-checked .el-radio__inner {
  background: #006dcc;
}
.newBox ::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #006dcc;
}

.setSize {
  font-size: 12px;
  margin-right: 20px;
  position: relative;
}

.see_s {
  margin-left: -105px;
  color: #333;
  font-size: 14px;
  font-weight: bold;
}

.tipsBox {
  width: 10px;
  cursor: pointer;
  margin: 0;
  position: absolute;
  top: 12px;
  left: -57px;
}

.screen_s > div {
  margin-bottom: 0 !important;
}
::v-deep .screen_s .editableCell {
  .el-select__tags {
    flex-wrap: unset;
    overflow-x: overlay;
    top: 53%;
    height: 38px;
    overflow-y: hidden;
  }
  .el-input .el-input__inner {
    height: 30px !important;
  }
  .el-tag {
    margin: 0 5px !important;
  }
}
.screen_s ::v-deep .el-form-item__content {
  display: flex;
  margin-left: 133px !important;
  align-items: center;
}

.select_one {
  width: 180px;
  margin: 6px;
  height: 30px;
}

.select_two {
  width: 140px;
  margin: 6px 12px 6px 6px;
  height: 30px;
}

.btn_s {
  font-size: 24px;
  margin: 0 8px;
  cursor: pointer;
}

.copyBox ::v-deep .el-dialog {
  width: 333px;
}
</style>